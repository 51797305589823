import axios from 'axios';
import {
  LOADING, GET_PACKAGES, UPDATE_CART, STATUS_MODALCART, SET_PROMO_CODE, GET_MY_PLANS,
} from '../types/home.type';
import { API_LICENCIAMIENTO } from '../config/config';

export const addToCartAction = (data) => (dispatch, getState) => {
  const { home: { cart } } = getState();
  cart.push(data);
  const cartUpdated = Object.assign([], cart);
  dispatch({ type: UPDATE_CART, payload: cartUpdated });
};

export const setStatusCart = (data) => (dispatch) => {
  dispatch({ type: STATUS_MODALCART, payload: data });
};
export const directPurchaseAction = (data) => (dispatch) => {
  dispatch(addToCartAction(data));
  dispatch(setStatusCart(true));
};

export const getPackagesAction = (appName, packageId) => async (dispatch) => {
  try {
    dispatch({ type: LOADING, payload: true });
    const response = await axios.get(`${API_LICENCIAMIENTO}/license/api/package/GetPackagesByServiceName/${appName}`);
    if (response.status === 200) {
      dispatch({ type: GET_PACKAGES, payload: response.data.result.records });
      dispatch({ type: LOADING, payload: false });
      if (packageId) {
        const packageFlat = Object.values(response.data.result.records).flat(1);
        const uniquePackages = [...new Set(packageFlat)];
        const packageToAdd = uniquePackages.find((pkg) => pkg.packageId === Number(packageId));
        dispatch(directPurchaseAction(packageToAdd));
      }
    }
  } catch (error) {
    console.error(error);
  }
};

export const deleteOfCartAction = (packageId, allItems) => (dispatch, getState) => {
  const { home: { cart } } = getState();
  let cartUpdated;
  if (allItems) cartUpdated = cart.filter((item) => item.packageId !== packageId);
  else {
    cart.splice(cart.findIndex((item) => item.packageId === packageId), 1);
    cartUpdated = Object.assign([], cart);
  }
  dispatch({ type: UPDATE_CART, payload: cartUpdated });
};

export const applyPromotionCodeAction = (data, setLoading, enqueueSnackbar) => async (dispatch, getState) => {
  try {
    const { home: { promoCode } } = getState();
    if (promoCode && (promoCode.promotionCode === data.PromotionCode)) return;
    setLoading(true);
    const response = await axios.post(`${API_LICENCIAMIENTO}/license/api/PurchaseOrderLicense/ValidatePromotionCode`, data);
    if (response.status === 200) {
      enqueueSnackbar('El cupón ha sido aplicado a su compra.', {
        variant: 'success',
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'right',
        },
      });
      dispatch({ type: SET_PROMO_CODE, payload: response.data.result.records });
      setLoading(false);
    }
  } catch (error) {
    if (error.response) {
      if (error.response.status === 400) {
        dispatch({ type: LOADING, payload: false });
        enqueueSnackbar('Cupón inválido. Por favor intente nuevamente.', {
          variant: 'error',
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right',
          },
        });
        setLoading(false);
      }
    }
  }
};

export const getMyPlanAction = (data, enqueueSnackbar) => async (dispatch) => {
  try {
    dispatch({ type: LOADING, payload: true });
    const response = await axios.post(`${API_LICENCIAMIENTO}/license/api/License/GetDetailLicenseCustomerMyPlan`, data);
    if (response.status === 200) {
      dispatch({ type: GET_MY_PLANS, payload: response.data.result.records });
      dispatch({ type: LOADING, payload: false });
    }
  } catch (error) {
    dispatch({ type: LOADING, payload: false });
    if (error.response) {
      if (error.response.status === 422) {
        enqueueSnackbar(`No se encontro plan.${error.response.data.statusMessage}`, {
          variant: 'error',
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right',
          },
        });
      } else {
        enqueueSnackbar('Error de servidor', {
          variant: 'error',
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right',
          },
        });
      }
    }
  }
};
