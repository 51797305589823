import React from 'react';
import { Controller } from 'react-hook-form';
import Switch from '@material-ui/core/Switch';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';

const CustomSwitch = (props) => {
  const {
    label, checked, name, control,
  } = props;
  return (
    <FormControl>
      <FormControlLabel
        control={(
          <Controller
            as={<Switch />}
            name={name}
            control={control}
          />
            )}
        key={name}
        label={label}
      />
    </FormControl>
  );
};

export default CustomSwitch;
