import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useSnackbar } from 'notistack';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import InputAdornment from '@material-ui/core/InputAdornment';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import EditIcon from '@material-ui/icons/Edit';
import SearchIcon from '@material-ui/icons/Search';
import Tooltip from '@material-ui/core/Tooltip';
import SwitchiOS from '../../../../components/SwitchiOS';
import ModalService from './ModalService';
import AlertConfirm from '../../../../components/AlertConfirm';
import { changeStateServiceAction } from '../../../../actions/service.action';


const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  tableHead: {
    backgroundColor: '#009BDD',
  },
  tableCellHead: {
    color: '#FFF',
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 150,
  },
  textFieldSearch: {
    marginBottom: 10,
    minWidth: 350,
  },
  rowOver: {
    hover: {
      backgroundColor: '#009BDD',
    },
  },
}));

const headCells = [
  {
    id: 'id', numeric: false, disablePadding: false, label: 'Nombre del servicio',
  },
  {
    id: 'id', numeric: false, disablePadding: false, label: 'Tipo de servicio',
  },
  {
    id: '_cliente', numeric: false, disablePadding: false, label: 'Descripción',
  },
  {
    id: '_tipoDocumento', numeric: false, disablePadding: false, label: 'Activo',
  },
  {
    id: 'edit', numeric: false, disablePadding: true, label: '',
  },
];

function EnhancedTableHead(props) {
  const {
    classes, order, orderBy, onRequestSort,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead className={classes.tableHead}>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            className={classes.tableCellHead}
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'default'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const GridServices = (props) => {
  const classes = useStyles();
  const {
    rows,
    totalRows,
    type,
    handleChangePage,
    changeStateService,
  } = props;
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('_referencia');
  const [selected, setSelected] = useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [openEditAddModal, setOpenEditAddModal] = useState(false);
  const [item, setItem] = useState();
  const { enqueueSnackbar } = useSnackbar();
  const [filterData, setFilterData] = useState(rows);
  const [isNew, setIsNew] = useState(false);
  const [openAlertConfirmState, setOpenAlertConfirmState] = useState(false);

  useEffect(() => {
    setFilterData(rows);
  }, [rows]);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = rows.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isSelected = (_referencia) => selected.indexOf(_referencia) !== -1;

  const handleSearchOnChange = (event) => {
    const result = rows.filter((data) => `${data.serviceName} ${data.serviceType.serviceType1} ${data.descriptionService}`
      .toLowerCase().includes(event.target.value.toLowerCase()));
    setFilterData(result);
  };
  
  const handleSwitchClick = (event, r) => {
    setOpenAlertConfirmState(true);
    setItem(r);
  };

  const handleEditClick = (event) => {
    setItem(event);
    setIsNew(false);
    setOpenEditAddModal(true);
  };

  const handleChangeStatus = () => {
    changeStateService({ Id: item.id, Status: item.idstate === 1 }, enqueueSnackbar);
    setOpenAlertConfirmState(false);
  };

  const handleCancel = () => {
    setOpenAlertConfirmState(false);
  };

  return (
    <div className={classes.root}>
      <div>
        <TextField
          size="small"
          variant="outlined"
          className={classes.textFieldSearch}
          onChange={handleSearchOnChange}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
          placeholder="Buscar"
        />
        {/* <Button aria-label="Filter list">
          <FilterListIcon />
          Filtrar
        </Button> */}
        <IconButton
          edge="end"
          size="small"
          style={{ float: 'right' }}
          onClick={(e) => { setIsNew(true); setOpenEditAddModal(true); }}
        >
          <AddCircleOutlineIcon color="secondary" />
        </IconButton>
      </div>
      <Paper className={classes.paper}>
        <TableContainer>
          <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            size="small"
            aria-label="enhanced table"
          >
            <EnhancedTableHead
              classes={classes}
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={totalRows}// {rows.length}
            />
            <TableBody>
              {Object.keys(filterData).length && stableSort(filterData, getComparator(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  return (
                    <TableRow 
                      //className={classes.rowOver}
                      hover
                      tabIndex={-1}
                      key={row.id}
                    >
                      <TableCell align="left">{row.serviceName}</TableCell>
                      <TableCell align="left">{row.serviceType.serviceType1}</TableCell>
                      <TableCell align="left">{row.descriptionService}</TableCell>
                      <TableCell align="left">
                        <SwitchiOS
                          onChange={(e) => { handleSwitchClick(e, row); }}
                          checked={row.idstate === 0}
                          id={row.id}
                        />
                      </TableCell>
                      <TableCell align="center">
                        <IconButton
                          edge="end"
                          aria-label="Editar"
                          size="small"
                          onClick={() => { handleEditClick(row); }}
                        >
                          <Tooltip title="Editar" aria-label="add">
                            <EditIcon color="primary" />
                          </Tooltip>
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 20, 30]}
          labelRowsPerPage="Registros por página"
          component="div"
          count={filterData.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={(e, newpage) => { handleChangePage(e, newpage, type, rowsPerPage); }}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </Paper>
      {openEditAddModal
        && (
        <ModalService
          isOpen={openEditAddModal}
          onClose={() => setOpenEditAddModal(false)}
          item={item}
          isNew={isNew}
        />
        )}
      {openAlertConfirmState
        && (
          <AlertConfirm
            isOpen={openAlertConfirmState}
            tituloModal="Confirmar"
            mensajeModal={item.idstate === 1
              ? '¿Esta seguro que quiere activar este producto o servicio?'
              : '¿Esta seguro que quiere inactivar este producto o servicio?'}
            handleAccept={handleChangeStatus}
            onClose={handleCancel}
            onCancel
          />
        )}
    </div>
  );
};

const mapStateToProps = ({ service }) => ({
  loading: service.loading,
});

const mapDispatchToProps = {
  changeStateService: changeStateServiceAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(GridServices);
