import {
  LOADING, GET_PACKAGES, UPDATE_CART, STATUS_MODALCART, SET_PROMO_CODE, GET_MY_PLANS,
} from '../types/home.type';

const INITIAL_STATE = {
  loading: false,
  packages: {},
  cart: [],
  promoCode: undefined,
  isCartOpen: false,
  myplan: {}
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_PACKAGES:
      return {
        ...state,
        packages: {
          offers: action.payload.packagesOffers,
          base: action.payload.packagesBase,
          combo: action.payload.packagesCombo,
          complementary: action.payload.packagesComplementary,
        },
      };
    case UPDATE_CART:
      return {
        ...state,
        cart: action.payload,
      };
    case STATUS_MODALCART:
      return {
        ...state,
        isCartOpen: action.payload,
      };
    case SET_PROMO_CODE:
      return {
        ...state,
        promoCode: action.payload,
      };
    case LOADING:
      return {
        ...state,
        loading: action.payload,
      };
    case GET_MY_PLANS:
      return {
        ...state,
        myplan: action.payload,
      };
    default:
      return state;
  }
};
