/* eslint-disable import/prefer-default-export */
import { createMuiTheme } from '@material-ui/core/styles';
import background from '../assets/img/background/firmaYa.jpg';
import logo from '../assets/img/logo/firmaYa.svg';

export default createMuiTheme({
  palette: {
    primary: {
      main: '#E55200',
    },
    secondary: {
      main: '#4F4F4F',
    },
  },
  logo,
  colorHeader: '#F1F1F1',
  home: {
    paquetes: {
      backgroundColor: '#F6F6F6',
    },
    combos: {
      backgroundColor: '#FFEFD8',
      border: '1px solid #ED8D3D',
    },
    agregados: {
      backgroundColor: '#F6F6F6',
      border: '1px solid #B2B2B2',
    },
    bgColorHead: {
      bases: 'orange,orange01,orange02',
      combos: 'orange04',
      complement:'red01',
    },
    sliderComboText: {
    },
    sliderComplementText: {
    },
    sliderBackGroundColor: {
    },
    cart: {
      title: {
        color: '#E55200',
      },
      tableHead: {
        backgroundColor: '#FFEFD8',
      },
      totalText: {
        color: '#4F4F4F',
      },
      promoForm: {
        backgroundColor: '#F1F1F1',
        color: '#B2B2B2',
      },
      slider:{
      },
      btnPromotional: {
        backgroundColor: '#F5AE19',
      },
      tableCell: {
        color: '#E55200',
      },
      textPromotional: {
        backgroundColor: '#fff',
      },
      btnContinue: {}
    }
  },
  miplan: {
    title: {
      color: '#E55200',
    },
    subtitle: {
      color: '#6D6E72',
    },
    cardHeader: {
      color:'#E55200'
    },
    featureName: {      
    },
    featureValue: {   
      color: '#E55200',   
    },
    buttonCard: {   
    },
    iconCard: {
      color: '#E55200',
    },    
  },  
  overrides: {
    MuiButton: {
      root: {
        textTransform: 'none',
      },
      label: {
        fontWeight: 'bold',
      },
    },
    MuiCssBaseline: {
      '@global': {
        body: {
          background: `url(${background}) no-repeat center center fixed`,
          WebkitBackgroundSize: 'cover',
          MozBackgroundSize: 'cover',
          OBackgroundSize: 'cover',
          backgroundSize: 'cover',
        },
      },
    },
  },
});
