import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Switch from '@material-ui/core/Switch';


const useStyles = makeStyles((theme) => ({
  tableHead: {
    backgroundColor: theme.palette.primary.main,
  },
}));

const PackageTable = ({ info }) => {
  console.log(info);
  const classes = useStyles();
  return (
    <TableContainer>
      <Table>
        <TableHead className={classes.tableHead}>
          <TableRow>
            <TableCell>Nombre</TableCell>
            <TableCell align="right">ID ítem paquete</TableCell>
            <TableCell align="right">ID paquete</TableCell>
            <TableCell align="right">ID característica</TableCell>
            <TableCell align="right">ID canal</TableCell>
            <TableCell align="right">Valor</TableCell>
            <TableCell align="right">Cantidad</TableCell>
            <TableCell align="right">Activo</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {info.map((row) => (
            <TableRow key={row.id}>
              <TableCell component="th" scope="row">
                {row.packageName}
              </TableCell>
              <TableCell align="right">{row.id}</TableCell>
              <TableCell align="right">{row.id}</TableCell>
              <TableCell align="right">{row.packageName}</TableCell>
              <TableCell align="right">{row.id}</TableCell>
              <TableCell align="right">{row.packagePrice}</TableCell>
              <TableCell align="right">{row.id}</TableCell>
              <TableCell align="right">
                <Switch
                  defaultValue={false}
                  color="primary"
                  name="checkedB"
                  size="small"
                />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default PackageTable;
