/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { ThemeProvider, responsiveFontSizes } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import { Provider, useDispatch, useSelector } from 'react-redux';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { SnackbarProvider } from 'notistack';
import Store from '../store';
import Header from '../components/Header';
import Home from '../views/Home';
import Payments from '../views/BackOffice/Payments';
import Packages from '../views/BackOffice/Packages';
import PromoCode from '../views/BackOffice/PromoCode';
import Configurations from '../views/BackOffice/Configurations';
import RegisterPurchaseOrder from '../views/RegisterPurchaseOrder';
import StatusOrder from '../views/RegisterPurchaseOrder/StatusOrder';
import MyPlan from '../views/MyPlan';
import { getPlainToken, setInfoToken } from '../actions/auth.action';
import { isValidToken } from '../helpers/authUtils';
import { SET_APP_NAME } from '../types/general.type';

const store = (appName) => {
  const localStore = Store();
  localStore.dispatch({
    type: SET_APP_NAME,
    payload: appName,
  });
  return localStore;
};

const NavRoute = (props) => {
  const {
    exact, path, computedMatch, component: Component,
  } = props;
  const dispatch = useDispatch();
  const userInfo = useSelector(({ auth }) => auth.userInfo);
  const { token } = computedMatch.params;
  const appsUserToken = localStorage.getItem('appsUserToken');
  if (appsUserToken && isValidToken && !userInfo) dispatch(setInfoToken());
  if (token) dispatch(getPlainToken(token, path));

  return (
    <Route
      exact={exact}
      path={path}
      render={(componetProps) => (
        <>
          <Header />
          {Component && <Component {...componetProps} />}
        </>
      )}
    />
  );
};

const App = ({ appName, theme }) => (
  <ThemeProvider theme={responsiveFontSizes(theme)}>
    <CssBaseline />
    <SnackbarProvider maxSnack={3}>
      <Provider store={store(appName)}>
        <BrowserRouter>
          <Switch>
            <NavRoute exact path="/packages" component={Packages} />
            <NavRoute exact path="/promoCode" component={PromoCode} />
            <NavRoute exact path="/configurations" component={Configurations} />
            <NavRoute exact path="/payments" component={Payments} />
            <NavRoute exact path="/myplan" component={MyPlan} />
            <NavRoute exact path="/registerPurchaseOrder" component={RegisterPurchaseOrder} />
            <NavRoute exact path="/registerPurchaseOrder/statusOrder/:refererenceUuid" component={StatusOrder} />
            <NavRoute exact path="/myplan/:token?" component={MyPlan} />        
            <NavRoute exact path="/:token?" component={Home} />
            <NavRoute exact path="/cart/:packageId" component={Home} />    
          </Switch>
        </BrowserRouter>
      </Provider>
    </SnackbarProvider>
  </ThemeProvider>
);
export default App;
