import {
  LOADING,
  SAVE_PURCHASE,
  GET_CUSTOMER_INFORMATION,
  GET_STATUS_PURCHASEORDER,
  SET_PERSON_TYPE,
  SET_PERSON_TYPE_SERVICE,
} from "../types/purchaseLicense.type";

const INITIAL_STATE = {
  loading: false,
  statusOrder: undefined,
  customerBillingInfo: {},
  customerServiceInfo: {},
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SAVE_PURCHASE:
      return {
        ...state,
        savePurchaseResponse: action.payload,
      };
    case GET_CUSTOMER_INFORMATION:
      return {
        ...state,
        customerBillingInfo: action.payload.customerBilling,
        customerServiceInfo: action.payload.customerService,
      };
    case SET_PERSON_TYPE:
      return {
        ...state,
        customerBillingInfo: {
          ...state.customerBillingInfo,
          personType: action.payload,
        },
      };
    case SET_PERSON_TYPE_SERVICE:
      return {
        ...state,
        customerServiceInfo: {
          ...state.customerServiceInfo,
          personType: action.payload,
        },
      };
    case GET_STATUS_PURCHASEORDER:
      return {
        ...state,
        statusOrder: action.payload,
      };
    case LOADING:
      return {
        ...state,
        loading: action.payload,
      };
    default:
      return state;
  }
};
