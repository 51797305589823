/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';
import Slider from 'react-slick';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';

const Arrow = (props) => {
  const {
    style,
    onClick,
    icon: Icon,
  } = props;
  return (
    <div className="slick-arrow" style={{ ...style }} onClick={onClick}>
      <Icon fontSize="large" color="primary" />
    </div>
  );
};

const Carousel = (props) => {
  const {
    autoplay = false,
    fade = false,
    dots = false,
    speed = 500,
    arrows = true,
    infinite = true,
    slidesToShow = 1,
    slidesToScroll = 1,
    slidesPerRow = 1,
    rows = 1,
    responsive,
    children,
    className,
  } = props;
  return (
    <Slider
      autoplay={autoplay}
      fade={fade}
      dots={dots}
      arrows={arrows}
      infinite={infinite}
      slidesToShow={slidesToShow}
      slidesToScroll={slidesToScroll}
      slidesPerRow={slidesPerRow}
      rows={rows}
      speed={speed}
      nextArrow={<Arrow icon={ArrowForwardIosIcon} />}
      prevArrow={<Arrow icon={ArrowBackIosIcon} />}
      responsive={responsive}
      className={className}
    >
      {children}
    </Slider>
  );
};

export default Carousel;
