import React from 'react';
import { withRouter } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import Typography from '@material-ui/core/Typography';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import Box from '@material-ui/core/Box';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import IconButton from '@material-ui/core/IconButton';
import FeaturedVideoIcon from '@material-ui/icons/FeaturedVideo';

const useStyles = makeStyles((theme) => ({
  root: {
    width: 200,
    borderRadius: 16,
    paddingBottom: 10,
    margin: 5,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    boxShadow: '0px 3px 3px -2px rgba(0,0,0,0.5)',
  },
  paper: {
    padding: '5px',
    marginTop: '20px',
    opacity: '0.95',
  },
  cardHeader: {
    ...theme.miplan.cardHeader,
    fontSize: 25,
    padding: 0,
    // backgroundImage: `url(${header})`,
    // backgroundRepeat: 'no-repeat',
    // backgroundColor: theme.palette.primary.main,
    // color: theme.home.paquetes.backgroundColor,
  },
  iconCard: {
    // backgroundImage: `url(${headerIcon})`,
    textAlign: 'center',
    borderRadius: 5,
    width: 30,
    height: 30,
    boxShadow: '0px 4px 7px #0000001F',
    ...theme.miplan.iconCard,
  },
  featureName: {
    ...theme.miplan.featureName,
  },
  featureValue: {
    ...theme.miplan.featureValue,
  },
  buttonCard: {
    ...theme.miplan.buttonCard,
  },
  iconBox: {
    width: 30,
    height: 30,
    boxShadow: '0px 4px 7px #0000001F',
    borderRadius: 5,
  },
}));

const LicenseCard = (props) => {
  const classes = useStyles();
  const { feature, serviceName, history } = props;

  const handleClickBuyMore = (pId) => {
    history.push(`/cart/${pId}`);
  };

  const getIconImg = (pFeatureName) => {
    let folder;

    switch (serviceName.toLowerCase()) {
      case 'ibuhoo':
      case 'firmaya':
      case 'colfactura':
      case 'firmamail':
        folder = serviceName.toLowerCase();
        break;
      case 'firmaya plus.':
        folder = 'firmaya';
        break;
      default:
        return require('../../assets/img/iconos/colfactura/icono factura.svg');
    }
    try {
      switch (pFeatureName.toLowerCase()) {
        case 'facturas':
          return require(`../../assets/img/iconos/${folder}/icono factura.svg`);
        case 'usuarios':
          return require(`../../assets/img/iconos/${folder}/Icono usuarios.svg`);
        case 'sucursales':
          return require(`../../assets/img/iconos/${folder}/Icono sucursales.svg`);
        case 'plantillas':
          return require(`../../assets/img/iconos/${folder}/Icono plantillas.svg`);
        case 'informes':
          return require(`../../assets/img/iconos/${folder}/Icono informes.svg`);
        case 'otros':
          return require(`../../assets/img/iconos/${folder}/icono factura.svg`);
        case 'firmas digitales':
          return require(`../../assets/img/iconos/${folder}/Icono firma digital.svg`);
        case 'estampas': case 'tsa':
          return require(`../../assets/img/iconos/${folder}/Icono estampas.svg`);
        case 'firmas manuscritas':
          return require(`../../assets/img/iconos/${folder}/Firma manuscrita.svg`);
        case 'firma ltv':
          return require(`../../assets/img/iconos/${folder}/Firma ltv.svg`);
        case 'empresas':
          return require(`../../assets/img/iconos/${folder}/Icono empresa.svg`);
        case 'bolsas':
          return require(`../../assets/img/iconos/${folder}/Icono bolsas.svg`);
        default:
          return require('../../assets/img/iconos/colfactura/icono factura.svg');
      }
    } catch (error) {
      return require('../../assets/img/iconos/colfactura/icono factura.svg');
    }
  };

  return (
    <Card className={classes.root} variant="outlined">
      <CardHeader align="center" titleTypographyProps={{ variant: 'h6' }} title={feature.featureAlias} className={classes.cardHeader} />
      <div style={{ textAlign: 'center' }}>
        <img src={getIconImg(feature.featureAlias)} title={feature.featureAlias} alt="Logo" className={classes.iconBox} />
        {/* <IconButton>
          <FeaturedVideoIcon style={getColorIcon(feature.featureAlias)} className={classes.iconCard} />
        </IconButton> */}
        <Divider variant="middle" />
      </div>
      <CardContent>
        <Box>
          <List dense disablePadding>
            <ListItem
              divider
              dense
              disableGutters
            >
              <ListItemText
                style={{ textAlign: 'center' }}
                primary={(
                  <>
                    <Typography
                      component="p"
                      variant="body2"
                      color="textPrimary"
                    >
                      <span className={classes.featureName}>Total: </span>
                      <span className={classes.featureValue}>{feature.quantityTx}</span>
                    </Typography>
                    <Typography
                      component="p"
                      variant="body2"
                      color="textPrimary"
                    >
                      <span className={classes.featureName}>Disponible: </span>
                      <span className={classes.featureValue}>{feature.amountBalanceTx}</span>
                    </Typography>
                    <Typography
                      component="p"
                      variant="body2"
                      color="textPrimary"
                    >
                      <span className={classes.featureName}>Usado: </span>
                      <span className={classes.featureValue}>{feature.quantityTx - feature.amountBalanceTx}</span>
                    </Typography>
                  </>
                )}
              />
            </ListItem>
          </List>
        </Box>
      </CardContent>
      <CardActions disableSpacing style={{ justifyContent: 'center' }}>
        <Button variant="contained" onClick={() => { handleClickBuyMore(feature.packageIdComplement); }} className={classes.buttonCard}>
          Comprar más
        </Button>
      </CardActions>
    </Card>
  );
};

export default withRouter(LicenseCard);
