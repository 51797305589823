import React, { useEffect, useState } from 'react';
// import { useDispatch } from 'react-redux';
// import { useSnackbar } from 'notistack';
import { makeStyles } from '@material-ui/core/styles';
import { useForm } from 'react-hook-form';
import Dialog from '@material-ui/core/Dialog';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/HighlightOff';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';

import CustomInput from '../Components/FormComponents/CustomInput';
import CustomSelect from '../Components/FormComponents/CustomSelect';
import CustomCheck from '../Components/FormComponents/CustomCheck';

import { Rules } from '../../../helpers/RHFRules';
// import { SavePromoCode } from '../../../actions/packets.action';

const packageTypeOptions = [
  { value: 'P', label: 'Porcentaje' },
  { value: 'V', label: 'Valor' },
];

const useStyles = makeStyles((theme) => ({
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    '& > *': {
      margin: 5,
    },
  },
  boxWrapperItems: {
    display: 'flex',
    '& > *': {
      flexBasis: '100%',
    },
    '& : first-child': {
      marginRight: 5,
    },
  },
  dialogActions: {
    justifyContent: 'center',
  },
}));

const defaultValues = {
  code: '',
  description: '',
  discountType: '',
  discountValue: '',
  totalPurchase: false,
  isUnique: false,
  applyToPackage: false,
  startDate: '',
  endDate: '',
  useQuantity: '',
};

const ModalNewPromo = (props) => {
  const { isOpen, onClose } = props;
  const classes = useStyles();
  const [disableUseQuantity, setUseQuantity] = useState(false);

  // const snackbar = useSnackbar();
  // const dispatch = useDispatch();

  const {
    handleSubmit, control, errors, watch, unregister, register, reset,
  } = useForm({ defaultValues });

  const { isUnique, useQuantity } = watch(['isUnique', 'useQuantity']);
  console.log(isUnique);
  console.log(useQuantity);

  // useEffect(() => {
  //   if (isUnique) {
  //     if (useQuantity !== '') reset({ useQuantity: '' });
  //     unregister('useQuantity');
  //     setUseQuantity(true);
  //   }
  //   if (!isUnique) {
  //     register('useQuantity', Rules.required);
  //   }
  // }, [isUnique]);

  const onSubmit = (data) => {
    console.log(data);
  };


  return (
    <>
      <Dialog
        fullWidth
        maxWidth="sm"
        open={isOpen}
      >
        <DialogTitle>
          <Typography color="primary">Crear promoción</Typography>
          <IconButton className={classes.closeButton} onClick={onClose}>
            <CloseIcon color="primary" />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <form
            noValidate
            autoComplete="off"
            className={classes.form}
          >
            <CustomInput
              label="Código"
              name="code"
              control={control}
              rule={Rules.required}
              error={errors}
            />
            <CustomInput
              label="Descripción"
              name="description"
              multiline
              rows={3}
              control={control}
              rule={Rules.required}
              error={errors}
            />
            <CustomSelect
              label="Tipo descuento"
              name="discountType"
              control={control}
              rule={Rules.required}
              error={errors}
              options={packageTypeOptions}
            />
            <CustomInput
              label="Valor descuento"
              name="discountValue"
              control={control}
              rule={Rules.required}
              error={errors}
            />
            <CustomCheck
              label="Total compra"
              name="totalPurchase"
              control={control}
            />
            <CustomCheck
              label="Uso único"
              name="isUnique"
              control={control}
            />
            <CustomCheck
              label="Aplicar paquete"
              name="applyToPackage"
              control={control}
            />
            <CustomInput
              label="Inicio vigencia"
              name="startDate"
              control={control}
              rule={Rules.required}
              error={errors}
            />
            <CustomInput
              label="Fin vigencia"
              name="endDate"
              control={control}
              rule={Rules.required}
              error={errors}
            />
            <CustomInput
              label="Cantidad de uso"
              name="useQuantity"
              type="number"
              control={control}
              rule={Rules.required}
              error={errors}
              disabled={disableUseQuantity}
            />
          </form>
        </DialogContent>
        <DialogActions className={classes.dialogActions} disableSpacing>
          <Button
            variant="contained"
            color="primary"
            onClick={handleSubmit(onSubmit)}
          >
            Guardar
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ModalNewPromo;
