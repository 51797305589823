import React, { useState } from 'react';
import { connect } from 'react-redux';
import { useSnackbar } from 'notistack';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import InputAdornment from '@material-ui/core/InputAdornment';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import SystemUpdateAltIcon from '@material-ui/icons/SystemUpdateAlt';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import Link from '@material-ui/core/Link';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import FilterListIcon from '@material-ui/icons/FilterList';
import SearchIcon from '@material-ui/icons/Search';
import MenuItem from '@material-ui/core/MenuItem';
import AlertConfirm from '../../../components/AlertConfirm';
import { formatter } from '../../../helpers/cartUtils';
import
{
  rejectPaymentAction,
  approvePaymentAction,
  searchPaymentsPagingAction,
} from '../../../actions/packets.action';
import { PURCHASE_TYPE } from '../../../constants/PurchaseConstants';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  tableHead: {
    backgroundColor: '#009BDD',
  },
  tableCellHead: {
    color: '#FFF',
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 150,
  },
}));

const headCells = [
  {
    id: 'id', numeric: false, disablePadding: false, label: 'Referencia',
  },
  {
    id: 'id', numeric: false, disablePadding: false, label: 'Fecha soporte',
  },
  {
    id: '_cliente', numeric: false, disablePadding: false, label: 'Cliente',
  },
  {
    id: '_tipoDocumento', numeric: false, disablePadding: false, label: 'Tipo documento',
  },
  {
    id: '_documento', numeric: false, disablePadding: false, label: 'Documento',
  },
  {
    id: '_valor', numeric: true, disablePadding: false, label: 'Valor a pagar',
  },
  {
    id: 'btn1', numeric: false, disablePadding: true, label: '',
  },
  {
    id: 'btn2', numeric: false, disablePadding: true, label: '',
  },
  {
    id: 'btn3', numeric: false, disablePadding: true, label: '',
  },
];

function EnhancedTableHead(props) {
  const {
    classes, order, orderBy, onRequestSort,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead className={classes.tableHead}>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            className={classes.tableCellHead}
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'default'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

function FormatDate(pdate) {
  return new Intl.DateTimeFormat('en-GB').format(new Date(pdate));
}


const GridPaymentsPaging = (props) => {
  const classes = useStyles();
  const {
    rows,
    totalRows,
    type,
    handleChangePage,
    setPage,
    page,
    rejectTypes,
    rejectPayment,
    approvePayment,
    searchPaymentsPaging,
    isSearchServer,    
  } = props;
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('_referencia');
  const [selected, setSelected] = useState([]);
  const [search, setSearch] = React.useState();
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [openAlertAprobe, setOpenAlertAprobe] = useState(false);
  const [openAlertReject, setOpenAlertReject] = useState(false);
  const [openAlertInfo, setOpenAlertInfo] = useState(false);
  const [item, setItem] = useState();
  const [valueRejectType, setValueRejectType] = useState();
  const { enqueueSnackbar } = useSnackbar();
  const [filterData, setFilterData] = useState(rows);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = rows.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isSelected = (_referencia) => selected.indexOf(_referencia) !== -1;

  // Object.keys(rows).length && rows.forEach((element) => {
  //   console.log(element);
  // });

  const handleRejectClick = (event) => {
    setItem(event);
    setOpenAlertReject(true);
  };

  const handleApproveClick = (event) => {
    setItem(event);
    setOpenAlertAprobe(true);
  };

  const handleRejectPay = () => {
    const data = { id: item.id, idState: 7, rejectTypeId: valueRejectType };
    rejectPayment(data, enqueueSnackbar);
    setOpenAlertReject(false);
  };

  const handleApprovePay = () => {
    const data = { id: item.id, idState: 6 };
    approvePayment(data, enqueueSnackbar);
    setOpenAlertAprobe(false);
  };

  const handleRejectType = (event) => {
    setValueRejectType(event.target.id);
  };

  // const handleSearch = (event) => {
  // const result = rows.filter((data) => `${data.referenceUuid} ${data.customer.legalName} ${data.customer.documentNumber} ${data.totalPurchase}`
  //   .toLowerCase().includes(event.target.value.toLowerCase()));
  // setFilterData(result);
  //   console.log(event.target.value.toLowerCase())
  // };

  const handleViewDetailClick = (id) => {
    setValueRejectType(id);
    setOpenAlertInfo(true);
  };

  const handleViewSupport = (support) => {
    if (support.length) {
      const win = window.open(support[0].pathFile, '_blank');
      if (win != null) {
        win.focus();
      }
    }
  };

  function rejectDescription() {
    if (valueRejectType === null) return '-';
    const rejectDesc = rejectTypes.find(
      (h) => h.value === valueRejectType,
    ).text;
    return ` ${rejectDesc}`;
  }

  const handleSearchClick = (event) => {
    if (isSearchServer) {
      switch (type) {
        case 'pending':
          searchPaymentsPaging({ IdState: PURCHASE_TYPE.Pending, Search: search }, 'pending');
          break;
        case 'approved':
          searchPaymentsPaging({ IdState: PURCHASE_TYPE.Approved, Search: search }, 'approved');
          break;
        case 'rejected':
          searchPaymentsPaging({ IdState: PURCHASE_TYPE.Rejected, Search: search }, 'rejected');
          break;
        default:
          break;
      }
    } else {
      const result = rows.filter((data) => `${data.referenceUuid} ${data.customer.legalName} ${data.customer.documentNumber} ${data.totalPurchase}`
        .toLowerCase().includes(search.toLowerCase()));
      setFilterData(result);
    }
  };

  return (
    <div className={classes.root}>
      <div>
        <TextField
          size="small"
          variant="outlined"
          onChange={(e) => { setSearch(e.target.value); }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
          placeholder="Buscar"
        />
        <Button aria-label="Filter list" onClick={handleSearchClick}>
          <FilterListIcon />
          Filtrar
        </Button>
      </div>
      <Paper className={classes.paper}>
        <TableContainer>
          <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            size="small"
            aria-label="enhanced table"
          >
            <EnhancedTableHead
              classes={classes}
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={totalRows}// {rows.length}
            />
            <TableBody>
              {Object.keys(filterData).length && stableSort(filterData, getComparator(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  const isItemSelected = isSelected(row.name);
                  const labelId = `enhanced-table-checkbox-${index}`;

                  return (
                    <TableRow
                      hover
                      // onClick={(event) => handleClick(event, row.name)}
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={row.id}
                      selected={isItemSelected}
                    >
                      <TableCell component="th" id={labelId} scope="row" padding="none">
                        {row.referenceUuid}
                      </TableCell>
                      <TableCell align="right">{FormatDate(row.purchaseDate)}</TableCell>
                      <TableCell align="left">{row.customer.legalName}</TableCell>
                      <TableCell align="right">{row.customer.documentType}</TableCell>
                      <TableCell align="right">{row.customer.documentNumber}</TableCell>
                      <TableCell align="right">{formatter.format(row.totalPurchase)}</TableCell>
                      <TableCell align="center">
                        <IconButton
                          edge="end"
                          aria-label="view"
                          disabled={!row.paymentSupport.length}
                          color="secondary"
                          size="small"
                          onClick={() => { handleViewSupport(row.paymentSupport); }}
                        >
                          <SystemUpdateAltIcon color={row.paymentSupport.length ? 'error' : 'primary'} />
                        </IconButton>
                      </TableCell>
                      {type === 'pending' && (
                        <>
                          <TableCell align="center">
                            <IconButton
                              edge="end"
                              aria-label="Aprobar"
                              size="small"
                              onClick={() => { handleApproveClick(row); }}
                            >
                              <CheckCircleIcon color="secondary" />
                            </IconButton>
                          </TableCell>
                          <TableCell align="center">
                            <IconButton
                              edge="end"
                              aria-label="rechazar"
                              size="small"
                              onClick={() => { handleRejectClick(row); }}
                            >
                              <HighlightOffIcon color="error" />
                            </IconButton>
                          </TableCell>
                        </>
                      )}
                      {type === 'rejected' && (
                      <TableCell align="center">
                        <Link href="#" onClick={() => { handleViewDetailClick(row.rejectTypeId); }}>
                          Ver detalle
                        </Link>
                      </TableCell>
                      )}
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          labelRowsPerPage="Registros por página"
          component="div"
          count={totalRows}// {rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={(e, newpage) => { handleChangePage(e, newpage, type, rowsPerPage); }}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </Paper>
      {openAlertInfo
         && (
         <AlertConfirm
           isOpen={openAlertInfo}
           tituloModal="Motivo del rechazo"
           mensajeModal={rejectDescription()}
           handleAccept={() => setOpenAlertInfo(false)}
           onClose={() => setOpenAlertInfo(false)}
         />
         )}
      {openAlertAprobe
        && (
          <AlertConfirm
            isOpen={openAlertAprobe}
            tituloModal="Aprobar la compra"
            mensajeModal="¿Está segúro que desea aprobar la compra?"
            handleAccept={handleApprovePay}
            onClose={() => setOpenAlertAprobe(false)}
          />
        )}
      {openAlertReject
        && (
          <AlertConfirm
            isOpen={openAlertReject}
            tituloModal="Rechazar compra"
            mensajeModal="¿Está segúro que desea rechazar el pago?"
            textButtonAcept="Rechazar"
            handleAccept={handleRejectPay}
            onClose={() => setOpenAlertReject(false)}
          >
            <TextField
              select
              label="Motivo de rechazo"
              size="small"
              variant="outlined"
              required
              fullWidth
              style={{ marginRight: 10, marginBottom: 10, color: 'red' }}
            >
              <MenuItem>Seleccione</MenuItem>
              {rejectTypes.map(
                (td) => (
                  <MenuItem
                    id={td.value}
                    key={td.value}
                    value={td.value}
                    onClick={handleRejectType}
                  >
                    {td.text}
                  </MenuItem>
                ),
              )}
            </TextField>
          </AlertConfirm>
        )}
    </div>
  );
};

const mapStateToProps = ({ general }) => ({
  rejectTypes: general.rejectTypes,
});

const mapDispatchToProps = {
  rejectPayment: rejectPaymentAction,
  approvePayment: approvePaymentAction,
  searchPaymentsPaging: searchPaymentsPagingAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(GridPaymentsPaging);
