/* eslint-disable import/prefer-default-export */
import { createMuiTheme } from '@material-ui/core/styles';
import background from '../assets/img/background/firmaEmail.png';
import logo from '../assets/img/logo/firmaEmail.svg';

export default createMuiTheme({
  palette: {
    primary: {
      main: '#009B38',
    },
    secondary: {
      main: '#E55200',
    },
  },
  logo,
  colorHeader: '#EDEDED',
  home: {
    paquetes: {
      backgroundColor: '#F6F6F6',
    },
    combos: {
      backgroundColor: '#ECF0DD',
      border: '1px solid #4DA623',
    },
    agregados: {
      backgroundColor: '#F8F8F8',
      border: '1px solid #B2B2B2',
    },
    bgColorHead: {
      bases: 'green01,green02,green03',
      combos: 'green',
      complement:'green04',
    },
    sliderComboText: {
    },
    sliderComplementText: {
    },
    sliderBackGroundColor: {
    }, 
    cart: {
      title: {
        color: '#009B38',
      },
      tableHead: {
        backgroundColor: '#54BA35',
      },
      totalText: {
        color: '#00732B',
      },
      promoForm: {
        backgroundColor: '#F1F1F1',
        color: '#B2B2B2',
      },
      slider:{
      },
      btnPromotional: {
        backgroundColor: '#95C11F',
      },
      tableCell: {
        color: '#fff',
      },
      textPromotional: {
        backgroundColor: '#fff',
      },
      btnContinue: {
        backgroundColor: '#E55200',
        color: '#fff',
      }
    }
  },
  miplan: {
    title: {
      color: '#009B38',
    },
    subtitle: {
      color: '#6D6E72',
    },
    cardHeader: {
      color:'#fff',
      backgroundColor: '#8C8C8C',
    },
    featureName: {      
    },
    featureValue: {   
      color: '#009B38',   
    },
    buttonCard: {   
      backgroundColor:'#009B38', 
      color: '#fff',
    },
    iconCard: {
      color: '#009B38',
    },    
  }, 
  overrides: {
    MuiButton: {
      root: {
        textTransform: 'none',
      },
      label: {
        fontWeight: 'bold',
      },
    },
    MuiCssBaseline: {
      '@global': {
        body: {
          background: `url(${background}) no-repeat center center fixed`,
          WebkitBackgroundSize: 'cover',
          MozBackgroundSize: 'cover',
          OBackgroundSize: 'cover',
          backgroundSize: 'cover',
        },
      },
    },
  },
});
