import {
  LOADING, GET_DOCUMENT_TYPE, GET_ASSISTANT_ADDRESS,
  GET_STATES_LIST, SET_PURCHASE_PARAMETERS,
  SET_REJECT_TYPES, SET_APP_NAME, SET_SERVICE_TYPES,
} from '../types/general.type';

const INITIAL_STATE = {
  appName: undefined,
  loading: false,
  assistantAddress: undefined,
  documentTypes: [],
  statesAndCities: undefined,
  citiesList: [],
  rejectTypes: [],
  serviceTypes: [],
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case LOADING:
      return {
        ...state,
        loading: action.payload,
      };
    case SET_APP_NAME:
      return {
        ...state,
        appName: action.payload,
      };
    case GET_DOCUMENT_TYPE:
      return {
        ...state,
        documentTypes: action.payload,
      };
    case GET_ASSISTANT_ADDRESS:
      return {
        ...state,
        assistantAddress: {
          itemsAddress: action.payload.itemsAddress,
          itemsAddressLetters: action.payload.itemsAddressLetters,
          itemsAddressBis: action.payload.itemsAddressBis,
          itemsAddressCardinal: action.payload.itemsAddressCardinal,
        },
      };
    case GET_STATES_LIST:
      return {
        ...state,
        statesAndCities: {
          statesList: action.payload.statesList,
          itemsStatesList: action.payload.itemsStatesList,
        },
      };
    case SET_PURCHASE_PARAMETERS:
      return {
        ...state,
        documentTypes: action.payload.docTypes,
        assistantAddress: {
          itemsAddress: action.payload.assistantAddressTypes.itemsAddress,
          itemsAddressLetters: action.payload.assistantAddressTypes.itemsAddressLetters,
          itemsAddressBis: action.payload.assistantAddressTypes.itemsAddressBis,
          itemsAddressCardinal: action.payload.assistantAddressTypes.itemsAddressCardinal,
        },
        statesAndCities: {
          statesList: action.payload.statesAndCities.statesList,
          itemsStatesList: action.payload.statesAndCities.itemsStatesList,
        },
      };
    case SET_REJECT_TYPES:
      return {
        ...state,
        rejectTypes: action.payload,
      };
    case SET_SERVICE_TYPES:
      return {
        ...state,
        serviceTypes: action.payload,
      };
    default:
      return state;
  }
};
