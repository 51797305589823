import axios from 'axios';
import { API_LICENCIAMIENTO } from '../config/config';
import {
  LOADING, SET_PURCHASE_PARAMETERS, SET_REJECT_TYPES, SET_SERVICE_TYPES
} from '../types/general.type';

// export const getDocumentTypesAction = () => async (dispatch) => {
//   const documentTypes = [];
//   await axios.get(`${API_LICENCIAMIENTO}${'license/api/Parameter/getAllDocumentTypes'}`)
//     .then((res) => {
//       res.data.result.records.forEach((item) => documentTypes.push(
//         {
//           value: item.id,
//           text: item.documentTypeDesciption,
//         },
//       ));

//       dispatch({
//         type: GET_DOCUMENT_TYPE,
//         payload: documentTypes,
//       });
//     })
//     .catch((err) => {
//       console.log(err);
//     });
// };

// export const getAssistantddressAction = () => async (dispatch) => {
//   const itemsAddress = [];
//   const itemsAddressLetters = [];
//   const itemsAddressBis = [];
//   const itemsAddressCardinal = [];

//   await axios.get(`${API_LICENCIAMIENTO}${'license/api/parameter/getAddressConfiguration'}`)
//     .then((res) => {
//       const getAddress = res.data.result.records.filter((x) => x.parameterCode === 'TIPO_NOMENCLATURA');
//       const getAddressLetters = res.data.result.records.filter((x) => x.parameterCode === 'TIPO_LETRA_NOMENCLATURA');
//       const getAddressBis = res.data.result.records.filter((x) => x.parameterCode === 'TIPO_BIS');
//       const getAddressCardinal = res.data.result.records.filter((x) => x.parameterCode === 'TIPO_CARDINAL');

//       getAddress.forEach((item) => itemsAddress.push(
//         {
//           value: item.parameterKey,
//           text: item.parameterValue,
//         },
//       ));

//       getAddressLetters.forEach((item) => itemsAddressLetters.push(
//         {
//           value: item.parameterKey,
//           text: item.parameterValue,
//         },
//       ));

//       getAddressBis.forEach((item) => itemsAddressBis.push(
//         {
//           value: item.parameterKey,
//           text: item.parameterValue,
//         },
//       ));

//       getAddressCardinal.forEach((item) => itemsAddressCardinal.push(
//         {
//           value: item.parameterKey,
//           text: item.parameterValue,
//         },
//       ));

//       const assistantAddressTypes = {
//         itemsAddress,
//         itemsAddressLetters,
//         itemsAddressBis,
//         itemsAddressCardinal,
//       };

//       dispatch({
//         type: GET_ASSISTANT_ADDRESS,
//         payload: assistantAddressTypes,
//       });
//     })
//     .catch((err) => {
//       console.log('error', err);
//     });
// };

// export const getStatesListAction = () => async (dispatch) => {
//   const itemsStatesList = [];

//   await axios.get(`${API_LICENCIAMIENTO}${'license/api/parameter/getListStatesAndCities'}`)
//     .then((res) => {
//       const statesList = res.data.result.records;
//       statesList.forEach((item) => itemsStatesList.push(
//         {
//           value: item.id,
//           text: item.stateName,
//         },
//       ));

//       const statesAndCities = {
//         statesList,
//         itemsStatesList,
//       };

//       dispatch({
//         type: GET_STATES_LIST,
//         payload: statesAndCities,
//       });
//     })
//     .catch((err) => {
//       console.log('error', err);
//     });
// };

export const getPurchaseOrderParametersAction = () => async (dispatch) => {
  try {
    const docTypes = [];
    const itemsStatesList = [];
    const itemsAddress = [];
    const itemsAddressLetters = [];
    const itemsAddressBis = [];
    const itemsAddressCardinal = [];

    dispatch({ type: LOADING, payload: true });

    const statesCities = await axios.get(`${API_LICENCIAMIENTO}/license/api/parameter/GetListStatesAndCities`);
    const documentesTypes = await axios.get(`${API_LICENCIAMIENTO}/license/api/parameter/GetAllDocumentTypes`);
    const addresConfiguration = await axios.get(`${API_LICENCIAMIENTO}/license/api/parameter/GetAddressConfiguration`);

    statesCities.data.result.records.forEach((item) => itemsStatesList.push(
      {
        value: item.id,
        text: item.stateName,
      },
    ));
    const statesAndCities = {
      statesList: statesCities.data.result.records,
      itemsStatesList,
    };

    documentesTypes.data.result.records.forEach((item) => docTypes.push(
      {
        value: item.id,
        text: item.documentTypeDesciption,
      },
    ));

    const getAddress = addresConfiguration.data.result.records.filter((x) => x.parameterCode === 'TIPO_NOMENCLATURA');
    const getAddressLetters = addresConfiguration.data.result.records.filter((x) => x.parameterCode === 'TIPO_LETRA_NOMENCLATURA');
    const getAddressBis = addresConfiguration.data.result.records.filter((x) => x.parameterCode === 'TIPO_BIS');
    const getAddressCardinal = addresConfiguration.data.result.records.filter((x) => x.parameterCode === 'TIPO_CARDINAL');

    getAddress.forEach((item) => itemsAddress.push(
      {
        value: item.parameterKey,
        text: item.parameterValue,
      },
    ));

    getAddressLetters.forEach((item) => itemsAddressLetters.push(
      {
        value: item.parameterKey,
        text: item.parameterValue,
      },
    ));

    getAddressBis.forEach((item) => itemsAddressBis.push(
      {
        value: item.parameterKey,
        text: item.parameterValue,
      },
    ));

    getAddressCardinal.forEach((item) => itemsAddressCardinal.push(
      {
        value: item.parameterKey,
        text: item.parameterValue,
      },
    ));

    const assistantAddressTypes = {
      itemsAddress,
      itemsAddressLetters,
      itemsAddressBis,
      itemsAddressCardinal,
    };

    const data = {
      docTypes,
      assistantAddressTypes,
      statesAndCities,
    };

    dispatch({
      type: SET_PURCHASE_PARAMETERS,
      payload: data,
    });

    dispatch({ type: LOADING, payload: false });
  } catch (error) {
    console.error(error);
  }
};

export const getRejectTypesAction = () => async (dispatch) => {
  try {
    const rejectTypes = [];
    const rejectsTypesData = await axios.get(`${API_LICENCIAMIENTO}/license/api/parameter/GetAllRejectTypes`);
    rejectsTypesData.data.result.records.forEach((item) => rejectTypes.push(
      {
        value: item.id,
        text: item.rejectTypeDescription,
      },
    ));

    dispatch({
      type: SET_REJECT_TYPES,
      payload: rejectTypes,
    });
  } catch (error) {
    console.error(error);
  }
};

export const getServiceTypesAction = () => async (dispatch) => {
  try {
    const serviceTypes = [];
    const serviceTypesData = await axios.get(`${API_LICENCIAMIENTO}/license/api/parameter/GetAllServiceTypes`);
    serviceTypesData.data.result.records.forEach((item) => serviceTypes.push(
      {
        value: item.id,
        label: item.serviceType1,
      },
    ));

    dispatch({
      type: SET_SERVICE_TYPES,
      payload: serviceTypes,
    });
  } catch (error) {
    console.error(error);
  }
};


export const test = () => {};
