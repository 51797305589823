import axios from 'axios';
import { useHistory } from 'react-router-dom';
import { SET_USER_INFO } from '../types/auth.type';
import { API_LICENCIAMIENTO } from '../config/config';

import { getInfoToken } from '../helpers/authUtils';

export const getPlainToken = (token, pathOrigin) => async (dispatch) => {
  try {
    const history = useHistory();
    const data = { token };
    const response = await axios.post(`${API_LICENCIAMIENTO}/license/api/Auth/GetPlainInfo`, data);
    if (response.status === 200) {
      localStorage.setItem('appsUserToken', response.data);
      const userInfo = getInfoToken();
      dispatch({ type: SET_USER_INFO, payload: userInfo });
      if (pathOrigin.includes('myplan')) {
        history.push('/myplan');
      } else {
        history.push('/');
      }
    }
  } catch (error) {
    console.error(error);
  }
};

export const setInfoToken = () => (dispatch) => {
  const userInfo = getInfoToken();
  dispatch({ type: SET_USER_INFO, payload: userInfo });
};

export const setLogOutAction = (history) => (dispatch) => {
  try {
    // const history = useHistory();
    localStorage.removeItem('appsUserToken');
    dispatch({ type: SET_USER_INFO, payload: undefined });
    history.push('/');
  } catch (error) {
    console.error(error);
  }
};

export const test = () => {};
