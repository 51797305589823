import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/HighlightOff';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import DialogContentText from '@material-ui/core/DialogContentText';


const useStyles = makeStyles((theme) => ({
  root: {
  },
  titleAlert: {
    color: '#003582',
    fontWeight: 700,
    fontSize: 25,
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
  },
  dialogContent: {
    marginBottom: 20,
  },
  promoForm: {
    flexDirection: 'row',
    border: '1px solid #F1F1F1',
    padding: 15,
    marginBottom: 20,
  },
  divider: {
    marginBottom: 20,
  },
  ButtonCancel: {
    marginLeft: 15,
  }
}));

const AlertConfirm = (props) => {
  const {
    isOpen, onClose, tituloModal, mensajeModal, textButtonAcept, onCancel, handleAccept,
  } = props;
  const classes = useStyles();

  return (
    <>
      <Dialog
        fullWidth
        maxWidth="xs"
        open={isOpen}
      >
        <DialogTitle>
          <Typography align="center" color="secondary" variant="h6" >{tituloModal}</Typography>
          <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent className={classes.dialogContent}>
          <DialogContentText>
            {mensajeModal}
          </DialogContentText>
          {props.children}
          <Grid container justify="center">
            <Button
              variant="contained"
              color="primary"
              onClick={handleAccept}
            >
              {textButtonAcept || 'Aceptar'}
            </Button>
            {onCancel
              && (
              <Button
                variant="contained"
                color="secundary"
                onClick={onClose}
                className={classes.ButtonCancel}
              >
                Cancelar
              </Button>
              )}
          </Grid>
        </DialogContent>
      </Dialog>
    </>
  );
};


export default AlertConfirm;
