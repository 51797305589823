/* eslint-disable no-prototype-builtins */
import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { connect } from 'react-redux';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { useForm, Controller } from 'react-hook-form';
import { useSnackbar } from 'notistack';
import Dialog from '@material-ui/core/Dialog';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/HighlightOff';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import CircularProgress from '@material-ui/core/CircularProgress';

import TableContainer from '@material-ui/core/TableContainer';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';

import SliderCard from '../SliderCard';

import { Rules } from '../../helpers/RHFRules';
import { CleanCart } from '../../helpers/cartUtils';

import {
  setStatusCart,
  addToCartAction,
  deleteOfCartAction,
  applyPromotionCodeAction,
} from '../../actions/home.action';

const formatter = new Intl.NumberFormat(undefined, {
  style: 'currency',
  currency: 'COP',
  minimumFractionDigits: 0,
  maximumFractionDigits: 0,
});

const getSubtotal = (cart) => formatter.format(
  cart.reduce(
    (previousValue, currentValue) => (
      (currentValue.price * currentValue.number) + previousValue
    ), 0,
  ),
);

const useStyles = makeStyles((theme) => ({
  root: {
  },
  titleModalCart: {
    ...theme.home.cart.title,
    fontWeight: 700,
    fontSize: 25,
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
  },
  tableCotainer: {
    marginBottom: 20,
    borderRadius: 5,
  },
  table: {
    minWidth: 300,
  },
  tableHead: {
    ...theme.home.cart.tableHead,
    heigth: 35, 
  },
  dialogContent: {
    marginBottom: 20,
  },
  promoForm: {
    ...theme.home.cart.promoForm,
    width: 'fit-content',
    display: 'flex',
    alignItems: 'flex-start',
    borderRadius: 5,
    // border: '1px solid #F1F1F1',
    padding: 15,
    marginBottom: 20,
  },
  divider: {
    marginBottom: 20,
  },
  totalWrapper: {
    float: 'right',
  },
  totalText: {
    ...theme.home.cart.totalText,
    fontSize: 20,
  },
  taxInclude: {
    fontSize: 14,
    color: '#B4B4B4',
    textAlign: 'right',
    marginBottom: 15,
  },
  btnPromotional: {
    ...theme.home.cart.btnPromotional,
  },
  tableCell: {
    ...theme.home.cart.tableCell,
  },
  textPromotional: {
    ...theme.home.cart.textPromotional,
    marginRight: 10,
  },
  btnContinue: {
    ...theme.home.cart.btnContinue,
  }
}));

const CartModal = (props) => {
  const {
    isOpen, onClose, cart, addToCart, deleteOfCart, applyPromotionCode, promoCode, history,
  } = props;

  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.down('xs'));

  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);

  const classes = useStyles();
  const cartClean = CleanCart(cart, promoCode);

  const {
    handleSubmit, control, errors,
  } = useForm();

  const handleClickPurchase = () => {
    onClose();
    history.push('/registerPurchaseOrder');
  };

  const handleQuantity = (e, row) => {
    const { value } = e.target;
    const { id, number } = row;
    const cartItem = cart.find((item) => item.packageId === id);
    if (value > number) addToCart(cartItem);
    else deleteOfCart(cartItem.packageId, false);
  };

  const handleApplyOfert = (data) => {
    applyPromotionCode(data, setLoading, enqueueSnackbar);
  };


  const unique = cartClean.length === 1;

  return (
    <>
      <Dialog
        fullWidth
        maxWidth="md"
        open={isOpen}
      >
        <DialogTitle>
          <Typography className={classes.titleModalCart}>{unique ? cart[0].packageName : 'Carrito de compras'}</Typography>
          <IconButton aria-label="close" color="primary" className={classes.closeButton} onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent className={classes.dialogContent}>
          <Grid container>
            { unique && !isXs
            && (
            <Grid item xs={4}>
              <SliderCard card={cart[0]} showActions={false} showHeader={false} isbgColor colorBgSlider={theme.home.cart.slider.backgroundColor} />
            </Grid>
            )}
            <Grid item xs={(unique && !isXs) ? 8 : 12}>

              <TableContainer className={classes.tableCotainer}>
                <Table className={classes.table}>
                  <TableHead className={classes.tableHead}>
                    <TableRow>
                      <TableCell className={classes.tableCell}>Producto</TableCell>
                      <TableCell className={classes.tableCell} align="right">Cantidad</TableCell>
                      <TableCell className={classes.tableCell} align="right">Valor unitario</TableCell>
                      <TableCell className={classes.tableCell} align="right">Subtotal</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {cartClean.map((row) => (
                      <TableRow key={row.id}>
                        <TableCell component="th" scope="row">
                          {row.name}
                        </TableCell>
                        <TableCell align="right">
                          <TextField
                            variant="outlined"
                            size="small"
                            type="number"
                            name="quantity"
                            autoComplete="off"
                            defaultValue={row.number}
                            inputProps={{
                              min: '1', max: '999', step: '1', style: { textAlign: 'center', width: '50px' },
                            }}
                            onKeyDown={(e) => e.preventDefault()}
                            onChange={(e) => handleQuantity(e, row)}
                          />
                        </TableCell>
                        <TableCell align="right">{formatter.format(row.price)}</TableCell>
                        <TableCell align="right">{formatter.format(row.price * row.number)}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>

              <form
                noValidate
                autoComplete="off"
                onSubmit={handleSubmit(handleApplyOfert)}
                className={classes.promoForm}
              >
                <FormControl>
                  <Controller
                    as={(
                      <TextField
                        label={isXs ? null : 'Código promocional'}
                        size="small"
                        variant="outlined"
                        className={classes.textPromotional}
                        FormHelperTextProps={{ style: { marginRight: 0 } }}
                        helperText={errors.PromotionCode ? errors.PromotionCode.message : null}
                      />
                  )}
                    name="PromotionCode"
                    control={control}
                    rules={Rules.required}
                    defaultValue={promoCode ? promoCode.promotionCode : ''}
                  />
                </FormControl>
                <Button
                  variant="contained"
                  color="primary"
                  type="submit"
                  style={{ marginTop: 2 }}
                  className={classes.btnPromotional}
                >
                  {loading ? 'Cargando' : 'Aplicar'}
                  {loading && <CircularProgress color="secondary" size={25} />}
                </Button>
              </form>
              <Divider className={classes.divider} variant="fullWidth" />

              <Box className={classes.totalWrapper}>
                <Typography className={classes.totalText}>
                  <>
                    <span>Total: </span>
                    <span style={{ fontWeight: 800 }}>{getSubtotal(cartClean)}</span>
                  </>
                </Typography>
                <Typography className={classes.taxInclude}>Impuesto incluido</Typography>
                <Button
                  variant="contained"
                  color="primary"
                  fullWidth
                  onClick={handleClickPurchase}
                  className={classes.btnContinue}
                >
                  Continuar
                </Button>
              </Box>

            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </>
  );
};

const mapStateToProps = ({ home }) => ({
  isOpen: home.isCartOpen,
  cart: home.cart,
  promoCode: home.promoCode,
});

const mapDispatchToProps = {
  onClose: () => setStatusCart(false),
  addToCart: addToCartAction,
  deleteOfCart: deleteOfCartAction,
  applyPromotionCode: applyPromotionCodeAction,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CartModal));
