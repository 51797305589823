import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';
import Container from '@material-ui/core/Container';
import GridServices from './Services/GridServices';
import { getServicesAction } from '../../../actions/service.action';
import { getServiceTypesAction } from '../../../actions/general.action';

const useStyles = makeStyles((theme) => ({
  root: {
    width: 280,
    borderRadius: 16,
    paddingBottom: 10,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  paper: {
    padding: '5px',
    marginTop: '20px',
    opacity: '0.95',
    borderRadius: 10,
  },
  cardContent: {
    padding: 0,
  },
  title: {
    fontSize: '22px',
    letterSpacing: '0px',
    lineHeight: 1,
  },
  subtitle: {
    fontSize: '30px',
    letterSpacing: '0px',
    fontWeight: 'bold',
    lineheight: 1,
  },
  text: {
    fontSize: '16px',
    letterSpacing: '0px',
    lineheight: 1,
  },
  tab: {
    borderBottom: '1px solid #ccc',
    textTransform: 'none',
  },
  paperTab: {
    backgroundColor:'#fff',
    margin: 20,
    borderRadius: 10,
    border: `1px solid rgba(0,155,221,0.3)`,
  }
}));

function TabPanel(props) {
  const {
    children, value, index, ...other
  } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
      <Box p={3}>
        <Typography>{children}</Typography>
      </Box>
      )}
    </div>
  );
}

const Configuration = (props) => {
  const classes = useStyles();
  const { getServices, services, loading, getServiceTypes } = props;
  const [value, setValue] = useState(0);
  const [page, setPage] = React.useState(0);

  useEffect(() => {
    getServices();
    getServiceTypes();
  }, []);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    setPage(0);
  };

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }

  return (
    <>
      <Container component="main" maxWidth="lg">
        <Paper elevation={3} className={classes.paper}>
          <Tabs
            value={value}
            indicatorColor="secondary"
            textColor="secondary"
            onChange={handleChange}
            aria-label="disabled tabs example"
          >
            <Tab className={classes.tab} label="Producto o servicio" {...a11yProps(0)} />
            <Tab className={classes.tab} label="Tipo características" {...a11yProps(1)} />
            <Tab className={classes.tab} label="Características del servicio" {...a11yProps(2)} />
            <Tab className={classes.tab} label="Códigos promocionales" {...a11yProps(3)} />
          </Tabs>
          {loading && <CircularProgress color="secondary" size={25} />}
          <Paper elevation={3} className={classes.paperTab}>
            <TabPanel value={value} index={0}>
              {services.length
                && (
                <GridServices
                  rows={services}
                  type="pending"
                />
                )}
            </TabPanel>
          </Paper>
        </Paper>
      </Container>
    </>
  );
};

const mapStateToProps = ({ service }) => ({
  services: service.services,
  loading: service.loading,
});

const mapDispatchToProps = {
  getServices: getServicesAction,
  getServiceTypes: getServiceTypesAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(Configuration);
