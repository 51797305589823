import React from 'react';
import { connect } from 'react-redux';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { green } from '@material-ui/core/colors';
import Card from '@material-ui/core/Card';
import Typography from '@material-ui/core/Typography';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Badge from '@material-ui/core/Badge';
import Box from '@material-ui/core/Box';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import CheckIcon from '@material-ui/icons/Check';
import AddCircleOutlineRoundedIcon from '@material-ui/icons/AddCircleOutlined';

import headerBlue from '../assets/img/sliderCard/blue.svg';
import headerGreen from '../assets/img/sliderCard/green.svg';
import headerYellow from '../assets/img/sliderCard/yellow.svg';
import headerYellow01 from '../assets/img/sliderCard/yellow01.svg';
import headerOrange from '../assets/img/sliderCard/orange.svg';
import headerOrange01 from '../assets/img/sliderCard/orange01.svg';
import headerOrange02 from '../assets/img/sliderCard/orange02.svg';
import headerOrange03 from '../assets/img/sliderCard/orange03.svg';
import headerOrange04 from '../assets/img/sliderCard/orange04.svg';
import headerRed01 from '../assets/img/sliderCard/red01.svg';
import headerGreen01 from '../assets/img/sliderCard/green01.svg';
import headerGreen02 from '../assets/img/sliderCard/green02.svg';
import headerGreen03 from '../assets/img/sliderCard/green03.svg';
import headerGreen04 from '../assets/img/sliderCard/green04.svg';
import headerGray from '../assets/img/sliderCard/gray.svg';

import { formatter } from '../helpers/cartUtils';
import { addToCartAction, directPurchaseAction } from '../actions/home.action';

const useStyles = makeStyles((thme)=>({
  root: {
    width: 280,
    borderRadius: 16,
    paddingBottom: 10,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  cardContent: {
    padding: 0,
  },
  cardHeader: {
    // backgroundImage: `url(${header})`,
    backgroundRepeat: 'no-repeat',
    height: '140px',
    padding: '10px 20px 0',
    color: 'white',
    fill: '#FFFFF',
  },
  title: {
    fontSize: '16px',
    letterSpacing: '0px',
    lineHeight: 1,
  },
  subtitle: {
    fontSize: '22px',
    letterSpacing: '0px',
    fontWeight: 'bold',
    lineheight: 1,
  },
  text: {
    fontSize: '15px',
    letterSpacing: '0px',
    lineheight: 1,
  },
  cardList: {
    padding: 10,
    fontSize: 10,
    letterSpacing: '0px',
    lineheight: 1,
  },
  addToCartButton: {
    width: 37,
    minWidth: 0,
  },
}));

const SliderCard = (props) => {
  const {
    card, showList = true, showActions = true, showHeader = true, addToCart, directPurchase, isbgColor, colorBgSlider, colorBgHeader, textColor,
  } = props;
  const { packageName, itemsPackageFeatures: listItems } = card;
  const classes = useStyles();
  const theme = useTheme();

  const subtitleToShow = (item) => `${formatter.format(item.packagePrice / 12)}/mes`;
  const textToShow = (item) => {
    if (item.applyOffer) {
      return (
        <>
          <s style={{ fontSize: 12 }}>{formatter.format(item.packagePrice)}</s>
          <span>{` ${formatter.format(item.packagePriceWithDiscount)}/anual`}</span>
        </>
      );
    }
    return `${formatter.format(item.packagePrice)}/anual`;
  };

  const getBackgroundImage = () => {
    switch (colorBgHeader) {
      case 'blue':
        return headerBlue;
      case 'green':
        return headerGreen;
      case 'green01':
        return headerGreen01;
      case 'green02':
        return headerGreen02; 
      case 'green03':
        return headerGreen03; 
      case 'green04':
        return headerGreen04;
      case 'yellow':
        return headerYellow;
      case 'yellow01':
        return headerYellow01;
      case 'orange':
        return headerOrange;
      case 'orange01':
        return headerOrange01;
      case 'orange02':
        return headerOrange02;
      case 'orange03':
        return headerOrange03;
      case 'orange04':
        return headerOrange04;
      case 'red01':
        return headerRed01;
      case 'gray':
        return headerGray;
      default:
        return headerBlue;
    }
  };
  return (
    <Card className={classes.root} variant="outlined" style={{ minHeight: showList ? 568 : 215, backgroundColor: isbgColor ? colorBgSlider : 'transparent' }}>
      <CardContent className={classes.cardContent}>
        {showHeader
        && (
        <Box className={classes.cardHeader} style={{ backgroundImage: `url(${getBackgroundImage()})` }}>
          <Typography className={classes.title} style={{color: textColor}}>{packageName}</Typography>
          <Typography className={classes.subtitle} style={{color: textColor}}>{subtitleToShow(card)}</Typography>
          <Typography className={classes.text} style={{color: textColor}}>{textToShow(card)}</Typography>
        </Box>
        )}
        {showList
        && (
        <Box className={classes.cardList}>
          <List dense disablePadding>
            { listItems.map((item) => (
              <ListItem
                key={item.itemPackageId}
                divider
                dense
                disableGutters
              >
                <ListItemIcon style={{ minWidth: 0, marginRight: '10px' }}>
                  <CheckIcon fontSize="small" style={{ color: green[500] }} />
                </ListItemIcon>
                {
                  item.quantity === 0 ? (
                    <ListItemText primary={`${item.serviceFeature.serviceFeatureDescription}`} />) : (
                      <ListItemText primary={`${item.quantity === 1000000 ? '(Ilimitadas)' : item.quantity} ${item.serviceFeature.serviceFeatureDescription}`} />)
                }
              </ListItem>
            ))}
          </List>
        </Box>
        )}
      </CardContent>
      {
        showActions && (
        <CardActions style={{ justifyContent: 'center' }}>
          <Button variant="contained" color="primary" onClick={() => directPurchase(card)}>
            ¡COMPRAR YA!
          </Button>
          <Button
            variant="contained"
            color="primary"
            className={classes.addToCartButton}
            onClick={() => addToCart(card)}
          >
            <Badge badgeContent={<AddCircleOutlineRoundedIcon color="secondary" fontSize="small" />}>
              <ShoppingCartIcon style={{ color: 'white' }} />
            </Badge>
          </Button>
        </CardActions>
        )
      }
    </Card>
  );
};

const mapDispatchToProps = {
  addToCart: addToCartAction,
  directPurchase: directPurchaseAction,
};

export default connect(null, mapDispatchToProps)(SliderCard);
