import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Grid from '@material-ui/core/Grid';
import Badge from '@material-ui/core/Badge';
import Box from '@material-ui/core/Box';
import Hidden from '@material-ui/core/Hidden';
import IconButton from '@material-ui/core/IconButton';
import Divider from '@material-ui/core/Divider';
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';
import AccountCircleOutlinedIcon from '@material-ui/icons/AccountCircleOutlined';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';

import CartMenu from './CartMenu';
import ProfileMenu from './ProfileMenu';
import CartModal from './CartModal';

import { setStatusCart } from '../../actions/home.action';
import { setLogOutAction } from '../../actions/auth.action';
import { getLettersAvatar } from '../../helpers/authUtils';

const useStyles = makeStyles((theme) => ({
  toolbar: {
    margin: 0,
    padding: 0,
    backgroundColor: theme.colorHeader,
  },
  content: {
    paddingRight: 10,
    paddingLeft: 10,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  logo: {
    maxWidth: 100,
  },
  divider: {
    margin: 5,
  },
  name: {
    textTransform: 'capitalize',
    marginLeft: 5,
    fontWeight: 400,
  },
}));

const Header = (props) => {
  const {
    cart, items, setIsCartOpen, userInfo, setLogOut, history,
  } = props;
  const theme = useTheme();
  const classes = useStyles();
  const [cartMenu, setCart] = useState(null);
  const [menu, setMenu] = useState(null);

  const handleClickLogOut = () => {
    setLogOut(history);
    console.log('ddddddddddddddddddd');
  };

  return (
    <>
      <AppBar position="static">
        <Toolbar className={classes.toolbar}>
          <Grid container>
            <Grid item xs={false} sm={1} />
            <Grid item xs={12} sm={10} className={classes.content}>
              <Link to="/">
                <img src={theme.logo} alt="logo" className={classes.logo} />
              </Link>
              <Box display="flex" alignItems="center">
                <IconButton onClick={(e) => setCart(e.currentTarget)}>
                  <Badge
                    color="primary"
                    badgeContent={items}
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'right',
                    }}
                  >
                    <ShoppingCartIcon />
                  </Badge>
                </IconButton>

                {userInfo
                  && (
                    <>
                      <Hidden smDown>
                        <Divider orientation="vertical" flexItem className={classes.divider} />
                        {userInfo && <Typography color="primary" className={classes.name}>{`${userInfo.name} ${userInfo.surname}`}</Typography> }
                      </Hidden>
                      <IconButton onClick={(e) => setMenu(e.currentTarget)}>
                        {userInfo
                          ? <Avatar>{getLettersAvatar(userInfo)}</Avatar>
                          : <AccountCircleOutlinedIcon />}
                      </IconButton>
                    </>
                  )}
                <CartMenu
                  cart={cart}
                  element={cartMenu}
                  setElement={setCart}
                  openCartModal={() => setIsCartOpen(true)}
                />
                {userInfo
                && <ProfileMenu element={menu} setElement={setMenu} handleLogOut={handleClickLogOut} />}
              </Box>
            </Grid>
            <Grid item xs={false} sm={1} />
          </Grid>
        </Toolbar>
      </AppBar>

      <CartModal />
    </>
  );
};

const mapStateToProps = ({ home, auth }) => ({
  userInfo: auth.userInfo,
  cart: home.cart,
  items: home.cart.length,
});

const mapDispatchToProps = {
  setIsCartOpen: setStatusCart,
  setLogOut: setLogOutAction,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Header));
