import { SET_AUTH_STATUS, SET_USER_INFO } from '../types/auth.type';

const INITIAL_STATE = {
  userInfo: undefined,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_AUTH_STATUS:
      return {
        ...state,
        isAuth: action.payload,
      };
    case SET_USER_INFO:
      return {
        ...state,
        userInfo: action.payload,
      };
    default:
      return state;
  }
};
