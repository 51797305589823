/* eslint-disable import/prefer-default-export */
/* eslint-disable no-param-reassign */
/* eslint-disable no-prototype-builtins */
import jwtDecode from 'jwt-decode';

export const getInfoToken = () => {
  const token = localStorage.getItem('appsUserToken');
  const jwt = jwtDecode(token);
  const currentTime = Date.now() / 1000;
  if (jwt.exp < currentTime) {
    console.warn('authUtils: Token removed');
    localStorage.removeItem('appsUserToken');
    return undefined;
  }
  const userInfo = { 
    name: jwt.given_name.toLowerCase(),
    surname: jwt.family_name.toLowerCase(),
    customer_id: jwt.customer_id,
    customer_type: jwt.customer_type,
    customer_value: jwt.customer_value,
   };
  return userInfo;
};

export const isValidToken = () => {
  const token = localStorage.getItem('appsUserToken');
  const jwt = jwtDecode(token);
  const currentTime = Date.now() / 1000;
  if (jwt.exp < currentTime) {
    console.warn('authUtils: Token removed');
    localStorage.removeItem('appsUserToken');
    return false;
  }
  return true;
};

export const getLettersAvatar = (userInfo) => `${userInfo.name.toUpperCase().charAt(0)}${userInfo.surname.toUpperCase().charAt(0)}`;
