import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Carousel from '../../components/Carousel';
import PromoCard from '../../components/PromoCard';
import SliderCard from '../../components/SliderCard';
import Loading from '../../components/Loading';

import { getPackagesAction } from '../../actions/home.action';
import { responsiveBase, responsiveCombo, responsiveComplementary } from '../../helpers/confResponsiveSlider';

const useStyles = makeStyles((theme) => ({
  root: {
    overflowX: 'hidden',
  },
  gridItem: {
    minHeight: 600,    
  },
  paper: {
    padding: theme.spacing(2),
    height: '100%',
  },
  paperPaquetes: {
    ...theme.home.paquetes,
  },
  paperCombos: {
    ...theme.home.combos,
  },
  paperAgregados: {
    ...theme.home.agregados,
  },
}));

const Home = (props) => {
  const { packageId } = useParams();
  const {
    packages, loading, appName, getPackage,
  } = props;
  const classes = useStyles();
  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.down('sm'));
  const {
    offers, base, combo, complementary,
  } = packages;
  // const paletteHeader = ['blue', 'green', 'yellow'];
  const paletteHeader = theme.home.bgColorHead.bases.split(',');
  useEffect(() => {
    getPackage(appName, packageId);
  }, [getPackage]);

  if (loading) return <Loading />;

  return (
    <>
      <Grid container style={{ overflowX: 'hidden', padding: isXs ? '10px 0 0' : 20 }}>
        <Grid item xs={false} sm={1} />
        <Grid item xs={12} sm={10}>
          <Grid container spacing={3} style={{ overflowX: 'hidden' }}>
            <Grid item xs={12} sm={4} className={classes.gridItem}>
              <Carousel
                autoplay
                arrows={false}                
              >
                { offers && offers.map((item) => (
                  <PromoCard
                    card={item}
                    key={item.packageId}
                  />
                ))}
              </Carousel>
            </Grid>
            <Grid item xs={12} sm={8} className={classes.gridItem}>
              <Paper elevation={5} className={`${classes.paper} ${classes.paperPaquetes}`}>
                <Carousel slidesToShow={3} responsive={responsiveBase}>
                  { base && base.map((item, index) => {
                    let pos = index > 2 ? 0 : index;
                    return (
                      <SliderCard
                        key={item.packageId}
                        card={item}
                        colorBgHeader={paletteHeader[pos]}
                        textColor="#FFF"
                      />
                    );
                    pos += 1;
                  })}
                </Carousel>
              </Paper>
            </Grid>
            <Grid item xs={12} sm={6} className={classes.gridItem}>
              <Paper elevation={5} className={`${classes.paper} ${classes.paperCombos}`}>
                <Carousel slidesToShow={2} responsive={responsiveCombo}>
                  { combo && combo.map((item) => (
                    <SliderCard
                      key={item.packageId}
                      card={item}
                      colorBgHeader={theme.home.bgColorHead.combos}
                      textColor={theme.home.sliderComboText.color}
                      isbgColor
                      colorBgSlider={theme.home.sliderBackGroundColor.color}
                    />
                  ))}
                </Carousel>
              </Paper>
            </Grid>
            <Grid item xs={12} sm={6} className={classes.gridItem}>
              <Paper elevation={5} className={`${classes.paper} ${classes.paperAgregados}`}>
                <Carousel
                  rows={2}
                  slidesToShow={2}
                  responsive={responsiveComplementary}
                >
                  { complementary && complementary.map((item) => (
                    <>
                      <SliderCard
                        key={item.packageId}
                        card={item}
                        showList={false}
                        colorBgHeader={theme.home.bgColorHead.complement}
                        textColor={theme.home.sliderComplementText.color}
                      />
                      <div style={{ height: '10px' }} />
                    </>
                  ))}
                </Carousel>
              </Paper>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={false} sm={1} />
      </Grid>
    </>
  );
};

const mapStateToProps = ({ home, general }) => ({
  loading: home.loading,
  packages: home.packages,
  appName: general.appName,
});

const mapDispatchToProps = {
  getPackage: getPackagesAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(Home);
