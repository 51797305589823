
export const API_LICENCIAMIENTO = 'https://5sw5ah9606.execute-api.us-east-2.amazonaws.com/pre';
export const API_SEGURIDAD = '';

export const API_PAYMENTSERVICESVINKEL = process.env.NODE_ENV === 'production'
  ? 'https://paymentservice.vinkel.co/v1/'
  : 'https://paymentservice.vinkel.co/v1/';

export const DEFAULTRESPONSE = {
  noResponseFromApi: 'No hay respuesta por parte del servidor. Por favor intente más tarde.',
  errorInRequest: 'El servidor no ha podido interpretar la solicitud. Por favor contacte al administrador.',
};

export const NUM_REGISTERS_PAGING = 30;
