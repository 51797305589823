import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useForm, Controller } from 'react-hook-form';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import InputLabel from '@material-ui/core/InputLabel';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import { Box } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import CreditCardIcon from '@material-ui/icons/CreditCard';
import MoneyIcon from '@material-ui/icons/Money';
import { Rules } from '../../helpers/RHFRules';
import Loading from '../../components/Loading';

import { savePurchaseAction, getCustomerInformationAction, setPersonTypeAction, setPersonTypeActionService } from '../../actions/purchaseLicense.action';
import { getPurchaseOrderParametersAction } from '../../actions/general.action';
import { CleanCart } from '../../helpers/cartUtils';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    marginTop: '30px',
    marginBottom: '10px',
    padding: '20px',
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    border: '1px solid #DEDCDA',
  },
  rowWrapper: {
    display: 'flex',
    alignItems: 'stretch',
    justifyContent: 'center',
  },
  margin: {
    margin: theme.spacing(1),
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
  contentText: {
    color: 'primary',
  },
  submit: {
    textAlign: 'center',
    padding: 10,
  },
  btn: {
    marginLeft: 10,
  },
  expanded: {
    backgroundColor: 'orange',
  },
  infoText: {
    color: 'orange',
    marginTop: -23,
    fontSize: theme.typography.pxToRem(16),
    position: 'absolute'
  },
  errorText: {
    color: '#f44336',
    marginTop: -23,
    fontSize: theme.typography.pxToRem(16),
    position: 'absolute'
  },
  errorTextDocument: {
    color: '#f44336',
    fontSize: theme.typography.pxToRem(16),
    position: 'absolute'
  },
}));

const personType = [
  { value: 'J', label: 'Persona Juridica' },
  { value: 'N', label: 'Persona Natural' },
];

const subtotal = (cart) => cart.reduce(
  (previousValue, currentValue) => (
    (currentValue.price * currentValue.number) + previousValue
  ), 0,
);

const RegisterPurchaseOrder = (props) => {
  const classes = useStyles();
  const {
    loading, loadingGeneral, userInfo,
    savePurchase, getPurchaseOrderParameters,
    assistantAddress, appName,
    documentTypes, statesAndCities,
    billingInfo, serviceInfo, getCustomerInformation, cart, history, setPersonTypeAction, setPersonTypeActionService
  } = props;
  const {
    handleSubmit, errors, control, setValue, getValues,
  } = useForm();
  const [citiesBilling, setCitiesBilling] = useState([]);
  const [citiesService, setCitiesService] = useState([]);
  const [open, setOpen] = React.useState(false);
  const [openCashPayment, setOpenToCashPayment] = React.useState(false);
  const [dataForm, setFormData] = React.useState({});
  const [personTypeBilling, setPersonTypeBilling] = React.useState('J');
  const [personTypeService, setPersonTypeService] = React.useState('J');
  const [documentTypeIdService, setDocumentTypeService] = React.useState('');
  const [check, setCheck] = useState(Boolean(Object.keys(billingInfo).lenght));
  const [checkService, setCheckService] = useState(Boolean(Object.keys(serviceInfo).lenght));
  const [paymentMethod, setPaymentMethod] = React.useState('');
  const [rules, setRules] = React.useState(Rules);
  const [documentValidationBilling, setDocumentValidationBilling] = React.useState(rules.numerico);
  const [documentValidationService, setDocumentValidationService] = React.useState([]);
  const [showErrorSameDocuments, setShowErrorSameDocuments] = React.useState(false)
  const [showErrorDocumentsBill, setShowErrorDocumentsBill] = React.useState(false)
  const [msgErrorDocumentsBill, setMsgErrorDocumentsBill] = React.useState('')
  const [showErrorDocumentsSer, setShowErrorDocumentsSer] = React.useState(false)
  const [msgErrorDocumentsSer, setMsgErrorDocumentsSer] = React.useState('')
  const [showErrorDocumentsLeg, setShowErrorDocumentsLeg] = React.useState(false)
  const [msgErrorDocumentsLeg, setMsgErrorDocumentsLeg] = React.useState('')

  const loadCustomerData = () => {
    if (userInfo) {
      const data = {
        documentType: userInfo.customer_type,
        documentNumber: userInfo.customer_value,
      };
      getCustomerInformation(data);
    }
  };


  useEffect(() => {
    if (cart.length) {
      getPurchaseOrderParameters();
      if (userInfo) {
        setCheck(true);
        setCheckService(true);
        loadCustomerData();
      }
    } else {
      history.push('/');
    }
  }, []);

  useEffect(() => {
    setValue('personTypeBilling', check ? billingInfo.personType : '');
    setPersonTypeBilling(billingInfo.personType);
    setValue('documentTypeIdBilling', check ? billingInfo.documentTypeId : '');
    setValue('documentNumberBilling', check ? billingInfo.documentNumber : '');
    setValue('legalNameBilling', check ? billingInfo.legalName : '');
    setValue('firstNameBilling', check ? billingInfo.firstName : '');
    setValue('lastNameBilling', check ? billingInfo.lastName : '');
    setValue('cellphoneNumberBilling', check ? billingInfo.cellphoneNumber : '');
    setValue('emailBilling', check ? billingInfo.email : '');
    setValue('departmentIdBilling', check ? billingInfo.departmentId : '');
    if (statesAndCities) {
      const newCities = statesAndCities.statesList.find((d) => d.id === billingInfo.departmentId);
      if (newCities) setCitiesBilling(newCities.city);
    }
    setValue('cityIdBilling', check ? billingInfo.cityId : '');
    setValue('addressField1Billing', check ? billingInfo.addressField1 : '');
    setValue('addressField2Billing', check ? billingInfo.addressField2 : '');
    setValue('addressField3Billing', check ? billingInfo.addressField3 : '');
    setValue('addressField4Billing', check ? billingInfo.addressField4 : '');
    setValue('addressField5Billing', check ? billingInfo.addressField5 : '');
    setValue('addressField6Billing', check ? billingInfo.addressField6 : '');
    setValue('addressField7Billing', check ? billingInfo.addressField7 : '');
    setValue('addressField8Billing', check ? billingInfo.addressField8 : '');
    setValue('addressField9Billing', check ? billingInfo.addressField9 : '');
    setValue('addressField10Billing', check ? billingInfo.addressField10 : '');
    setValue('addressFieldComplementary1Billing', check ? billingInfo.addressComplementaryField1 : '');
    setValue('addressFieldComplementary2Billing', check ? billingInfo.addressComplementaryField2 : '');
    setValue('addressFieldComplementary3Billing', check ? billingInfo.addressComplementaryField3 : '');
    setValue('addressFieldComplementary4Billing', check ? billingInfo.addressComplementaryField4 : '');
    setValue('fulladdressBilling', check ? billingInfo.fullAddress : '');
  }, [billingInfo]);

  useEffect(() => {
    setValue('personTypeService', checkService ? serviceInfo.personType : '');
    setPersonTypeService(serviceInfo.personType);
    setValue('documentTypeIdService', checkService ? serviceInfo.documentTypeId : '');
    setValue('documentNumberService', checkService ? serviceInfo.documentNumber : '');
    setValue('legalNameService', checkService ? serviceInfo.legalName : '');
    setValue('firstNameService', checkService ? serviceInfo.firstName : '');
    setValue('lastNameService', checkService ? serviceInfo.lastName : '');
    setValue('cellphoneNumberService', checkService ? serviceInfo.cellphoneNumber : '');
    setValue('emailService', checkService ? serviceInfo.email : '');
    setValue('departmentIdService', checkService ? serviceInfo.departmentId : '');
    if (statesAndCities) {
      const newCities = statesAndCities.statesList.find((d) => d.id === serviceInfo.departmentId);
      if (newCities) setCitiesService(newCities.city);
    }
    setValue('cityIdService', checkService ? serviceInfo.cityId : '');
    setValue('addressField1Service', checkService ? serviceInfo.addressField1 : '');
    setValue('addressField2Service', checkService ? serviceInfo.addressField2 : '');
    setValue('addressField3Service', checkService ? serviceInfo.addressField3 : '');
    setValue('addressField4Service', checkService ? serviceInfo.addressField4 : '');
    setValue('addressField5Service', checkService ? serviceInfo.addressField5 : '');
    setValue('addressField6Service', checkService ? serviceInfo.addressField6 : '');
    setValue('addressField7Service', checkService ? serviceInfo.addressField7 : '');
    setValue('addressField8Service', checkService ? serviceInfo.addressField8 : '');
    setValue('addressField9Service', checkService ? serviceInfo.addressField9 : '');
    setValue('addressField10Service', checkService ? serviceInfo.addressField10 : '');
    setValue('addressFieldComplementary1Service', checkService ? serviceInfo.addressComplementaryField1 : '');
    setValue('addressFieldComplementary2Service', checkService ? serviceInfo.addressComplementaryField2 : '');
    setValue('addressFieldComplementary3Service', checkService ? serviceInfo.addressComplementaryField3 : '');
    setValue('addressFieldComplementary4Service', checkService ? serviceInfo.addressComplementaryField4 : '');
    setValue('fulladdressService', checkService ? serviceInfo.fullAddress : '');
    setValue('legalDocumentTypeIdService', checkService ? serviceInfo.legalDocumentTypeId : '');
    setValue('legalDocumentNumberService', checkService ? serviceInfo.legalDocumentNumber : '');
    setValue('legalFirstNameService', checkService ? serviceInfo.legalFirstName : '');
    setValue('legalLastNameService', checkService ? serviceInfo.legalLastName : '');
  }, [serviceInfo]);

  const getUserInfo = (obj) => {
    if (obj.target.name === 'checkedUseCustomerBilling') setCheck(!check);
    else if (obj.target.name === 'checkedUseCustomerService') setCheckService(!checkService);
    loadCustomerData();
  };

  const setFullAddressBilling = (obj) => {
    const [event] = obj;
    const af1 = event.target.name === 'addressField1Billing' ? event.target.value : getValues('addressField1Billing');
    const af2 = event.target.name === 'addressField2Billing' ? event.target.value : getValues('addressField2Billing');
    const af3 = event.target.name === 'addressField3Billing' ? event.target.value : getValues('addressField3Billing');
    const af4 = event.target.name === 'addressField4Billing' ? event.target.value : getValues('addressField4Billing');
    const af5 = event.target.name === 'addressField5Billing' ? event.target.value : getValues('addressField5Billing');
    const af6 = event.target.name === 'addressField6Billing' ? event.target.value : getValues('addressField6Billing');
    const af7 = event.target.name === 'addressField7Billing' ? event.target.value : getValues('addressField7Billing');
    const af8 = event.target.name === 'addressField8Billing' ? event.target.value : getValues('addressField8Billing');
    const af9 = event.target.name === 'addressField9Billing' ? event.target.value : getValues('addressField9Billing');
    const af10 = event.target.name === 'addressField10Billing' ? event.target.value : getValues('addressField10Billing');
    const afc1 = event.target.name === 'addressFieldComplementary1Billing' ? event.target.value : getValues('addressFieldComplementary1Billing');
    const afc2 = event.target.name === 'addressFieldComplementary2Billing' ? event.target.value : getValues('addressFieldComplementary2Billing');
    const afc3 = event.target.name === 'addressFieldComplementary3Billing' ? event.target.value : getValues('addressFieldComplementary3Billing');
    const afc4 = event.target.name === 'addressFieldComplementary4Billing' ? event.target.value : getValues('addressFieldComplementary4Billing');


    const fullAddress = [trim(af1), trim(af2), trim(af3), trim(af4),
    trim(af5), trim(af6), trim(af7), trim(af8), trim(af9), trim(af10),
    trim(afc1), trim(afc2), trim(afc3), trim(afc4)]
    setValue('fulladdressBilling', fullAddress.filter(item => item !== "").join(' '));
    return event.target.value;
  };

  const setFullAddressService = (obj) => {
    const [event] = obj;

    const af1 = event.target.name === 'addressField1Service' ? event.target.value : getValues('addressField1Service');
    const af2 = event.target.name === 'addressField2Service' ? event.target.value : getValues('addressField2Service');
    const af3 = event.target.name === 'addressField3Service' ? event.target.value : getValues('addressField3Service');
    const af4 = event.target.name === 'addressField4Service' ? event.target.value : getValues('addressField4Service');
    const af5 = event.target.name === 'addressField5Service' ? event.target.value : getValues('addressField5Service');
    const af6 = event.target.name === 'addressField6Service' ? event.target.value : getValues('addressField6Service');
    const af7 = event.target.name === 'addressField7Service' ? event.target.value : getValues('addressField7Service');
    const af8 = event.target.name === 'addressField8Service' ? event.target.value : getValues('addressField8Service');
    const af9 = event.target.name === 'addressField9Service' ? event.target.value : getValues('addressField9Service');
    const af10 = event.target.name === 'addressField10Service' ? event.target.value : getValues('addressField10Service');
    const afc1 = event.target.name === 'addressFieldComplementary1Service' ? event.target.value : getValues('addressFieldComplementary1Service');
    const afc2 = event.target.name === 'addressFieldComplementary2Service' ? event.target.value : getValues('addressFieldComplementary2Service');
    const afc3 = event.target.name === 'addressFieldComplementary3Service' ? event.target.value : getValues('addressFieldComplementary3Service');
    const afc4 = event.target.name === 'addressFieldComplementary4Service' ? event.target.value : getValues('addressFieldComplementary4Service');

    const fullAddress = [trim(af1), trim(af2), trim(af3), trim(af4),
    trim(af5), trim(af6), trim(af7), trim(af8), trim(af9), trim(af10),
    trim(afc1), trim(afc2), trim(afc3), trim(afc4)]
    setValue('fulladdressService', fullAddress.filter(item => item !== "").join(' '));
    return event.target.value;
  };

  const trim = (campo) => {
    return (campo != null) ? campo.trim() : "";
  }

  const setPersonType = (obj) => {
    const [event] = obj;
    const { value } = event.target;
    //TODO

    if (event.target.name === 'personTypeBilling') {
      if (billingInfo.personType) {
        setPersonTypeAction(value);
      }
      setPersonTypeBilling(value);
    } else if (event.target.name === 'personTypeService') {
      if (serviceInfo.personType) {
        setPersonTypeActionService(value);
      }
      setPersonTypeService(value);
    }
    return value;
  };

  const setDocumentType = (obj) => {
    const [event] = obj;
    const { value } = event.target;

    console.log('id =>', value);
    if (event.target.name === 'documentTypeIdService') setDocumentTypeService(value);
    if (value === 6) {
      setDocumentValidationService([rules.letrasNumeros])
      console.log('valor' + documentValidationService);
    } else {
      setDocumentValidationService([rules.numerico])
    }
    return value;
  };

  const validateDocumentNumber = (documentTypeBill, documentNumberBill, option) => {
    if (documentTypeBill === undefined || documentNumberBill === undefined) {
      return true
    }
    if (documentTypeBill === 6) {
      //if (getValues('documentTypeIdBilling') === 6) {
      var letters = /^[0-9a-zA-Z]+$/;
      return updateDoc(documentNumberBill, letters, 'Solo letras y numeros', option)
    } else {
      var letters = /^[0-9]+$/;
      return updateDoc(documentNumberBill, letters, 'Solo numeros', option)
    }
  }


  const updateDoc = (documentNumber, letters, msg, option) => {
    if (!documentNumber.match(letters)) {
      if (option === 'Bill') {
        setShowErrorDocumentsBill(true)
        setMsgErrorDocumentsBill(msg)
      } else if (option === 'Ser') {
        setShowErrorDocumentsSer(true)
        setMsgErrorDocumentsSer(msg)
      } else if (option === 'Leg') {
        setShowErrorDocumentsLeg(true)
        setMsgErrorDocumentsLeg(msg)
      }
      return false
    }
    return true
  }

  const cleanMsg = () => {
    setShowErrorSameDocuments(false)
    setShowErrorDocumentsBill(false)
    setShowErrorDocumentsSer(false)
    setShowErrorDocumentsLeg(false)
  }

  const onSubmit = (formData) => {

    cleanMsg()
    console.log("datos " + formData)

    const documentNumberBill = getValues('documentNumberBilling')
    const documentTypeBill = getValues('documentTypeIdBilling')
    const documentNumberService = getValues('documentNumberService')
    const documentTypeService = getValues('documentTypeIdService')
    const documentNumberLeg = getValues('legalDocumentNumberService')
    const documentTypeLeg = getValues('legalDocumentTypeIdService')

    if (validateDocumentNumber(documentTypeBill, documentNumberBill, 'Bill')
      && validateDocumentNumber(documentTypeService, documentNumberService, 'Ser')
      && validateDocumentNumber(documentTypeLeg, documentNumberLeg, 'Leg')
    ) {
      const documentNumberCustomer = serviceInfo.documentNumber;
      if (getValues('personTypeService') === 'J' && getValues('legalDocumentNumberService') === documentNumberService) {
        setShowErrorSameDocuments(true)
      } else {
        if (documentNumberCustomer && documentNumberCustomer !== documentNumberService) {
          setOpen(true);
          setFormData(formData);
        } else if (paymentMethod === 'EN_LINEA' || paymentMethod === 'SIN_PAGO') {
          savePurchase(formData, paymentMethod, appName, history);
        } else {
          setOpenToCashPayment(true);
        }
      }
    }
  };

  const handleClickOk = () => {
    if (paymentMethod === 'EN_LINEA' || paymentMethod === 'SIN_PAGO') {
      savePurchase(dataForm, paymentMethod, appName);
    } else {
      setOpenToCashPayment(true);
    }

    setOpen(false);
  };

  const handleClickCancel = () => {
    setOpen(false);
  };

  const handleClickSendByEmail = () => {
    savePurchase(dataForm, paymentMethod, appName);
  };

  const handleClickCancelCashPayment = () => {
    setOpenToCashPayment(false);
  };

  const handleChangeStateCustomerBilling = (obj) => {
    const [event] = obj;
    const newCities = statesAndCities.statesList.find((d) => d.id === event.target.value);
    if (newCities) setCitiesBilling(newCities.city);
    return event.target.value;
  };

  const handleChangeStateCustomerService = (obj) => {
    const [event] = obj;
    const newCities = statesAndCities.statesList.find((d) => d.id === event.target.value);
    if (newCities) setCitiesService(newCities.city);
    return event.target.value;
  };

  // if (!cart.length) return history.push('/');

  if (loading) return <Loading />;

  return (
    <>
      <Container component="main" maxWidth="lg">
        <Paper className={classes.root} elevation={5}>

          <form
            className={classes.form}
            noValidate
            onSubmit={handleSubmit(onSubmit)}
          >
            <Typography align="left" color="primary" variant="h5">Proceso de facturación y envió</Typography>
            {
              subtotal(cart)
                ? (
                  <ExpansionPanel defaultExpanded>
                    <ExpansionPanelSummary
                      expandIcon={<ExpandMoreIcon color="secondary" />}
                      classes={{ expanded: classes.expanded }}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <Typography color="secondary" variant="h6">Datos de facturación</Typography>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails className={classes.container}>
                      <Grid container spacing={2}>
                        <Grid item xs={12}>
                          <FormControlLabel
                            control={(
                              <Switch
                                checked={check}
                                onChange={(e) => getUserInfo(e)}
                                name="checkedUseCustomerBilling"
                                color="primary"
                                disabled={!userInfo}
                              />
                            )}
                            label="Usar datos facturación"
                          />
                        </Grid>
                        <Grid item xs={12} sm={12} md={4} lg={4}>
                          <Controller
                            as={(
                              <TextField
                                select
                                label="Tipo de persona"
                                size="small"
                                variant="outlined"
                                required
                                fullWidth
                                style={{ marginRight: 10, color: 'red' }}
                                FormHelperTextProps={{ style: { marginRight: 10 } }}
                                helperText={errors.personTypeBilling ? errors.personTypeBilling.message : ''}
                              >
                                <MenuItem value="">Seleccione</MenuItem>
                                {personType.map(
                                  (td) => (
                                    <MenuItem
                                      key={td.value}
                                      value={td.value}
                                    >
                                      {td.label}
                                    </MenuItem>
                                  ),
                                )}
                              </TextField>
                            )}
                            name="personTypeBilling"
                            control={control}
                            rules={Rules.required}
                            error={Boolean(errors.personTypeBilling)}
                            onChange={(e) => setPersonType(e)}
                            defaultValue=""
                          />
                        </Grid>
                        <Grid item xs={12} sm={12} md={4} lg={4}>
                          <Controller
                            as={(
                              <TextField
                                select
                                label="Tipo de documento"
                                size="small"
                                variant="outlined"
                                required
                                fullWidth
                                style={{ marginRight: 10, color: 'red' }}
                                FormHelperTextProps={{ style: { marginRight: 10 } }}
                                helperText={errors.documentTypeIdBilling ? errors.documentTypeIdBilling.message : ''}
                              >
                                <MenuItem value="">Seleccione</MenuItem>
                                {
                                  documentTypes && documentTypes.map(
                                    (td) => (
                                      <MenuItem
                                        key={td.value}
                                        value={td.value}
                                      >
                                        {td.text}
                                      </MenuItem>
                                    ),
                                  )
                                }
                              </TextField>
                            )}
                            name="documentTypeIdBilling"
                            control={control}
                            rules={Rules.required}
                            //onChange={(e) => setDocumentValidationOnChange(e)}
                            error={Boolean(errors.documentTypeIdBilling)}
                            defaultValue=""
                          />
                        </Grid>
                        <Grid item xs={12} sm={12} md={4} lg={4}>
                          <Controller
                            as={(
                              <TextField
                                label="Número de documento"
                                size="small"
                                variant="outlined"
                                required
                                fullWidth
                                style={{ marginRight: 10, color: 'red' }}
                                FormHelperTextProps={{ style: { marginRight: 10 } }}
                                helperText={errors.documentNumberBilling ? errors.documentNumberBilling.message : ''}
                              />
                            )}
                            name="documentNumberBilling"
                            control={control}
                            rules={Rules.required}
                            error={Boolean(errors.documentNumberBilling)}
                            defaultValue=""
                            inputProps={{
                              maxLength: 15,
                            }}
                          />
                          {
                            showErrorDocumentsBill
                              ? (
                                <InputLabel shrink className={classes.errorTextDocument}>
                                  Formato inválido ({msgErrorDocumentsBill})
                                </InputLabel>
                              ) : (
                                ''
                              )
                          }
                        </Grid>
                        {
                          (billingInfo.personType === 'J' || personTypeBilling === 'J')
                            ? (
                              <Grid item xs={12}>
                                <Controller
                                  as={(
                                    <TextField
                                      label="Razón Social"
                                      size="small"
                                      variant="outlined"
                                      required
                                      fullWidth
                                      style={{ marginRight: 10, color: 'red' }}
                                      FormHelperTextProps={{ style: { marginRight: 10 } }}
                                      helperText={errors.legalNameBilling ? errors.legalNameBilling.message : ''}
                                    />
                                  )}
                                  name="legalNameBilling"
                                  control={control}
                                  rules={Rules.required}
                                  error={Boolean(errors.legalNameBilling)}
                                  defaultValue=""
                                  inputProps={{
                                    maxLength: 200,
                                  }}
                                />
                              </Grid>
                            )
                            : (
                              <>
                                <Grid item xs={12} sm={12} md={6} lg={6}>
                                  <Controller
                                    as={(
                                      <TextField
                                        label="Nombres"
                                        size="small"
                                        variant="outlined"
                                        required
                                        fullWidth
                                        style={{ marginRight: 10, color: 'red' }}
                                        FormHelperTextProps={{ style: { marginRight: 10 } }}
                                        helperText={errors.firstNameBilling ? errors.firstNameBilling.message : ''}
                                      />
                                    )}
                                    name="firstNameBilling"
                                    control={control}
                                    rules={Rules.required}
                                    error={Boolean(errors.firstNameBilling)}
                                    defaultValue=""
                                    inputProps={{
                                      maxLength: 150,
                                    }}
                                  />
                                </Grid>
                                <Grid item xs={12} sm={12} md={6} lg={6}>
                                  <Controller
                                    as={(
                                      <TextField
                                        label="Apellidos"
                                        size="small"
                                        variant="outlined"
                                        required
                                        fullWidth
                                        style={{ marginRight: 10, color: 'red' }}
                                        FormHelperTextProps={{ style: { marginRight: 10 } }}
                                        helperText={errors.lastNameBilling ? errors.lastNameBilling.message : ''}
                                      />
                                    )}
                                    name="lastNameBilling"
                                    control={control}
                                    rules={Rules.required}
                                    error={Boolean(errors.lastNameBilling)}
                                    defaultValue=""
                                    inputProps={{
                                      maxLength: 150,
                                    }}
                                  />
                                </Grid>
                              </>
                            )
                        }
                        <Grid item xs={12} sm={12} md={6} lg={6}>
                          <Controller
                            as={(
                              <TextField
                                label="Celular"
                                size="small"
                                variant="outlined"
                                required
                                fullWidth
                                style={{ marginRight: 10, color: 'red' }}
                                FormHelperTextProps={{ style: { marginRight: 10 } }}
                                helperText={errors.cellphoneNumberBilling ? errors.cellphoneNumberBilling.message : ''}
                              />
                            )}
                            name="cellphoneNumberBilling"
                            control={control}
                            rules={Rules.required, Rules.numerico}
                            error={Boolean(errors.cellphoneNumberBilling)}
                            defaultValue=""
                            inputProps={{
                              maxLength: 15,
                            }}
                          />
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={6}>
                          <Controller
                            as={(
                              <TextField
                                label="Correo Electronico"
                                size="small"
                                variant="outlined"
                                required
                                fullWidth
                                style={{ marginRight: 10, color: 'red' }}
                                FormHelperTextProps={{ style: { marginRight: 10 } }}
                                helperText={errors.emailBilling ? errors.emailBilling.message : ''}
                              />
                            )}
                            name="emailBilling"
                            control={control}
                            rules={Rules.email}
                            error={Boolean(errors.emailBilling)}
                            defaultValue=""
                            inputProps={{ maxLength: 100 }}
                          />
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={6}>
                          <Controller
                            as={(
                              <TextField
                                select
                                label="Departamento Residencia"
                                size="small"
                                variant="outlined"
                                required
                                fullWidth
                                style={{ marginRight: 10, color: 'red' }}
                                FormHelperTextProps={{ style: { marginRight: 10 } }}
                                helperText={errors.departmentIdBilling ? errors.departmentIdBilling.message : ''}
                              >
                                <MenuItem value="">Seleccione</MenuItem>
                                {statesAndCities && statesAndCities.itemsStatesList.map(
                                  (td) => (
                                    <MenuItem
                                      key={td.value}
                                      value={td.value}
                                    >
                                      {td.text}
                                    </MenuItem>
                                  ),
                                )}
                              </TextField>
                            )}
                            name="departmentIdBilling"
                            control={control}
                            rules={Rules.required}
                            error={Boolean(errors.departmentIdBilling)}
                            onChange={(e) => handleChangeStateCustomerBilling(e)}
                            defaultValue=""
                          />
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={6}>
                          <Controller
                            as={(
                              <TextField
                                select
                                label="Municipio Residencia"
                                size="small"
                                variant="outlined"
                                required
                                fullWidth
                                style={{ marginRight: 10, color: 'red' }}
                                FormHelperTextProps={{ style: { marginRight: 10 } }}
                                helperText={errors.cityIdBilling ? errors.cityIdBilling.message : ''}
                              >
                                <MenuItem value="">Seleccione</MenuItem>
                                {citiesBilling.map(
                                  (td) => (
                                    <MenuItem
                                      key={td.id}
                                      value={td.id}
                                    >
                                      {td.cityName}
                                    </MenuItem>
                                  ),
                                )}
                              </TextField>
                            )}
                            name="cityIdBilling"
                            control={control}
                            rules={Rules.required}
                            error={Boolean(errors.cityIdBilling)}
                            defaultValue=""
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <h3>Dirección de facturación</h3>
                          <p>Asistente para el ingreso de dirección</p>
                        </Grid>
                        <Grid item xs={4} sm={4} md={3} lg={3}>
                          <Controller
                            as={(
                              <TextField
                                select
                                label=""
                                size="small"
                                variant="outlined"
                                required
                                fullWidth
                                style={{ marginRight: 10, color: 'red' }}
                                FormHelperTextProps={{ style: { marginRight: 10 } }}
                                helperText={errors.addressField1Billing ? errors.addressField1Billing.message : ''}
                              >
                                <MenuItem value="">Seleccione</MenuItem>
                                {assistantAddress && assistantAddress.itemsAddress.map(
                                  (td) => (
                                    <MenuItem
                                      key={td.value}
                                      value={td.value}
                                    >
                                      {td.text}
                                    </MenuItem>
                                  ),
                                )}
                              </TextField>
                            )}
                            name="addressField1Billing"
                            control={control}
                            rules={Rules.required}
                            error={Boolean(errors.addressField1Billing)}
                            onChange={(e) => setFullAddressBilling(e)}
                            defaultValue=""
                          />
                        </Grid>
                        <Grid item xs={4} sm={4} md={2} lg={2}>
                          <Controller
                            as={(
                              <TextField
                                label=""
                                size="small"
                                variant="outlined"
                                required
                                placeholder="Digite un valor"
                                fullWidth
                                style={{ marginRight: 10, color: 'red' }}
                                FormHelperTextProps={{ style: { marginRight: 10 } }}
                                helperText={errors.addressField2Billing ? errors.addressField2Billing.message : ''}
                              />
                            )}
                            name="addressField2Billing"
                            control={control}
                            type="number"
                            rules={Rules.required}
                            error={Boolean(errors.addressField2Billing)}
                            onChange={(e) => setFullAddressBilling(e)}
                            defaultValue=""
                          />
                        </Grid>
                        <Grid item xs={4} sm={4} md={2} lg={2}>
                          <Controller
                            as={(
                              <TextField
                                select
                                label=""
                                size="small"
                                variant="outlined"
                                fullWidth
                              >
                                <MenuItem value="">Seleccione</MenuItem>
                                {assistantAddress && assistantAddress.itemsAddressLetters.map(
                                  (td) => (
                                    <MenuItem
                                      key={td.value}
                                      value={td.value}
                                    >
                                      {td.text}
                                    </MenuItem>
                                  ),
                                )}
                              </TextField>
                            )}
                            name="addressField3Billing"
                            control={control}
                            onChange={(e) => setFullAddressBilling(e)}
                            defaultValue=""
                          />
                        </Grid>
                        <Grid item xs={4} sm={4} md={2} lg={2}>
                          <Controller
                            as={(
                              <TextField
                                select
                                label=""
                                size="small"
                                variant="outlined"
                                fullWidth
                              >
                                <MenuItem value="">Seleccione</MenuItem>
                                {assistantAddress && assistantAddress.itemsAddressBis.map(
                                  (td) => (
                                    <MenuItem
                                      key={td.value}
                                      value={td.value}
                                    >
                                      {td.text}
                                    </MenuItem>
                                  ),
                                )}
                              </TextField>
                            )}
                            name="addressField4Billing"
                            control={control}
                            onChange={(e) => setFullAddressBilling(e)}
                            defaultValue=""
                          />
                        </Grid>
                        <Grid item xs={4} sm={4} md={3} lg={3}>
                          <Controller
                            as={(
                              <TextField
                                select
                                label=""
                                size="small"
                                variant="outlined"
                                fullWidth
                                style={{ marginRight: 10, color: 'red' }}
                                FormHelperTextProps={{ style: { marginRight: 10 } }}
                                helperText={errors.addressField5Billing ? errors.addressField5Billing.message : ''}
                              >
                                <MenuItem value="">Seleccione</MenuItem>
                                {assistantAddress && assistantAddress.itemsAddressLetters.map(
                                  (td) => (
                                    <MenuItem
                                      key={td.value}
                                      value={td.value}
                                    >
                                      {td.text}
                                    </MenuItem>
                                  ),
                                )}
                              </TextField>
                            )}
                            name="addressField5Billing"
                            control={control}
                            onChange={(e) => setFullAddressBilling(e)}
                            defaultValue=""
                          />
                        </Grid>
                        <Grid item xs={4} sm={4} md={3} lg={3}>
                          <Controller
                            as={(
                              <TextField
                                select
                                label=""
                                size="small"
                                variant="outlined"
                                fullWidth
                              >
                                <MenuItem value="">Seleccione</MenuItem>
                                {assistantAddress && assistantAddress.itemsAddressCardinal.map(
                                  (td) => (
                                    <MenuItem
                                      key={td.value}
                                      value={td.value}
                                    >
                                      {td.text}
                                    </MenuItem>
                                  ),
                                )}
                              </TextField>
                            )}
                            name="addressField6Billing"
                            control={control}
                            onChange={(e) => setFullAddressBilling(e)}
                            defaultValue=""
                          />
                        </Grid>
                        <Grid item xs={4} sm={4} md={2} lg={2}>
                          <Controller
                            as={(
                              <TextField
                                label=""
                                size="small"
                                variant="outlined"
                                required
                                placeholder="Digite un valor"
                                fullWidth
                                style={{ marginRight: 10, color: 'red' }}
                                FormHelperTextProps={{ style: { marginRight: 10 } }}
                                helperText={errors.addressField7Billing ? errors.addressField7Billing.message : ''}
                              />
                            )}
                            name="addressField7Billing"
                            control={control}
                            type="number"
                            rules={Rules.required}
                            error={Boolean(errors.addressField7Billing)}
                            onChange={(e) => setFullAddressBilling(e)}
                            defaultValue=""
                          />
                        </Grid>
                        <Grid item xs={4} sm={4} md={2} lg={2}>
                          <Controller
                            as={(
                              <TextField
                                select
                                label=""
                                size="small"
                                variant="outlined"
                                fullWidth
                              >
                                <MenuItem value="">Seleccione</MenuItem>
                                {assistantAddress && assistantAddress.itemsAddressLetters.map(
                                  (td) => (
                                    <MenuItem
                                      key={td.value}
                                      value={td.value}
                                    >
                                      {td.text}
                                    </MenuItem>
                                  ),
                                )}
                              </TextField>
                            )}
                            name="addressField8Billing"
                            control={control}
                            onChange={(e) => setFullAddressBilling(e)}
                            defaultValue=""
                          />
                        </Grid>
                        <Grid item xs={4} sm={4} md={2} lg={2}>
                          <Controller
                            as={(
                              <TextField
                                label=""
                                size="small"
                                variant="outlined"
                                required
                                placeholder="Digite un valor"
                                fullWidth
                                style={{ marginRight: 10, color: 'red' }}
                                FormHelperTextProps={{ style: { marginRight: 10 } }}
                                helperText={errors.addressField9Billing ? errors.addressField9Billing.message : ''}
                              />
                            )}
                            name="addressField9Billing"
                            control={control}
                            type="number"
                            rules={Rules.required}
                            error={Boolean(errors.addressField9Billing)}
                            onChange={(e) => setFullAddressBilling(e)}
                            defaultValue=""
                          />
                        </Grid>
                        <Grid item xs={4} sm={4} md={3} lg={3}>
                          <Controller
                            as={(
                              <TextField
                                select
                                label=""
                                size="small"
                                variant="outlined"
                                fullWidth
                              >
                                <MenuItem value="">Seleccione</MenuItem>
                                {assistantAddress && assistantAddress.itemsAddressCardinal.map(
                                  (td) => (
                                    <MenuItem
                                      key={td.value}
                                      value={td.value}
                                    >
                                      {td.text}
                                    </MenuItem>
                                  ),
                                )}
                              </TextField>
                            )}
                            name="addressField10Billing"
                            control={control}
                            onChange={(e) => setFullAddressBilling(e)}
                            defaultValue=""
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <h3>Complemento</h3>
                        </Grid>
                        <Grid item xs={3}>
                          <Controller
                            as={(
                              <TextField
                                select
                                label=""
                                size="small"
                                variant="outlined"
                                fullWidth
                              >
                                <MenuItem value="">Seleccione</MenuItem>
                                {assistantAddress && assistantAddress.itemsAddress.map(
                                  (td) => (
                                    <MenuItem
                                      key={td.value}
                                      value={td.value}
                                    >
                                      {td.text}
                                    </MenuItem>
                                  ),
                                )}
                              </TextField>
                            )}
                            name="addressFieldComplementary1Billing"
                            control={control}
                            onChange={(e) => setFullAddressBilling(e)}
                            defaultValue=""
                          />
                        </Grid>
                        <Grid item xs={3}>
                          <Controller
                            as={(
                              <TextField
                                label=""
                                size="small"
                                variant="outlined"
                                placeholder="Digite un valor"
                                fullWidth
                              />
                            )}
                            name="addressFieldComplementary2Billing"
                            control={control}
                            onChange={(e) => setFullAddressBilling(e)}
                            defaultValue=""
                          />
                        </Grid>
                        <Grid item xs={3}>
                          <Controller
                            as={(
                              <TextField
                                select
                                label=""
                                size="small"
                                variant="outlined"
                                fullWidth
                              >
                                <MenuItem value="">Seleccione</MenuItem>
                                {assistantAddress && assistantAddress.itemsAddress.map(
                                  (td) => (
                                    <MenuItem
                                      key={td.value}
                                      value={td.value}
                                    >
                                      {td.text}
                                    </MenuItem>
                                  ),
                                )}
                              </TextField>
                            )}
                            name="addressFieldComplementary3Billing"
                            control={control}
                            onChange={(e) => setFullAddressBilling(e)}
                            defaultValue=""
                          />
                        </Grid>
                        <Grid item xs={3}>
                          <Controller
                            as={(
                              <TextField
                                label=""
                                size="small"
                                variant="outlined"
                                placeholder="Digite un valor"
                                fullWidth
                              />
                            )}
                            name="addressFieldComplementary4Billing"
                            control={control}
                            onChange={(e) => setFullAddressBilling(e)}
                            defaultValue=""
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <h3>Dirección completa</h3>
                        </Grid>
                        <Grid item xs={12}>
                          <Controller
                            as={(
                              <TextField
                                label=""
                                size="small"
                                variant="outlined"
                                placeholder="Dirección completa"
                                //disabled
                                fullWidth
                              />
                            )}
                            name="fulladdressBilling"
                            control={control}
                            defaultValue=""
                            inputProps={{
                              maxLength: 150,
                            }}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <Controller
                            as={(
                              <TextField
                                label=""
                                size="small"
                                variant="outlined"
                                multiline
                                rows={4}
                                placeholder="Información adicional"
                                fullWidth
                              />
                            )}
                            name="additionalInfoBilling"
                            control={control}
                            defaultValue=""
                          />
                        </Grid>

                      </Grid>
                    </ExpansionPanelDetails>
                  </ExpansionPanel>
                ) : (' ')}
            <ExpansionPanel defaultExpanded={subtotal(cart) ? false : true}>
              <ExpansionPanelSummary
                expandIcon={<ExpandMoreIcon color="secondary" />}
                classes={{ expanded: classes.expanded }}
                aria-controls="panel2a-content"
                id="panel2a-header"
              >
                <Typography color="secondary" variant="h6">Datos de servicio y envió</Typography>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails className={classes.container}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <FormControlLabel
                      control={(
                        <Switch
                          checked={checkService}
                          onChange={(e) => getUserInfo(e)}
                          name="checkedUseCustomerService"
                          color="primary"
                          disabled={!userInfo}
                        />
                      )}
                      label="Usar datos servicio"
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={4} lg={4}>
                    <InputLabel />
                    <Controller
                      as={(
                        <TextField
                          select
                          label="Tipo de persona"
                          size="small"
                          variant="outlined"
                          required
                          fullWidth
                          style={{ marginRight: 10, color: 'red' }}
                          FormHelperTextProps={{ style: { marginRight: 10 } }}
                          helperText={errors.personTypeService ? errors.personTypeService.message : ''}
                        >
                          <MenuItem value="">Seleccione</MenuItem>
                          {personType && personType.map(
                            (td) => (
                              <MenuItem
                                key={td.value}
                                value={td.value}
                              >
                                {td.label}
                              </MenuItem>
                            ),
                          )}
                        </TextField>
                      )}
                      name="personTypeService"
                      control={control}
                      rules={Rules.required}
                      error={Boolean(errors.personTypeService)}
                      onChange={(e) => setPersonType(e)}
                      defaultValue=""
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={4} lg={4} className={{ marginTop: "5px" }}>
                    <InputLabel />
                    <Controller
                      as={(
                        <TextField
                          select
                          label="Tipo de documento"
                          size="small"
                          variant="outlined"
                          required
                          fullWidth
                          style={{ marginRight: 10, color: 'red' }}
                          FormHelperTextProps={{ style: { marginRight: 10 } }}
                          helperText={errors.documentTypeIdService ? errors.documentTypeIdService.message : ''}
                        >
                          <MenuItem value="">Seleccione</MenuItem>
                          {documentTypes && (appName === 'colfactura' ? (serviceInfo.personType === 'J' || personTypeService === 'J') ?
                            documentTypes.filter(doc => doc.value === 4) :
                            documentTypes.filter(doc => doc.value === 1 || doc.value === 2 || doc.value === 6)
                            : documentTypes).map(
                              (td) => (
                                <MenuItem
                                  key={td.value}
                                  value={td.value}
                                >
                                  {td.text}
                                </MenuItem>
                              ),
                            )}
                        </TextField>
                      )}
                      name="documentTypeIdService"
                      control={control}
                      rules={Rules.required}
                      error={Boolean(errors.documentTypeIdService)}
                      onChange={(e) => setDocumentType(e)}
                      defaultValue=""
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={4} lg={4}>
                    {
                      documentTypeIdService === 4 // Nit
                        ? (
                          <InputLabel shrink className={classes.infoText}>
                            Ingrese NIT sin digito de verificación
                          </InputLabel>
                        ) : (
                          ''
                        )
                    }
                    <Controller
                      as={(
                        <TextField
                          label="Número de documento"
                          size="small"
                          variant="outlined"
                          required
                          fullWidth
                          style={{ marginRight: 10, color: 'red' }}
                          FormHelperTextProps={{ style: { marginRight: 10 } }}
                          helperText={errors.documentNumberService ? errors.documentNumberService.message : ''}
                        />
                      )}
                      name="documentNumberService"
                      control={control}
                      rules={Rules.required}
                      error={Boolean(errors.documentNumberService)}
                      defaultValue=""
                      inputProps={{
                        maxLength: 15,
                      }}
                    />
                    {
                      showErrorDocumentsSer
                        ? (
                          <InputLabel shrink className={classes.errorTextDocument}>
                            Formato inválido ({msgErrorDocumentsSer})
                          </InputLabel>
                        ) : (
                          ''
                        )
                    }
                  </Grid>
                  {
                    (serviceInfo.personType === 'J' || personTypeService === 'J')
                      ? (
                        <Grid item xs={12}>
                          <Controller
                            as={(
                              <TextField
                                label="Razón Social"
                                size="small"
                                variant="outlined"
                                required
                                fullWidth
                                style={{ marginRight: 10, color: 'red' }}
                                FormHelperTextProps={{ style: { marginRight: 10 } }}
                                helperText={errors.legalNameService ? errors.legalNameService.message : ''}
                              />
                            )}
                            name="legalNameService"
                            control={control}
                            rules={Rules.required}
                            error={Boolean(errors.legalNameService)}
                            defaultValue=""
                            inputProps={{
                              maxLength: 200,
                            }}
                          />
                        </Grid>
                      )
                      : (
                        <>
                          <Grid item xs={12} sm={12} md={6} lg={6}>
                            <Controller
                              as={(
                                <TextField
                                  label="Nombres"
                                  size="small"
                                  variant="outlined"
                                  required
                                  fullWidth
                                  style={{ marginRight: 10, color: 'red' }}
                                  FormHelperTextProps={{ style: { marginRight: 10 } }}
                                  helperText={errors.firstNameService ? errors.firstNameService.message : ''}
                                />
                              )}
                              name="firstNameService"
                              control={control}
                              rules={Rules.required}
                              error={Boolean(errors.firstNameService)}
                              defaultValue=""
                              inputProps={{
                                maxLength: 150,
                              }}
                            />
                          </Grid>
                          <Grid item xs={12} sm={12} md={6} lg={6}>
                            <Controller
                              as={(
                                <TextField
                                  label="Apellidos"
                                  size="small"
                                  variant="outlined"
                                  required
                                  fullWidth
                                  style={{ marginRight: 10, color: 'red' }}
                                  FormHelperTextProps={{ style: { marginRight: 10 } }}
                                  helperText={errors.lastNameService ? errors.lastNameService.message : ''}
                                />
                              )}
                              name="lastNameService"
                              control={control}
                              rules={Rules.required}
                              error={Boolean(errors.lastNameService)}
                              defaultValue=""
                              inputProps={{
                                maxLength: 150,
                              }}
                            />
                          </Grid>
                        </>
                      )
                  }
                  <Grid item xs={12} sm={12} md={6} lg={6}>
                    <Controller
                      as={(
                        <TextField
                          label="Celular"
                          size="small"
                          variant="outlined"
                          required
                          fullWidth
                          style={{ marginRight: 10, color: 'red' }}
                          FormHelperTextProps={{ style: { marginRight: 10 } }}
                          helperText={errors.cellphoneNumberService ? errors.cellphoneNumberService.message : ''}
                        />
                      )}
                      name="cellphoneNumberService"
                      control={control}
                      rules={Rules.required, Rules.numerico}
                      error={Boolean(errors.cellphoneNumberService)}
                      defaultValue=""
                      inputProps={{
                        maxLength: 15,
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} lg={6}>
                    <Controller
                      as={(
                        <TextField
                          label="Correo Electronico"
                          size="small"
                          variant="outlined"
                          required
                          fullWidth
                          style={{ marginRight: 10, color: 'red' }}
                          FormHelperTextProps={{ style: { marginRight: 10 } }}
                          helperText={errors.emailService ? errors.emailService.message : ''}
                        />
                      )}
                      name="emailService"
                      control={control}
                      rules={Rules.email}
                      error={Boolean(errors.emailService)}
                      defaultValue=""
                      inputProps={{ maxLength: 100 }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} lg={6}>
                    <Controller
                      as={(
                        <TextField
                          select
                          label="Departamento Residencia"
                          size="small"
                          variant="outlined"
                          required
                          fullWidth
                          style={{ marginRight: 10, color: 'red' }}
                          FormHelperTextProps={{ style: { marginRight: 10 } }}
                          helperText={errors.departmentIdService ? errors.departmentIdService.message : ''}
                        >
                          <MenuItem value="">Seleccione</MenuItem>
                          {statesAndCities && statesAndCities.itemsStatesList.map(
                            (td) => (
                              <MenuItem
                                key={td.value}
                                value={td.value}
                              >
                                {td.text}
                              </MenuItem>
                            ),
                          )}
                        </TextField>
                      )}
                      name="departmentIdService"
                      control={control}
                      rules={Rules.required}
                      error={Boolean(errors.departmentIdService)}
                      onChange={(e) => handleChangeStateCustomerService(e)}
                      defaultValue=""
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} lg={6}>
                    <Controller
                      as={(
                        <TextField
                          select
                          label="Municipio Residencia"
                          size="small"
                          variant="outlined"
                          required
                          fullWidth
                          style={{ marginRight: 10, color: 'red' }}
                          FormHelperTextProps={{ style: { marginRight: 10 } }}
                          helperText={errors.cityIdService ? errors.cityIdService.message : ''}
                        >
                          <MenuItem value="">Seleccione</MenuItem>
                          {citiesService && citiesService.map(
                            (td) => (
                              <MenuItem
                                key={td.id}
                                value={td.id}
                              >
                                {td.cityName}
                              </MenuItem>
                            ),
                          )}
                        </TextField>
                      )}
                      name="cityIdService"
                      control={control}
                      rules={Rules.required}
                      error={Boolean(errors.cityIdService)}
                      defaultValue=""
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <h3>Dirección de envió</h3>
                    <p>Asistente para el ingreso de dirección</p>
                  </Grid>
                  <Grid item xs={4} sm={4} md={3} lg={3}>
                    <Controller
                      as={(
                        <TextField
                          select
                          label=""
                          size="small"
                          variant="outlined"
                          required
                          fullWidth
                          style={{ marginRight: 10, color: 'red' }}
                          FormHelperTextProps={{ style: { marginRight: 10 } }}
                          helperText={errors.addressField1Service ? errors.addressField1Service.message : ''}
                        >
                          <MenuItem value="">Seleccione</MenuItem>
                          {assistantAddress && assistantAddress.itemsAddress.map(
                            (td) => (
                              <MenuItem
                                key={td.value}
                                value={td.value}
                              >
                                {td.text}
                              </MenuItem>
                            ),
                          )}
                        </TextField>
                      )}
                      name="addressField1Service"
                      control={control}
                      rules={Rules.required}
                      error={Boolean(errors.addressField1Service)}
                      onChange={(e) => setFullAddressService(e)}
                      defaultValue=""
                    />
                  </Grid>
                  <Grid item xs={4} sm={4} md={2} lg={2}>
                    <Controller
                      as={(
                        <TextField
                          label=""
                          size="small"
                          variant="outlined"
                          required
                          placeholder="Digite un valor"
                          fullWidth
                          style={{ marginRight: 10, color: 'red' }}
                          FormHelperTextProps={{ style: { marginRight: 10 } }}
                          helperText={errors.addressField2Service ? errors.addressField2Service.message : ''}
                        />
                      )}
                      name="addressField2Service"
                      control={control}
                      type="number"
                      rules={Rules.required}
                      error={Boolean(errors.addressField2Service)}
                      onChange={(e) => setFullAddressService(e)}
                      defaultValue=""
                    />
                  </Grid>
                  <Grid item xs={4} sm={4} md={2} lg={2}>
                    <Controller
                      as={(
                        <TextField
                          select
                          label=""
                          size="small"
                          variant="outlined"
                          fullWidth
                        >
                          <MenuItem value="">Seleccione</MenuItem>
                          {assistantAddress && assistantAddress.itemsAddressLetters.map(
                            (td) => (
                              <MenuItem
                                key={td.value}
                                value={td.value}
                              >
                                {td.text}
                              </MenuItem>
                            ),
                          )}
                        </TextField>
                      )}
                      name="addressField3Service"
                      control={control}
                      onChange={(e) => setFullAddressService(e)}
                      defaultValue=""
                    />
                  </Grid>
                  <Grid item xs={4} sm={4} md={2} lg={2}>
                    <Controller
                      as={(
                        <TextField
                          select
                          label=""
                          size="small"
                          variant="outlined"
                          fullWidth
                        >
                          <MenuItem value="">Seleccione</MenuItem>
                          {assistantAddress && assistantAddress.itemsAddressBis.map(
                            (td) => (
                              <MenuItem
                                key={td.value}
                                value={td.value}
                              >
                                {td.text}
                              </MenuItem>
                            ),
                          )}
                        </TextField>
                      )}
                      name="addressField4Service"
                      control={control}
                      onChange={(e) => setFullAddressService(e)}
                      defaultValue=""
                    />
                  </Grid>
                  <Grid item xs={4} sm={4} md={3} lg={3}>
                    <Controller
                      as={(
                        <TextField
                          select
                          label=""
                          size="small"
                          variant="outlined"
                          fullWidth
                          style={{ marginRight: 10, color: 'red' }}
                          FormHelperTextProps={{ style: { marginRight: 10 } }}
                        >
                          <MenuItem value="">Seleccione</MenuItem>
                          {assistantAddress && assistantAddress.itemsAddressLetters.map(
                            (td) => (
                              <MenuItem
                                key={td.value}
                                value={td.value}
                              >
                                {td.text}
                              </MenuItem>
                            ),
                          )}
                        </TextField>
                      )}
                      name="addressField5Service"
                      control={control}
                      onChange={(e) => setFullAddressService(e)}
                      defaultValue=""
                    />
                  </Grid>
                  <Grid item xs={4} sm={4} md={3} lg={3}>
                    <Controller
                      as={(
                        <TextField
                          select
                          label=""
                          size="small"
                          variant="outlined"
                          fullWidth
                        >
                          <MenuItem value="">Seleccione</MenuItem>
                          {assistantAddress && assistantAddress.itemsAddressCardinal.map(
                            (td) => (
                              <MenuItem
                                key={td.value}
                                value={td.value}
                              >
                                {td.text}
                              </MenuItem>
                            ),
                          )}
                        </TextField>
                      )}
                      name="addressField6Service"
                      control={control}
                      onChange={(e) => setFullAddressService(e)}
                      defaultValue=""
                    />
                  </Grid>
                  <Grid item xs={4} sm={4} md={2} lg={2}>
                    <Controller
                      as={(
                        <TextField
                          label=""
                          size="small"
                          variant="outlined"
                          required
                          placeholder="Digite un valor"
                          fullWidth
                          style={{ marginRight: 10, color: 'red' }}
                          FormHelperTextProps={{ style: { marginRight: 10 } }}
                          helperText={errors.addressField7Service ? errors.addressField7Service.message : ''}
                        />
                      )}
                      name="addressField7Service"
                      control={control}
                      type="number"
                      rules={Rules.required}
                      error={Boolean(errors.addressField7Service)}
                      onChange={(e) => setFullAddressService(e)}
                      defaultValue=""
                    />
                  </Grid>
                  <Grid item xs={4} sm={4} md={2} lg={2}>
                    <Controller
                      as={(
                        <TextField
                          select
                          label=""
                          size="small"
                          variant="outlined"
                          fullWidth
                        >
                          <MenuItem value="">Seleccione</MenuItem>
                          {assistantAddress && assistantAddress.itemsAddressLetters.map(
                            (td) => (
                              <MenuItem
                                key={td.value}
                                value={td.value}
                              >
                                {td.text}
                              </MenuItem>
                            ),
                          )}
                        </TextField>
                      )}
                      name="addressField8Service"
                      control={control}
                      onChange={(e) => setFullAddressService(e)}
                      defaultValue=""
                    />
                  </Grid>
                  <Grid item xs={4} sm={4} md={2} lg={2}>
                    <Controller
                      as={(
                        <TextField
                          label=""
                          size="small"
                          variant="outlined"
                          required
                          placeholder="Digite un valor"
                          fullWidth
                          style={{ marginRight: 10, color: 'red' }}
                          FormHelperTextProps={{ style: { marginRight: 10 } }}
                          helperText={errors.addressField9Service ? errors.addressField9Service.message : ''}
                        />
                      )}
                      name="addressField9Service"
                      control={control}
                      type="number"
                      rules={Rules.required}
                      error={Boolean(errors.addressField9Service)}
                      onChange={(e) => setFullAddressService(e)}
                      defaultValue=""
                    />
                  </Grid>
                  <Grid item xs={4} sm={4} md={3} lg={3}>
                    <Controller
                      as={(
                        <TextField
                          select
                          label=""
                          size="small"
                          variant="outlined"
                          fullWidth
                        >
                          <MenuItem value="">Seleccione</MenuItem>
                          {assistantAddress && assistantAddress.itemsAddressCardinal.map(
                            (td) => (
                              <MenuItem
                                key={td.value}
                                value={td.value}
                              >
                                {td.text}
                              </MenuItem>
                            ),
                          )}
                        </TextField>
                      )}
                      name="addressField10Service"
                      control={control}
                      onChange={(e) => setFullAddressService(e)}
                      defaultValue=""
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <h3>Complemento</h3>
                  </Grid>
                  <Grid item xs={3}>
                    <Controller
                      as={(
                        <TextField
                          select
                          label=""
                          size="small"
                          variant="outlined"
                          fullWidth
                        >
                          <MenuItem value="">Seleccione</MenuItem>
                          {assistantAddress && assistantAddress.itemsAddress.map(
                            (td) => (
                              <MenuItem
                                key={td.value}
                                value={td.value}
                              >
                                {td.text}
                              </MenuItem>
                            ),
                          )}
                        </TextField>
                      )}
                      name="addressFieldComplementary1Service"
                      control={control}
                      onChange={(e) => setFullAddressService(e)}
                      defaultValue=""
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <Controller
                      as={(
                        <TextField
                          label=""
                          size="small"
                          variant="outlined"
                          placeholder="Digite un valor"
                          fullWidth
                        />
                      )}
                      name="addressFieldComplementary2Service"
                      control={control}
                      onChange={(e) => setFullAddressService(e)}
                      defaultValue=""
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <Controller
                      as={(
                        <TextField
                          select
                          label=""
                          size="small"
                          variant="outlined"
                          fullWidth
                        >
                          <MenuItem value="">Seleccione</MenuItem>
                          {assistantAddress && assistantAddress.itemsAddress.map(
                            (td) => (
                              <MenuItem
                                key={td.value}
                                value={td.value}
                              >
                                {td.text}
                              </MenuItem>
                            ),
                          )}
                        </TextField>
                      )}
                      name="addressFieldComplementary3Service"
                      control={control}
                      onChange={(e) => setFullAddressService(e)}
                      defaultValue=""
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <Controller
                      as={(
                        <TextField
                          label=""
                          size="small"
                          variant="outlined"
                          placeholder="Digite un valor"
                          fullWidth
                        />
                      )}
                      name="addressFieldComplementary4Service"
                      control={control}
                      onChange={(e) => setFullAddressService(e)}
                      defaultValue=""
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <h3>Dirección completa</h3>
                  </Grid>
                  <Grid item xs={12}>
                    <Controller
                      as={(
                        <TextField
                          label=""
                          size="small"
                          variant="outlined"
                          placeholder="Dirección completa"
                          disabled
                          fullWidth
                        />
                      )}
                      name="fulladdressService"
                      control={control}
                      defaultValue=""
                      inputProps={{
                        maxLength: 150,
                      }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Controller
                      as={(
                        <TextField
                          label=""
                          size="small"
                          variant="outlined"
                          multiline
                          rows={4}
                          placeholder="Información adicional"
                          fullWidth
                        />
                      )}
                      name="additionalInfoService"
                      control={control}
                      defaultValue=""
                    />
                  </Grid>
                  {
                    (serviceInfo.personType === 'J' || personTypeService === 'J')
                      ? (
                        <>
                          <Grid item xs={12}>
                            <h3>Datos representante legal</h3>
                          </Grid>
                          <Grid item xs={12} sm={12} md={6} lg={6}>
                            <Controller
                              as={(
                                <TextField
                                  select
                                  label="Tipo de documento Representante Legal"
                                  size="small"
                                  variant="outlined"
                                  required
                                  fullWidth
                                  style={{ marginRight: 10, color: 'red' }}
                                  FormHelperTextProps={{ style: { marginRight: 10 } }}
                                  helperText={errors.legalDocumentTypeIdService ? errors.legalDocumentTypeIdService.message : ''}
                                >
                                  <MenuItem value="">Seleccione</MenuItem>
                                  {documentTypes && documentTypes.filter(doc => doc.value === 1 || doc.value === 2 || doc.value === 6).map(
                                    (td) => (
                                      <MenuItem
                                        key={td.value}
                                        value={td.value}
                                      >
                                        {td.text}
                                      </MenuItem>
                                    ),
                                  )}
                                </TextField>
                              )}
                              name="legalDocumentTypeIdService"
                              control={control}
                              rules={Rules.required}
                              error={Boolean(errors.legalDocumentTypeIdService)}
                              defaultValue=""
                            />
                          </Grid>
                          <Grid item xs={12} sm={12} md={6} lg={6}>
                            {
                              showErrorDocumentsLeg
                                ? (
                                  <InputLabel shrink className={classes.errorText}>
                                    Formato inválido ({msgErrorDocumentsLeg})
                                  </InputLabel>
                                ) : (
                                  ''
                                )
                            }
                            {
                              showErrorSameDocuments
                                ? (
                                  <InputLabel shrink className={classes.errorText}>
                                    Nit y Número de Documento no deben ser iguales.
                                  </InputLabel>
                                ) : (
                                  ''
                                )
                            }
                            <Controller
                              as={(
                                <TextField
                                  label="Número de documento Representante Legal"
                                  size="small"
                                  variant="outlined"
                                  required
                                  fullWidth
                                  style={{ marginRight: 10, color: 'red' }}
                                  FormHelperTextProps={{ style: { marginRight: 10 } }}
                                  helperText={errors.legalDocumentNumberService ? errors.legalDocumentNumberService.message : ''}
                                />
                              )}
                              name="legalDocumentNumberService"
                              control={control}
                              rules={Rules.required}
                              error={Boolean(errors.legalDocumentNumberService)}
                              defaultValue=""
                              inputProps={{ maxLength: 15 }}
                            />
                          </Grid>
                          <Grid item xs={12} sm={12} md={6} lg={6}>
                            <Controller
                              as={(
                                <TextField
                                  label="Nombres Representante Legal"
                                  size="small"
                                  variant="outlined"
                                  required
                                  fullWidth
                                  style={{ marginRight: 10, color: 'red' }}
                                  FormHelperTextProps={{ style: { marginRight: 10 } }}
                                  helperText={errors.legalFirstNameService ? errors.legalFirstNameService.message : ''}
                                />
                              )}
                              name="legalFirstNameService"
                              control={control}
                              rules={Rules.required, Rules.letrasTildes}
                              error={Boolean(errors.legalFirstNameService)}
                              defaultValue=""
                              inputProps={{ maxLength: 150 }}
                            />
                          </Grid>
                          <Grid item xs={12} sm={12} md={6} lg={6}>
                            <Controller
                              as={(
                                <TextField
                                  label="Apellidos Representante Legal"
                                  size="small"
                                  variant="outlined"
                                  required
                                  fullWidth
                                  style={{ marginRight: 10, color: 'red' }}
                                  FormHelperTextProps={{ style: { marginRight: 10 } }}
                                  helperText={errors.legalLastNameService ? errors.legalLastNameService.message : ''}
                                />
                              )}
                              name="legalLastNameService"
                              control={control}
                              rules={Rules.required, Rules.letrasTildes}
                              error={Boolean(errors.legalLastNameService)}
                              defaultValue=""
                              inputProps={{ maxLength: 150 }}
                            />
                          </Grid>
                        </>
                      )
                      : (
                        <>
                        </>
                      )
                  }
                </Grid>
              </ExpansionPanelDetails>
            </ExpansionPanel>
            <Box className={classes.submit}>
              {
                subtotal(cart)
                  ? (
                    <>
                      <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        className={classes.btn}
                        startIcon={<CreditCardIcon />}
                        onClick={() => {
                          setPaymentMethod('EN_LINEA');
                        }}
                      >
                        Pago en Linea
                      </Button>
                      {/* <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        className={classes.btn}
                        startIcon={<MoneyIcon />}
                        disabled={true}
                        onClick={() => {
                          setPaymentMethod('EFECTIVO');
                        }}
                      >
                        Pago en Efectivo
                      </Button> */}
                    </>
                  ) : (
                    <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                      className={classes.btn}
                      startIcon={<MoneyIcon />}
                      onClick={() => {
                        setPaymentMethod('SIN_PAGO');
                      }}
                    >
                      Activar
                    </Button>
                  )
              }
            </Box>
          </form>
        </Paper>
        <div>
          <Dialog
            open={open}
            onClose={handleClickCancel}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title" />
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                Recuerde que si modifica estos datos la licencia quedara
                registrada con esta información y no con la del formulario de facturación
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClickCancel} variant="outlined" color="primary">
                Cancelar
              </Button>
              <Button onClick={handleClickOk} variant="outlined" color="primary" autoFocus>
                Aceptar
              </Button>
            </DialogActions>
          </Dialog>
        </div>
        <div>
          <Dialog
            open={open}
            onClose={handleClickCancel}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title" />
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                Recuerde que si modifica estos datos la licencia quedara
                registrada con esta información y no con la del formulario de facturación
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClickCancel} variant="outlined" color="primary">
                Cancelar
              </Button>
              <Button onClick={handleClickOk} variant="outlined" color="primary" autoFocus>
                Aceptar
              </Button>
            </DialogActions>
          </Dialog>
          <Dialog
            open={openCashPayment}
            onClose={handleClickCancelCashPayment}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title" />
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                Para pagar en efectivo imprima la orden de pedido
                y siga las instrucciones que allí se especifican
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClickSendByEmail} variant="outlined" color="primary" autoFocus>
                Recibir por correo
              </Button>
            </DialogActions>
          </Dialog>
        </div>
      </Container>
    </>
  );
};

const mapStateToProps = (props) => ({
  userInfo: props.auth.userInfo,
  loading: props.purchaseLicense.loading,
  loadingGeneral: props.general.loading,
  billingInfo: props.purchaseLicense.customerBillingInfo,
  serviceInfo: props.purchaseLicense.customerServiceInfo,
  assistantAddress: props.general.assistantAddress,
  documentTypes: props.general.documentTypes,
  statesAndCities: props.general.statesAndCities,
  cart: CleanCart(props.home.cart),
  appName: props.general.appName,
});

const mapDispatchToProps = {
  getPurchaseOrderParameters: getPurchaseOrderParametersAction,
  savePurchase: savePurchaseAction,
  getCustomerInformation: getCustomerInformationAction,
  setPersonTypeAction,
  setPersonTypeActionService
};

export default connect(mapStateToProps, mapDispatchToProps)(RegisterPurchaseOrder);
