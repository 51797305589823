/* eslint-disable no-param-reassign */
/* eslint-disable no-prototype-builtins */
export const CleanCart = (cart, promoCode) => {
  const cartClean = {};
  cart.forEach((element) => {
    if (cartClean.hasOwnProperty(element.packageId)) {
      cartClean[element.packageId] = {
        ...cartClean[element.packageId],
        number: cartClean[element.packageId].number + 1,
      };
    } else {
      cartClean[element.packageId] = {
        id: element.packageId,
        name: element.packageName,
        number: 1,
        price: element.packagePriceWithDiscount,
      };
    }
  });
  if (promoCode) {
    if (promoCode.applyTotalPurchase) {
      if (promoCode.offerTypeCode === 'PTJ') {
        Object.values(cartClean).forEach((element) => {
          element.price -= (element.price / 100) * promoCode.totalDiscount;
        });
      }
    }
    if (promoCode.applyProduct) {
      if (promoCode.offerTypeCode === 'PTJ') {
        Object.values(cartClean).forEach((element) => {
          const item = promoCode.packageApplyPromotion.find((p) => p.packageId === element.id);
          if (item) element.price -= ((element.price / 100) * promoCode.totalDiscount);
        });
      }
    }
  }
  return Object.values(cartClean);
};

export const formatter = new Intl.NumberFormat(undefined, {
  style: 'currency',
  currency: 'COP',
  minimumFractionDigits: 0,
  maximumFractionDigits: 0,
});
