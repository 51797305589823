import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import { format } from 'date-fns';
import LicenseCard from './LicenseCard';

const useStyles = makeStyles((theme) => ({
  root: {
    width: 280,
    borderRadius: 16,
    paddingBottom: 10,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  paper: {
    padding: '5px',
    marginTop: '20px',
    opacity: '0.95',
  },
  boxTitles: {
    marginLeft: 25,
  },
  title: {
    ...theme.miplan.title,
  },
  subtitle: {
    ...theme.miplan.subtitle,
  },
  caption: {
    color: '#818181',
  },
}));

const LicenseGroup = (props) => {
  const classes = useStyles();
  const { data } = props;
  return (
    <>
      <Container component="main" maxWidth="lg">
        <Paper elevation={3} className={classes.paper}>
          <Box className={classes.boxTitles}>
            <Typography className={classes.title} variant="h5">{data.serviceName}</Typography>
            <Typography className={classes.subtitle} variant="subtitle2">{data.packageName}</Typography>
            <Typography className={classes.caption} variant="caption text">
              Fecha vigencia:
              {format(new Date(data.expirationDate), 'dd/MM/yyyy') }
            </Typography>
          </Box>
          <Grid container style={{ padding: 20 }}>
            {data.packagesFeatures
              && data.packagesFeatures.map((feature, index) => (
                <Grid item xs={12} sm={4} md={3} lg={3}>
                  <LicenseCard
                    key={index}
                    feature={feature}
                    serviceName={data.serviceName}
                  />
                </Grid>
              ))}
          </Grid>
        </Paper>
      </Container>
    </>
  );
};

export default LicenseGroup;
