import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Switch from '@material-ui/core/Switch';


const useStyles = makeStyles((theme) => ({
  tableHead: {
    backgroundColor: theme.palette.primary.main,
  },
}));

const PromoTable = ({ info }) => {
  const classes = useStyles();
  return (
    <TableContainer>
      <Table>
        <TableHead className={classes.tableHead}>
          <TableRow>
            <TableCell>Nombre</TableCell>
            <TableCell align="right">Código promoción</TableCell>
            <TableCell align="right">Descripción</TableCell>
            <TableCell align="right">Código tipo descuento</TableCell>
            <TableCell align="right">Valor descuento</TableCell>
            <TableCell align="right">Total compra</TableCell>
            <TableCell align="right">Aplicar paquete</TableCell>
            <TableCell align="right">Uso único</TableCell>
            <TableCell align="right">Inicio vigencia</TableCell>
            <TableCell align="right">Fin vigencia</TableCell>
            <TableCell align="right">Fecha creación</TableCell>
            <TableCell align="right">Cantidad de uso</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {info.map((row) => (
            <TableRow key={row.id}>
              <TableCell component="th" scope="row">{row.id}</TableCell>
              <TableCell align="right">{row.promotionCode}</TableCell>
              <TableCell align="right">{row.descriptionPromotionCode}</TableCell>
              <TableCell align="right">{row.id}</TableCell>
              <TableCell align="right">{row.id}</TableCell>
              <TableCell align="right">{row.id}</TableCell>
              <TableCell align="right">{row.id}</TableCell>
              <TableCell align="right">{row.id}</TableCell>
              <TableCell align="right">{row.effectiveStartDate}</TableCell>
              <TableCell align="right">{row.expirationDate}</TableCell>
              <TableCell align="right">{row.createdon}</TableCell>
              <TableCell align="right">{row.id}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default PromoTable;
