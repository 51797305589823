import React from 'react';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import PowerSettingsNewOutlinedIcon from '@material-ui/icons/PowerSettingsNewOutlined';
import FeaturedPlayListIcon from '@material-ui/icons/FeaturedPlayList';
import { Link } from 'react-router-dom';

const ProfileMenu = ({ element, setElement ,handleLogOut}) => {
  return (
    <>
      <Menu
        id="profile-menu"
        open={Boolean(element)}
        anchorEl={element}
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        onClose={() => setElement(null)}
        disableAutoFocusItem
      >
        <MenuItem dense component={Link} to="/myplan">
          <FeaturedPlayListIcon color="primary" style={{ marginRight: 10 }} />
          Mi plan
        </MenuItem>
        <MenuItem dense onClick={()=>{handleLogOut()}}>
          <PowerSettingsNewOutlinedIcon color="primary" style={{ marginRight: 10 }} />
          Cerrar sesión
        </MenuItem>
      </Menu>
    </>
  );
};

export default ProfileMenu;
