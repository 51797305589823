/* eslint-disable import/prefer-default-export */
import { createMuiTheme } from '@material-ui/core/styles';
import background from '../assets/img/background/gse.png';
import logo from '../assets/img/logo/gse.svg';

export default createMuiTheme({
  palette: {
    primary: {
      main: '#009FE3',
      contrastText: '#fff',
    },
    secondary: {
      main: '#FF6F00',
    },
  },
  logo,
  colorHeader: '#FFFFFF',
  home: {
    paquetes: {
      backgroundColor: '#FFFFFF',
    },
    combos: {
      backgroundColor: '#3298CC',
    },
    agregados: {
      backgroundColor: '#F8F8F8',
    },
    bgColorHead: {
      bases: 'blue,green,orange',
      combos: 'gray',
      complement:'red01',
    },
    sliderComboText: {
      color:'#0069A5',
    },
    sliderComplementText: {
    },
    sliderBackGroundColor: {
      color:'#FFF',
    },
    cart: {
      title: {
        color: '#003582',
      },
      tableHead: {
        backgroundColor: '#009BDD',
      },
      totalText: {
        color: '#003582',
      },
      promoForm: {
        backgroundColor: '#F1F1F1',
        color: '#B2B2B2',
      },
      slider:{
      },
      btnPromotional: {
        backgroundColor: '#009BDD',
        color:'#fff',
      },
      tableCell: {
        color: '#fff',
      },
      textPromotional: {
        backgroundColor: '#fff',
      },
      btnContinue: {
        backgroundColor: '#E45501',
        color: '#fff',
      }
    },    
  },
  miplan: {
    title: {
      color: '#009BDD',
    },
    subtitle: {
      color: '#6D6E72',
    },
    cardHeader: {
      color:'#003582'
    },
    featureName: {      
    },
    featureValue: {   
      color: '#009BDD',   
    },
    buttonCard: {  
      backgroundColor:'#E55200', 
      color: '#fff',
    },
    iconCard: {
      color: '#009BDD',
    },
  },
  overrides: {
    MuiButton: {
      root: {
        textTransform: 'none',
      },
      label: {
        fontWeight: 'bold',
      },
    },
    MuiCssBaseline: {
      '@global': {
        body: {
          background: `url(${background}) no-repeat center center fixed`,
          WebkitBackgroundSize: 'cover',
          MozBackgroundSize: 'cover',
          OBackgroundSize: 'cover',
          backgroundSize: 'cover',
        },
      },
    },
  },
});
