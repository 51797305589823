import React from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Countdown from './Countdown';

import { directPurchaseAction } from '../actions/home.action';
import { formatter } from '../helpers/cartUtils';

const useStyles = makeStyles(() => ({
  root: {
    height: '600px',
    padding: '80px 40px 80px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    color: 'white',
  },
  title: {
    color: '#F7FF00',
    fontWeight: 'bold',
  },
  subtitle: {
    fontWeight: 'bold',
    marginTop: 10,
    marginBottom: 10,
  },
  description: {
    fontWeight: 'bold',
  },
}));

const PromoCard = (props) => {
  const classes = useStyles();
  const { card, directPurchase } = props;

  const {
    packageName, packageDescription, urlImageOffer, offerExpirationTime,
  } = card;
  const getOfertText = (item) => {
    if (item.offerTypeCode === 'PTJ') return `${item.totalOffer}%`;
    if (item.offerTypeCode === 'VLR') {
      return (
        <>
          <s style={{ fontSize: 20, marginRight: 10 }}>{formatter.format(item.packagePrice)}</s>
          <span>{formatter.format(item.packagePriceWithDiscount)}</span>
        </>
      );
    }
    return '';
  };
  const getValidityText = (item) => {
    if (item.validityTypeId === 'I') return `Vigencia ${item.validityPeriodDescription}`;
    return `Valido por ${item.validityPeriodDescription}`;
  };

  return (
    <Paper className={classes.root} style={{ backgroundImage: `url(${urlImageOffer})` }} elevation={5}>
      <Box>
        <Typography className={classes.title} variant="h4">{packageName}</Typography>
        <Typography className={classes.subtitle} variant="h3">{getOfertText(card)}</Typography>
        <Typography className={classes.description} variant="h5">{packageDescription}</Typography>
        <Typography>{getValidityText(card)}</Typography>
      </Box>
      <Box>
        <Button variant="contained" color="primary" onClick={() => directPurchase(card)}>
          ¡COMPRAR YA!
        </Button>
      </Box>
      <Box>
        <Typography>Oferta finaliza en</Typography>
        <Countdown
          titlePosition="bottom"
          endAt={new Date(offerExpirationTime).toUTCString()}
          size="extra-small"
          hideYear
          hideMonth
        />
      </Box>
    </Paper>
  );
};

const mapDispatchToProps = {
  directPurchase: directPurchaseAction,
};

export default connect(null, mapDispatchToProps)(PromoCard);
