import { combineReducers } from 'redux';
import home from './home.reducer';
import purchaseLicense from './purchaseLicense.reducer';
import general from './general.reducer';
import auth from './auth.reducer';
import packets from './packets.reducer';
import service from './service.reducer';

export default combineReducers(
  {
    auth,
    home,
    purchaseLicense,
    general,
    packets,
    service,
  },
);
