import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import Container from '@material-ui/core/Container';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import HomeIcon from '@material-ui/icons/Home';

import { getStatusOrderAction } from '../../actions/purchaseLicense.action';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: '20px',
    borderRadius: 3,
    boxShadow: '0 3px 5px 2px #555453',
    justifyContent: 'center',
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: '-10%',
    marginLeft: '-20%',
    width: '40%',
    minHeight: '220px',
  },
  statusPayment: {
    color: 'orange',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
}));

const StatusOrder = (props) => {
  const {
    history, getStatusOrder, statusOrder, appName
  } = props;
  const classes = useStyles();

  useEffect(() => {
    const { refererenceUuid } = props.match.params;
    getStatusOrder(refererenceUuid, appName);
  }, []);

  const handleClickHome = () => {
    history.push('/');
  };

  const handleClickExternalApp = () => {
    if (statusOrder && statusOrder.result === true) window.location.href = statusOrder.resultData.urlBaseExternalApp;
    else history.push('/');
  };

  return (
    <>
      <Container component="main" maxWidth="lg">
        <Paper className={classes.root} elevation={5}>
          <div>
            <h2 className={classes.statusPayment}>{statusOrder && statusOrder.result === true ? '¡Felicitaciones!' : '¡Lo sentimos!'}</h2>
            <h3>{statusOrder && statusOrder.resultMessage}</h3>
            <br />
            {
              statusOrder && statusOrder.result === true ? (
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={handleClickExternalApp}
                  startIcon={<HomeIcon />}
                >
                  Continuar
                </Button>
              ) : (
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={handleClickHome}
                  startIcon={<HomeIcon />}
                >
                  Volver a la tienda
                </Button>
              )
            }
          </div>
        </Paper>
      </Container>
    </>
  );
};

const mapStateToProps = (props) => ({
  statusOrder: props.purchaseLicense.statusOrder,
  appName: props.general.appName,
});

const mapDispatchToProps = {
  getStatusOrder: getStatusOrderAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(StatusOrder);
