import React from 'react';
import ReactDOM from 'react-dom';

import firmaYaTheme from './theme/FirmaYa';
import gseTheme from './theme/Gse';
import colfacturaTheme from './theme/Colfactura';
import firmaMailTheme from './theme/FirmaMail';

import './index.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import 'fontsource-roboto';
import App from './routes/App';

const request = new XMLHttpRequest();
request.open('GET', document.location, false);
request.send(null);
const appName = request.getResponseHeader('appName');

const SelectTheme = (app) => {
  let selectedTheme;
  switch (app) {
    case 'firmaya':
      selectedTheme = firmaYaTheme;
      break;
    case 'ibuhoo':
      selectedTheme = gseTheme;
      break;
    case 'colfactura':
      selectedTheme = colfacturaTheme;
      break;
    case 'firmamail':
      selectedTheme = firmaMailTheme;
      break;
    default:
      console.error(`${app}: appName no configurado`);
      break;
  }
  return selectedTheme;
};

const theme = SelectTheme(appName);

ReactDOM.render(theme ? <App appName={appName} theme={theme} /> : null, document.getElementById('App'));
