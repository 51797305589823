import axios from 'axios';
import {
  LOADING, GET_PAYMENTS, UPDATE_PENDING, UPDATE_APPROVED, UPDATE_REJECTED,
  GET_PROMO_CODES, GET_PACKAGES,
} from '../types/packets.type';
import { API_LICENCIAMIENTO, NUM_REGISTERS_PAGING } from '../config/config';
import { PURCHASE_TYPE } from '../constants/PurchaseConstants';

export const getPaymentsAction = () => async (dispatch) => {
  try {
    dispatch({ type: LOADING, payload: true });
    const response = await axios.post(`${API_LICENCIAMIENTO}/license/api/PurchaseOrder/GetPurchaseCash`);
    if (response.status === 200) {
      // console.log(response.data.result.records);
      dispatch({ type: GET_PAYMENTS, payload: response.data.result.records });
      dispatch({ type: LOADING, payload: false });
    }
  } catch (error) {
    console.error(error);
  }
};

export const getPaymentsPagingAction = (data, type) => async (dispatch, getState) => {
  try {
    dispatch({ type: LOADING, payload: true });
    const response = await axios.post(`${API_LICENCIAMIENTO}/license/api/PurchaseOrder/GetPurchaseCashByBatch`, data);
    if (response.status === 200) {
      switch (type) {
        case 'pending':
          const { packets: { pendingBatch } } = getState();
          if (pendingBatch) Array.prototype.push.apply(pendingBatch, response.data.result.records.purchase);
          const arrUpdated = Object.assign([], pendingBatch === null ? response.data.result.records.purchase : pendingBatch);
          dispatch({ type: UPDATE_PENDING, payload: arrUpdated, rownums: response.data.result.records.purchaseRows });
          break;
        case 'approved':
          const { packets: { approvedBatch } } = getState();
          if (approvedBatch) Array.prototype.push.apply(approvedBatch, response.data.result.records.purchase);
          const arrUpdatedAp = Object.assign([], approvedBatch === null ? response.data.result.records.purchase : approvedBatch);
          dispatch({ type: UPDATE_APPROVED, payload: arrUpdatedAp, rownums: response.data.result.records.purchaseRows });
          break;
        case 'rejected':
          const { packets: { rejectedBatch } } = getState();
          if (rejectedBatch) Array.prototype.push.apply(rejectedBatch, response.data.result.records.purchase);
          const arrUpdatedRe = Object.assign([], rejectedBatch === null ? response.data.result.records.purchase : rejectedBatch);
          dispatch({ type: UPDATE_REJECTED, payload: arrUpdatedRe, rownums: response.data.result.records.purchaseRows });
          break;
        default:
          break;
      }
      dispatch({ type: LOADING, payload: false });
    }
  } catch (error) {
    console.error(error);
  }
};

export const reloadBatchAction = () => (dispatch) => {
  try {
    dispatch({ type: UPDATE_PENDING, payload: null, rownums: 0 });
    dispatch({ type: UPDATE_APPROVED, payload: null, rownums: 0 });
    dispatch({ type: UPDATE_REJECTED, payload: null, rownums: 0 });
    dispatch(getPaymentsPagingAction({ Last: 0, NumRows: NUM_REGISTERS_PAGING, IdState: PURCHASE_TYPE.Pending }, 'pending'));
    dispatch(getPaymentsPagingAction({ Last: 0, NumRows: NUM_REGISTERS_PAGING, IdState: PURCHASE_TYPE.Approved }, 'approved'));
    dispatch(getPaymentsPagingAction({ Last: 0, NumRows: NUM_REGISTERS_PAGING, IdState: PURCHASE_TYPE.Rejected }, 'rejected'));
  } catch (error) {
    console.error(`Error aprobando pago: ${error.message}`);
  }
};

export const approvePaymentAction = (data, enqueueSnackbar) => async (dispatch) => {
  try {
    dispatch({ type: LOADING, payload: true });
    const response = await axios.post(`${API_LICENCIAMIENTO}/license/api/PurchaseOrder/ChangeStatePurchase`, data);
    if (response.status === 200) {
      dispatch(reloadBatchAction());
      dispatch({ type: LOADING, payload: false });
      enqueueSnackbar('Se ha aprobado el pago satisfactoriamente.', {
        variant: 'success',
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'right',
        },
      });
    }
  } catch (error) {
    if (error.response) {
      if (error.response.status === 422) {
        enqueueSnackbar(error.response.data.statusMessage, {
          variant: 'error',
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right',
          },
        });
      } else {
        enqueueSnackbar('Error de servidor', {
          variant: 'error',
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right',
          },
        });
      }
    }
    console.error(`Error aprobando pago: ${error.message}`);
  }
};

export const rejectPaymentAction = (data, enqueueSnackbar) => async (dispatch) => {
  try {
    dispatch({ type: LOADING, payload: true });
    const response = await axios.post(`${API_LICENCIAMIENTO}/license/api/PurchaseOrder/ChangeStatePurchase`, data);
    if (response.status === 200) {
      dispatch(reloadBatchAction());
      dispatch({ type: LOADING, payload: false });
      enqueueSnackbar('El pago ha sido rechazado satisfactoriamente.', {
        variant: 'success',
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'right',
        },
      });
    }
  } catch (error) {
    if (error.response) {
      if (error.response.status === 422) {
        enqueueSnackbar(error.response.data.statusMessage, {
          variant: 'error',
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right',
          },
        });
      } else {
        enqueueSnackbar('Error de servidor', {
          variant: 'error',
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right',
          },
        });
      }
    }
    console.error(`Error aprobando pago: ${error.message}`);
  }
};

export const GetPackages = () => async (dispatch) => {
  try {
    dispatch({ type: LOADING, payload: true });
    const response = await axios.get(`${API_LICENCIAMIENTO}/license/api/Package/GetPackagesBackOffice`);
    if (response.status === 200) {
      dispatch({ type: GET_PACKAGES, payload: response.data.result.records });
      dispatch({ type: LOADING, payload: false });
    }
  } catch (error) {
    console.error(`Error obteniendo la lista de paquetes: ${error.message}`);
  }
};

export const SaveNewPackage = (data) => async (dispatch) => {
  try {
    dispatch({ type: LOADING, payload: true });
    const response = await axios.post(`${API_LICENCIAMIENTO}/license/api/Package/SaveNewPackageBackOffice`, data);
    if (response.status === 200) {
      dispatch({ type: LOADING, payload: false });
    }
  } catch (error) {
    console.error(`Error obteniendo la lista de paquetes: ${error.message}`);
  }
};

export const GetPromoCodes = () => async (dispatch) => {
  try {
    dispatch({ type: LOADING, payload: true });
    const response = await axios.get(`${API_LICENCIAMIENTO}/license/api/PromotionalCode/GetPromotionalCodes`);
    if (response.status === 200) {
      dispatch({ type: GET_PROMO_CODES, payload: response.data.result.records });
      dispatch({ type: LOADING, payload: false });
    }
  } catch (error) {
    console.error(`Error obteniendo la lista de códigos promocionales: ${error.message}`);
  }
};

export const SavePromoCode = (data) => async (dispatch) => {
  try {
    dispatch({ type: LOADING, payload: true });
    const response = await axios.post(`${API_LICENCIAMIENTO}/license/api/PromotionalCode/SaveNewPromoCode`, data);
    if (response.status === 200) {
      console.log('OK SavePromoCode');
      dispatch({ type: LOADING, payload: false });
    }
  } catch (error) {
    console.error(`Error obteniendo la lista de códigos promocionales: ${error.message}`);
  }
};

export const searchPaymentsPagingAction = (data, type) => async (dispatch) => {
  try {
    dispatch({ type: LOADING, payload: true });
    const response = await axios.post(`${API_LICENCIAMIENTO}/license/api/PurchaseOrder/SearchPurchaseCash`, data);
    if (response.status === 200) {
      const dataResultSearch = Object.assign([], response.data.result.records.purchase);
      switch (type) {
        case 'pending':
          dispatch({ type: UPDATE_PENDING, payload: dataResultSearch, rownums: response.data.result.records.purchaseRows });
          break;
        case 'approved':
          dispatch({ type: UPDATE_APPROVED, payload: dataResultSearch, rownums: response.data.result.records.purchaseRows });
          break;
        case 'rejected':
          dispatch({ type: UPDATE_REJECTED, payload: dataResultSearch, rownums: response.data.result.records.purchaseRows });
          break;
        default:
          break;
      }
      dispatch({ type: LOADING, payload: false });
    }
  } catch (error) {
    console.error(error);
  }
};
