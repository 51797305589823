import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useSnackbar } from 'notistack';
import { makeStyles } from '@material-ui/core/styles';
import { useForm } from 'react-hook-form';
import Dialog from '@material-ui/core/Dialog';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/HighlightOff';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import TextField from '@material-ui/core/TextField';

import CustomInput from '../Components/FormComponents/CustomInput';
import CustomSelect from '../Components/FormComponents/CustomSelect';
import CustomSwitch from '../Components/FormComponents/CustomSwitch';
import CustomCheck from '../Components/FormComponents/CustomCheck';

import { Rules } from '../../../helpers/RHFRules';
import { SaveNewPackage } from '../../../actions/packets.action';


const packageTypeOptions = [
  { value: 1, label: 'Tipo paquete 1' },
  { value: 2, label: 'Tipo paquete 2' },
  { value: 3, label: 'Tipo paquete 3' },
  { value: 4, label: 'Tipo paquete 4' },
];
const validityPeriodOptions = [
  { value: 'A', label: 'Anual' },
  { value: 'M', label: 'Mensual' },
  { value: 'D', label: 'Diaria' },
];
const visibilityTypeOptions = [
  { value: 'PU', label: 'Pública' },
  { value: 'PR', label: 'Privada' },
];

const percentageOptions = [
  { value: 10, label: '10%' },
  { value: 20, label: '20%' },
  { value: 30, label: '30%' },
  { value: 40, label: '40%' },
];

const useStyles = makeStyles((theme) => ({
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    '& > *': {
      margin: 5,
    },
  },
  boxWrapperItems: {
    display: 'flex',
    '& > *': {
      flexBasis: '100%',
    },
    '& : first-child': {
      marginRight: 5,
    },
  },
  dialogActions: {
    justifyContent: 'center',
  },
}));


const ModalNewPackage = (props) => {
  const { isOpen, onClose } = props;
  const classes = useStyles();

  const snackbar = useSnackbar();
  const dispatch = useDispatch();
  const {
    handleSubmit, control, errors, watch,
  } = useForm();

  const { value, percentage } = watch(['value', 'percentage']);
  const [totalValue, setTotalValue] = useState();

  useEffect(() => {
    if (value && percentage) {
      const valuePercentage = (value / 100) * percentage;
      const total = Number(value) + valuePercentage;
      setTotalValue(total);
    } else setTotalValue(0);
  }, [value, percentage]);

  const onSubmit = (data) => {
    dispatch(SaveNewPackage(data, snackbar));
    onClose();
  };


  return (
    <>
      <Dialog
        fullWidth
        maxWidth="sm"
        open={isOpen}
      >
        <DialogTitle>
          <Typography color="primary">Crear paquete</Typography>
          <IconButton className={classes.closeButton} onClick={onClose}>
            <CloseIcon color="primary" />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <form
            noValidate
            autoComplete="off"
            className={classes.form}
          >
            <CustomSwitch
              label="Visibilidad"
              name="visibility"
              control={control}
            />
            <CustomSelect
              label="Tipo de paquete"
              name="packageType"
              control={control}
              rule={Rules.required}
              error={errors}
              options={packageTypeOptions}
            />
            <CustomInput
              label="Código"
              name="code"
              control={control}
              rule={Rules.required}
              error={errors}
            />
            <CustomInput
              label="Nombre"
              name="name"
              control={control}
              rule={Rules.required}
              error={errors}
            />
            <CustomInput
              label="Descripción"
              name="description"
              multiline
              rows={3}
              control={control}
              rule={Rules.required}
              error={errors}
            />
            <Box className={classes.boxWrapperItems}>
              <CustomInput
                label="Valor paquete"
                name="value"
                type="number"
                control={control}
                rule={Rules.required}
                error={errors}
                className={{ marginRight: 5 }}
              />
              <CustomSelect
                label="Porcentaje"
                name="percentage"
                control={control}
                rule={Rules.required}
                error={errors}
                options={percentageOptions}
                className={{ marginRight: 5 }}
              />
              <TextField
                id="totalValue"
                label="Valor total"
                value={totalValue}
                size="small"
                type="number"
                variant="outlined"
                disabled
              />
            </Box>
            <Box className={classes.boxWrapperItems}>
              <CustomSelect
                label="Tipo vigencia"
                name="validityPeriod"
                control={control}
                rule={Rules.required}
                error={errors}
                options={validityPeriodOptions}
              />
              <CustomInput
                label="Vigencia"
                name="validity"
                type="number"
                control={control}
                rule={Rules.required}
                error={errors}
                className={{ marginLeft: 5 }}
              />
            </Box>
            <CustomInput
              label="Tiempo de expiración"
              name="expirationTime"
              control={control}
              rule={Rules.required}
              error={errors}
            />
            <CustomSelect label="Visibilidad" name="visibilityType" control={control} rule={Rules.required} error={errors} options={visibilityTypeOptions} />
            <CustomInput label="Orden" name="order" type="number" control={control} rule={Rules.required} error={errors} />
            <CustomInput label="Resumen" name="resume" control={control} rule={Rules.required} error={errors} />
            <CustomCheck label="Oferta" name="offer" control={control} />
          </form>
        </DialogContent>
        <DialogActions className={classes.dialogActions} disableSpacing>
          <Button
            variant="contained"
            color="primary"
            onClick={handleSubmit(onSubmit)}
          >
            Guardar
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ModalNewPackage;
