import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { useSnackbar } from 'notistack';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import LicenseGroup from './LicenseGroup';
import { getMyPlanAction } from '../../actions/home.action';
import Loading from '../../components/Loading';


const useStyles = makeStyles((theme)=>({
  root: {
    width: 280,
    borderRadius: 16,
    paddingBottom: 10,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  paper: {
    padding: '5px',
    marginTop: '20px',
    paddingBottom: '20px', 
    opacity: '0.95'
  },
  cardContent: {
    padding: 0,
  },
  title: {
    ...theme.miplan.title,
  },
}));

const MyPlan = (props) => {
  const classes = useStyles();
  const {
    userInfo, myplan, loading, appName, getMyPlan,
  } = props;
  const { enqueueSnackbar } = useSnackbar();
  useEffect(() => {
    if (userInfo) {
      const CustomerRequest = {
        DocumentType: userInfo.customer_type,
        DocumentNumber: userInfo.customer_value, // '901043004','800000110',
        ServiceName: appName === 'ibuhoo' ? '' : appName,
      };
      getMyPlan(CustomerRequest, enqueueSnackbar);
    }
  }, [userInfo]);

  if (loading) return <Loading />;

  return (
    <>
      <Container component="main" maxWidth="lg">
        <Paper elevation={3} className={classes.paper}>
          <Typography color="primary" variant="h4" style={{ marginLeft: 20 }}>Mi plan</Typography>
          {myplan.length && myplan.map((itemPlan, indexl)=>(
            <>
              <LicenseGroup key={indexl} data={itemPlan} />
            </>
          ))}
        </Paper>
      </Container>
    </>
  );
};

const mapStateToProps = ({ home, general, auth }) => ({
  userInfo: auth.userInfo,
  loading: home.loading,
  myplan: home.myplan,
  appName: general.appName,
});

const mapDispatchToProps = {
  getMyPlan: getMyPlanAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(MyPlan);