import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';
import AddCircle from '@material-ui/icons/AddCircleOutline';

import PromoTable from './PromoTable';
import SearchInput from '../Components/SearchInput';
import ModalNewPromo from './ModalNewPromo';

import { GetPromoCodes } from '../../../actions/packets.action';

const useStyles = makeStyles({
  root: {
    marginTop: 30,
    padding: '30px 30px 60px 30px',
  },
  title: {
    fontWeight: 500,
    marginLeft: 10,
  },
  container: {
    padding: 40,
  },
  boxActions: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: 10,
  },
});

const PromoCode = () => {
  const dispatch = useDispatch();
  const promoCodes = useSelector(({ packets }) => packets.promoCodes);
  const loading = useSelector(({ packets }) => packets.loading);
  const classes = useStyles();
  const [statusModal, setStatusModal] = useState(false);

  useEffect(() => {
    dispatch(GetPromoCodes());
  }, [dispatch]);


  return (
    <>
      <Container maxWidth="lg" component={Paper} className={classes.root}>
        <Typography color="primary" variant="h4" className={classes.title}>Configuración</Typography>
        <Box className={classes.container}>
          <Box className={classes.boxActions}>
            <SearchInput />
            <IconButton onClick={() => setStatusModal(true)}>
              <AddCircle color="secondary" />
            </IconButton>
          </Box>
          {
            loading
              ? (
                <div>Cargando</div>
              )
              : <PromoTable info={promoCodes} />
          }
        </Box>
      </Container>
      <ModalNewPromo isOpen={statusModal} onClose={() => setStatusModal(false)} />
    </>
  );
};

export default PromoCode;
