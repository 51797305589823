import React from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { useForm } from 'react-hook-form';
import { useSnackbar } from 'notistack';
import { format } from 'date-fns';
import Dialog from '@material-ui/core/Dialog';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import Collapse from '@material-ui/core/Collapse';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import CloseIcon from '@material-ui/icons/HighlightOff';
import CircularProgress from '@material-ui/core/CircularProgress';
import CustomInput from '../../../../components/CustomInput';
import CustomSelect from '../../../../components/CustomSelect';
import CustomSwitch from '../../../../components/CustomSwitch';
import { Rules } from '../../../../helpers/RHFRules';
import { registerServiceAction } from '../../../../actions/service.action';

const useStyles = makeStyles((theme) => ({
  root: {
    margin: 0,
  },
  titleAlert: {
    color: '#003582',
    fontWeight: 700,
    fontSize: 25,
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
  },
  dialogContent: {
    marginBottom: 20,
  },
  serviceForm: {
    flexDirection: 'row',
    padding: 15,
    marginBottom: 10,
  },
  divider: {
    marginBottom: 20,
  },
  heading: {
    fontSize: theme.typography.pxToRem(13),
    height: 12,
    flexBasis: '33.33%',
    flexShrink: 0,
    color: theme.palette.text.secondary,
  },
  paper: {
    backgroundColor: '#0000000D',
  },
}));

const ModalService = (props) => {
  const classes = useStyles();
  const {
    isOpen, onClose, serviceTypes, item, isNew,
    registerService, loading,
  } = props;

  const defaultValues = {
    serviceTypeId: !isNew && item ? item.serviceTypeId : null,
    serviceName: !isNew && item ? item.serviceName : '',
    description: !isNew && item ? item.descriptionService : '',
    Status: isNew ? false : !(item && item.idstate === 1),
  };

  const { enqueueSnackbar } = useSnackbar();
  const [openViewMore, setOpenViewMore] = React.useState(false);

  const {
    handleSubmit, control, errors,
  } = useForm({ defaultValues });

  const handleSaveService = async (data) => {
    const dataSave = {
      ...data,
      Id: isNew ? 0 : item.id,
    };
    await registerService(dataSave, enqueueSnackbar);
    onClose();
  };

  return (
    <>
      <Dialog
        fullWidth
        maxWidth="sm"
        open={isOpen}
        className={classes.root}
      >
        <DialogTitle>
          <Typography align="left" color="primary" variant="h6">
            {isNew ? 'Crear producto o servicio' : 'Editar producto o servicio'}
          </Typography>
          <IconButton aria-label="close" color="primary" className={classes.closeButton} onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent className={classes.dialogContent}>
          <form
            noValidate
            autoComplete="off"
            onSubmit={handleSubmit(handleSaveService)}
            className={classes.serviceForm}
          >
            <FormControl fullWidth>
              <CustomSelect
                label="Tipo de servicio*"
                name="serviceTypeId"
                control={control}
                options={serviceTypes}
                error={errors}
                className={{ marginBottom: 10 }}
                rule={Rules.required}
              />
              <CustomInput
                label="Nombre del servicio*"
                name="serviceName"
                control={control}
                error={errors}
                className={{ marginBottom: 10 }}
                rule={Rules.required}
                inputProps={{
                  maxLength: 100,
                }}
              />
              <CustomInput
                label="Descripción"
                name="description"
                control={control}
                error={errors}
                multiline
                rows={4}
                className={{ marginBottom: 10 }}
                inputProps={{
                  maxLength: 250,
                }}
              />
              <Grid component="label" container alignItems="center" spacing={2}>
                <Grid item>Activar</Grid>
                <Grid item>
                  <CustomSwitch
                    label=""
                    name="Status"
                    control={control}
                    className={{ marginBottom: 10 }}
                  />
                </Grid>
              </Grid>
            </FormControl>
            {!isNew && !openViewMore
             && (
             <Button
               edge="false"
               size="small"
               style={{ float: 'right' }}
               color="primary"
               onClick={() => { setOpenViewMore(true); }}
             >
               Ver más
               <ExpandMoreIcon color="primary" />
             </Button>
             )}
            {!isNew && (
              <>
                <p></p>
                <Collapse in={openViewMore}>
                  <Paper elevation={1} className={classes.paper}>
                    <Typography variant="caption">
                      Creado por:
                      {' '}
                      {item.createdby}
                      <br />
                      Fecha y hora de creación:
                      {' '}
                      {format(new Date(item.createdon), 'dd/MM/yyyy hh:mm:ss aaaa ') }
                      {' '}
                      <br />
                      Modificado por:
                      {' '}
                      {item.modifiedby}
                      {' '}
                      <br />
                      Fecha y hora de modificación:
                      {' '}
                      {format(new Date(item.modifiedon), 'dd/MM/yyyy hh:mm:ss aaaa ') }
                    </Typography>
                  </Paper>
                </Collapse>
              </>
            )}
            {openViewMore
             && (
             <Button
               edge="false"
               size="small"
               style={{ float: 'right' }}
               color="primary"
               onClick={() => { setOpenViewMore(false); }}
             >
               Ver menos
               <ExpandLessIcon color="primary" />
             </Button>
             )}
            <Grid container justify="center">
              <Button
                variant="contained"
                color="primary"
                type="submit"
                style={{ marginTop: 10, color: '#fff', minWidth: 130 }}
              >
                {loading && <CircularProgress color="secondary" size={25} />}
                {isNew ? 'Crear' : 'Aceptar' }
              </Button>
            </Grid>
          </form>
        </DialogContent>
      </Dialog>
    </>
  );
};

const mapStateToProps = ({ general, service }) => ({
  serviceTypes: general.serviceTypes,
  loading: service.loading,
});

const mapDispatchToProps = {
  registerService: registerServiceAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(ModalService);
