import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';
import Container from '@material-ui/core/Container';
import { getPaymentsAction, getPaymentsPagingAction, reloadBatchAction, searchPaymentsPagingAction } from '../../../actions/packets.action';
import { getRejectTypesAction } from '../../../actions/general.action';
import GridPaymentsPaging from './GridPaymentsPaging';
import { NUM_REGISTERS_PAGING } from '../../../config/config';
import { PURCHASE_TYPE } from '../../../constants/PurchaseConstants';
// import GridPayments from './GridPayments';

const useStyles = makeStyles({
  root: {
    width: 280,
    borderRadius: 16,
    paddingBottom: 10,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  paper: {
    padding: '5px',
    marginTop: '20px',
    opacity: '0.95',
  },
  cardContent: {
    padding: 0,
  },
  title: {
    fontSize: '22px',
    letterSpacing: '0px',
    lineHeight: 1,
  },
  subtitle: {
    fontSize: '30px',
    letterSpacing: '0px',
    fontWeight: 'bold',
    lineheight: 1,
  },
  text: {
    fontSize: '16px',
    letterSpacing: '0px',
    lineheight: 1,
  },
  tab: {
    borderBottom: '1px solid #ccc',
    textTransform: 'none',
  },
});

function TabPanel(props) {
  const {
    children, value, index, ...other
  } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
      <Box p={3}>
        <Typography>{children}</Typography>
      </Box>
      )}
    </div>
  );
}

const Payments = (props) => {
  const classes = useStyles();
  const {
    getPayments,
    payments,
    getRejectTypes,
    getPaymentsPaging,
    pendingBatch,
    pendingBatchRows,
    approvedBatch,
    approvedBatchRows,
    rejectedBatch,
    rejectedBatchRows,
    reloadBatch,
    loading,
    searchPaymentsPaging,
  } = props;

  const {
    approved,
    rejected,
    pending,
    approvedRows,
    rejectedRows,
    pendingRows,
  } = payments;

  const [value, setValue] = useState(0);
  const [page, setPage] = React.useState(0);

  // useEffect(() => {
  //   getPayments();
  //   getRejectTypes();
  // }, [getPayments]);

  useEffect(() => {
    reloadBatch();
    getRejectTypes();
  }, [getPaymentsPaging]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    setPage(0);
  };

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }

  const handleChangePage = (event, newPage, typegrid, rowsPerPage) => {
    const numRows = rowsPerPage;
    let last;
    switch (typegrid) {
      case 'pending':
        last = pendingBatch.length;
        if (last < (newPage + 1) * numRows) {
          getPaymentsPaging({ Last: last, NumRows: NUM_REGISTERS_PAGING, IdState: PURCHASE_TYPE.Pending }, 'pending');
        }
        break;
      case 'approved':
        last = approvedBatch.length;
        if (last < (newPage + 1) * numRows) {
          getPaymentsPaging({ Last: last, NumRows: NUM_REGISTERS_PAGING, IdState: PURCHASE_TYPE.Approved }, 'approved');
        }
        break;
      case 'rejected':
        last = rejectedBatch.length;
        if (last < (newPage + 1) * numRows) {
          getPaymentsPaging({ Last: last, NumRows: NUM_REGISTERS_PAGING, IdState: PURCHASE_TYPE.Rejected }, 'rejected');
        }
        break;
      default:
        break;
    }
    setPage(newPage);
  };

  const handleSearchClick = (text, type) => {
    switch (type) {
      case 'pending':
        searchPaymentsPaging({ IdState: PURCHASE_TYPE.Pending, Search: text }, 'pending');
        break;
      case 'approved':
        searchPaymentsPaging({ IdState: PURCHASE_TYPE.Approved, Search: text }, 'approved');
        break;
      case 'rejected':
        searchPaymentsPaging({ IdState: PURCHASE_TYPE.Rejected, Search: text }, 'rejected');
        break;
      default:
        break;
    }    
  };

  // if (loading) return <Loading />;
  return (
    <>
      <Container component="main" maxWidth="lg">
        <Paper elevation={3} className={classes.paper}>
          <Tabs
            value={value}
            indicatorColor="secondary"
            textColor="secondary"
            onChange={handleChange}
            aria-label="disabled tabs example"
          >
            <Tab className={classes.tab} label="Pagos por aprobar" {...a11yProps(0)} />
            <Tab className={classes.tab} label="Pagos aprobados" {...a11yProps(1)} />
            <Tab className={classes.tab} label="Pagos rechazados" {...a11yProps(2)} />
          </Tabs>
          {loading && <CircularProgress color="secondary" size={25} />}
          {/* <TabPanel value={value} index={0}>
            {pending
              && <GridPayments rows={pending} totalRows={pendingRows} type="pending" />}
          </TabPanel>
          <TabPanel value={value} index={1}>
            {approved
              && <GridPayments rows={approved} totalRows={approvedRows} type="approved" />}
          </TabPanel>
          <TabPanel value={value} index={2}>
            {rejected
              && <GridPayments rows={rejected} totalRows={rejectedRows} type="rejected" />}
          </TabPanel> */}
          <TabPanel value={value} index={0}>
            {pendingBatch
              && (
              <GridPaymentsPaging
                rows={pendingBatch}
                totalRows={pendingBatchRows}
                type="pending"
                setPage={setPage}
                page={page}
                handleChangePage={handleChangePage}
                isSearchServer={false}
                handleSearch={handleSearchClick}
              />
              )}
          </TabPanel>
          <TabPanel value={value} index={1}>
            {approvedBatch
              && (
              <GridPaymentsPaging
                rows={approvedBatch}
                totalRows={approvedBatchRows}
                type="approved"
                setPage={setPage}
                page={page}
                handleChangePage={handleChangePage}
                isSearchServer={false}
                handleSearch={handleSearchClick}
              />
              )}
          </TabPanel>
          <TabPanel value={value} index={2}>
            {rejectedBatch
              && (
              <GridPaymentsPaging
                rows={rejectedBatch}
                totalRows={rejectedBatchRows}
                type="rejected"
                setPage={setPage}
                page={page}
                handleChangePage={handleChangePage}
                isSearchServer={false}
                handleSearch={handleSearchClick}
              />
              )}
          </TabPanel>
        </Paper>
      </Container>
    </>
  );
};

const mapStateToProps = ({ packets }) => ({
  payments: packets.payments,
  pendingBatch: packets.pendingBatch,
  pendingBatchRows: packets.pendingBatchRows,
  approvedBatch: packets.approvedBatch,
  approvedBatchRows: packets.approvedBatchRows,
  rejectedBatch: packets.rejectedBatch,
  rejectedBatchRows: packets.rejectedBatchRows,
  loading: packets.loading,
});

const mapDispatchToProps = {
  getPayments: getPaymentsAction,
  getRejectTypes: getRejectTypesAction,
  getPaymentsPaging: getPaymentsPagingAction,
  reloadBatch: reloadBatchAction,
  searchPaymentsPaging: searchPaymentsPagingAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(Payments);
