import axios from "axios";
import {
  LOADING,
  GET_CUSTOMER_INFORMATION,
  GET_STATUS_PURCHASEORDER,
  SET_PERSON_TYPE,
  SET_PERSON_TYPE_SERVICE,
} from "../types/purchaseLicense.type";
import {
  API_LICENCIAMIENTO,
  API_PAYMENTSERVICESVINKEL,
} from "../config/config";
import { CleanCart } from "../helpers/cartUtils";

let tokenPaymentService = "";

export const sendToPaymentAction = (token, request, customerBilling) => async (
  dispatch
) => {
  try {
    console.log("consumiendo vinkel");
    console.log("llega token =>", token);
    console.log("request a enviar =>", request);

    const urlPaymentServices = `${API_PAYMENTSERVICESVINKEL}${"payment/form"}`;
    const configPaymentServices = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${token}`,
      },
    };

    const paymentRequest = {
      fullReference: request.fullReference,
      redirectionUrl: request.redirectionUrl,
      subTotal: request.subTotal,
      taxIva: request.taxIva,
      taxReteIva: request.taxReteIva,
      taxReteIca: request.taxReteIca,
      taxReteFuente: request.taxReteFuente,
      user: {
        firstName: customerBilling.firstName,
        lastName: customerBilling.lastName,
        legalName:
          customerBilling.personType === "N" ? null : customerBilling.legalName,
        isLegalClient: customerBilling.personType === "J",
        documentType: request.customerBillingDocumentType,
        documentNumber: customerBilling.documentNumber,
        cellPhoneNumber: customerBilling.cellphoneNumber,
        email: customerBilling.email,
        departmentCode: customerBilling.departmentId,
        cityCode: customerBilling.cityId,
        address: {
          address_field_1: customerBilling.addressField1,
          address_field_2: customerBilling.addressField2,
          address_field_3: customerBilling.addressField3,
          address_field_4: customerBilling.addressField4,
          address_field_5: customerBilling.addressField5,
          address_field_6: customerBilling.addressField6,
          address_field_7: customerBilling.addressField7,
          address_field_8: customerBilling.addressField8,
          address_field_9: customerBilling.addressField9,
          address_field_10: customerBilling.addressField10,
          address_complementary_field_1:
            customerBilling.addressFieldComplementary1,
          address_complementary_field_2:
            customerBilling.addressFieldComplementary2,
          address_complementary_field_3:
            customerBilling.addressFieldComplementary3,
          address_complementary_field_4:
            customerBilling.addressFieldComplementary4,
        },
        additionalInformation: request.additionalInformation,
      },
      productsList: request.productsList,
      service: request.service,
    };

    console.log("config =>", configPaymentServices);
    console.log("url =>", urlPaymentServices);
    console.log(paymentRequest);

    const response = await axios.post(
      urlPaymentServices,
      paymentRequest,
      configPaymentServices
    );
    console.log("response vinkel", response);
    console.log("url", response.data.result.url);
    if (response.status === 200)
      window.location.href = response.data.result.url;

    console.log("response vinkel", response);
    dispatch({ type: LOADING, payload: false });
  } catch (err) {
    console.error("ERROR-->", err);
    dispatch({ type: LOADING, payload: false });
  }
};

export const savePurchaseAction = (
  formData,
  paymentMethod,
  appName,
  history
) => async (dispatch, getState) => {
  try {
    dispatch({ type: LOADING, payload: true });
    const {
      home: { cart, promoCode },
    } = getState();
    const cartClean = CleanCart(cart);

    const packagesToPurchase = [];
    // listado de productos paquetes en carrito de compras
    cartClean.forEach((item) =>
      packagesToPurchase.push({
        packageId: item.id,
        packageCode: "",
        packageQuantity: item.number,
      })
    );

    const customerService = {
      personType: formData.personTypeService,
      documentTypeId: formData.documentTypeIdService,
      documentNumber: formData.documentNumberService,
      legalName:
        formData.personTypeService === "J" ? formData.legalNameService : null,
      firstName: formData.firstNameService,
      lastName: formData.lastNameService,
      cellphoneNumber: formData.cellphoneNumberService,
      email: formData.emailService,
      departmentId: formData.departmentIdService,
      cityId: formData.cityIdService,
      addressField1: formData.addressField1Service,
      addressField2: formData.addressField2Service,
      addressField3: formData.addressField3Service,
      addressField4: formData.addressField4Service,
      addressField5: formData.addressField5Service,
      addressField6: formData.addressField6Service,
      addressField7: formData.addressField7Service,
      addressField8: formData.addressField8Service,
      addressField9: formData.addressField9Service,
      addressField10: formData.addressField10Service,
      addressFieldComplementary1: formData.addressFieldComplementary1Service,
      addressFieldComplementary2: formData.addressFieldComplementary2Service,
      addressFieldComplementary3: formData.addressFieldComplementary3Service,
      addressFieldComplementary4: formData.addressFieldComplementary4Service,
      fulladdress: formData.fulladdressService,
      legalDocumentTypeId: formData.legalDocumentTypeIdService,
      legalDocumentNumber: formData.legalDocumentNumberService,
      legalFirstName: formData.legalFirstNameService,
      legalLastName: formData.legalLastNameService,
    };
    let customerBilling;
    if (formData.personTypeBilling === undefined) {
      customerBilling = customerService;
    } else {
      customerBilling = {
        personType: formData.personTypeBilling,
        documentTypeId: formData.documentTypeIdBilling,
        documentNumber: formData.documentNumberBilling,
        legalName:
          formData.personTypeBilling === "J" ? formData.legalNameBilling : null,
        firstName: formData.firstNameBilling,
        lastName: formData.lastNameBilling,
        cellphoneNumber: formData.cellphoneNumberBilling,
        email: formData.emailBilling,
        departmentId: formData.departmentIdBilling,
        cityId: formData.cityIdBilling,
        addressField1: formData.addressField1Billing,
        addressField2: formData.addressField2Billing,
        addressField3: formData.addressField3Billing,
        addressField4: formData.addressField4Billing,
        addressField5: formData.addressField5Billing,
        addressField6: formData.addressField6Billing,
        addressField7: formData.addressField7Billing,
        addressField8: formData.addressField8Billing,
        addressField9: formData.addressField9Billing,
        addressField10: formData.addressField10Billing,
        addressFieldComplementary1: formData.addressFieldComplementary1Billing,
        addressFieldComplementary2: formData.addressFieldComplementary2Billing,
        addressFieldComplementary3: formData.addressFieldComplementary3Billing,
        addressFieldComplementary4: formData.addressFieldComplementary4Billing,
        fulladdress: formData.fulladdressBilling,
      };
    }

    const savePurchaseRequest = {
      customerBilling,
      customerService,
      packagesToPurchase,
      promotionCodeUse: promoCode ? promoCode.promotionCode : "",
      totalDiscount: 0,
      totalTaxIva: 0,
      totalPurchase: 0,
      userRegisterPurchase: "",
      additionalInfo: formData.additionalInfoBilling,
      paymentMethod,
      appName,
    };
    console.log(JSON.stringify(savePurchaseRequest));
    const response = await axios.post(
      `${API_LICENCIAMIENTO}/license/api/PurchaseOrderLicense/RegisterPurchaseOrder`,
      savePurchaseRequest
    );
    if (response.status === 200) {
      console.log(response);
      const productListArray = [];
      const result = response.data.result.records;
      tokenPaymentService = result.token;
      const paymentRequestResponse = result.paymentRequest;

      paymentRequestResponse.productsList.forEach((item) =>
        productListArray.push({
          ref: item.reference,
          quantity: item.quantity,
          unitPrice: item.unitPrice,
        })
      );

      const paymentRequest = {
        fullReference: paymentRequestResponse.fullReference,
        redirectionUrl: paymentRequestResponse.redirectionUrl,
        subTotal: paymentRequestResponse.subTotal,
        taxIva: paymentRequestResponse.taxIva,
        taxReteIva: paymentRequestResponse.taxReteIva,
        taxReteIca: paymentRequestResponse.taxReteIca,
        taxReteFuente: paymentRequestResponse.taxReteFuente,
        productsList: productListArray,
        customerBillingDocumentType: result.customerBillingDocumentType,
        additionalInformation: formData.additionalInfoBilling,
        service: paymentRequestResponse.service,
      };

      if (paymentMethod === "EN_LINEA") {
        dispatch(
          sendToPaymentAction(
            tokenPaymentService,
            paymentRequest,
            customerBilling
          )
        );
        dispatch({ type: LOADING, payload: true });
      }
      if (paymentMethod === "SIN_PAGO")
        history.push(
          `/registerPurchaseOrder/statusOrder/${response.data.result.records.referenceUuid}`
        );
    }
  } catch (error) {
    console.error(error);
    dispatch({ type: LOADING, payload: false });
  }
};

export const getCustomerInformationAction = (data) => async (dispatch) => {
  try {
    // dispatch({ type: LOADING, payload: true });
    const response = await axios.post(
      `${API_LICENCIAMIENTO}/license/api/PurchaseOrderLicense/GetCustomerInformation`,
      data
    );
    dispatch({
      type: GET_CUSTOMER_INFORMATION,
      payload: response.data.result.records,
    });
    // dispatch({ type: LOADING, payload: false });
  } catch (error) {
    console.error(error);
  }
};

export const setPersonTypeAction = (data) => async (dispatch) => {
  try {
    // dispatch({ type: LOADING, payload: true });
    dispatch({ type: SET_PERSON_TYPE, payload: data });
    // dispatch({ type: LOADING, payload: false });
  } catch (error) {
    console.error(error);
  }
};

export const setPersonTypeActionService = (data) => async (dispatch) => {
  try {
    // dispatch({ type: LOADING, payload: true });
    dispatch({ type: SET_PERSON_TYPE_SERVICE, payload: data });
    // dispatch({ type: LOADING, payload: false });
  } catch (error) {
    console.error(error);
  }
};

export const getStatusOrderAction = (referenceUuid, appName) => async (
  dispatch
) => {
  try {
    dispatch({ type: LOADING, payload: true });
    const response = await axios.get(
      `${API_LICENCIAMIENTO}/license/api/PurchaseOrderLicense/GetStatusPurchaseOrder/${referenceUuid}/${appName}`
    );
    dispatch({
      type: GET_STATUS_PURCHASEORDER,
      payload: response.data.result.records,
    });
    dispatch({ type: LOADING, payload: false });
  } catch (error) {
    console.error(error);
  }
};
