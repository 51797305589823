import {
  LOADING, GET_PAYMENTS, UPDATE_PENDING, UPDATE_APPROVED, UPDATE_REJECTED,
  GET_PACKAGES, GET_PROMO_CODES,
} from '../types/packets.type';

const INITIAL_STATE = {
  loading: false,
  payments: {
    pending: [],
  },
  packages: [],
  promoCodes: [],
  pendingBatch: null,
  pendingBatchRows: null,
  approvedBatch: null,
  approvedBatchRows: null,
  rejectedBatch: null,
  rejectedBatchRows: null,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_PAYMENTS:
      return {
        ...state,
        payments: {
          approved: action.payload.purchaseAproved,
          rejected: action.payload.purchaseRejected,
          pending: action.payload.purchasePending,
          pendingRows: action.payload.purchasePendingRows,
          approvedRows: action.payload.purchaseAprovedRows,
          rejectedRows: action.payload.purchaseRejectedRows,
        },
      };
    case GET_PACKAGES:
      return {
        ...state,
        packages: action.payload,
      };
    case GET_PROMO_CODES:
      return {
        ...state,
        promoCodes: action.payload,
      };
    case UPDATE_PENDING:
      return {
        ...state,
        pendingBatch: action.payload,
        pendingBatchRows: action.rownums,
      };
    case UPDATE_APPROVED:
      return {
        ...state,
        approvedBatch: action.payload,
        approvedBatchRows: action.rownums,
      };
    case UPDATE_REJECTED:
      return {
        ...state,
        rejectedBatch: action.payload,
        rejectedBatchRows: action.rownums,
      };
    case LOADING:
      return {
        ...state,
        loading: action.payload,
      };
    default:
      return state;
  }
};
