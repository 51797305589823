/* eslint-disable import/prefer-default-export */
import { createMuiTheme } from '@material-ui/core/styles';
import logo from '../assets/img/logo/colfactura.png';

export default createMuiTheme({
  palette: {
    type: 'dark',
    primary: {
      main: '#16B1F3', 
      contrastText: '#fff',
    },
    secondary: {
      main: '#2CC63E',
    },
  },
  logo,
  colorHeader: '#121212',
  home: {
    paquetes: {
    },
    combos: {
    },
    agregados: {
    },
    bgColorHead: {
      bases: 'blue,green,yellow',
      combos: 'green',
      complement:'yellow01',
    },
    sliderComboText: {      
    },
    sliderComplementText: {
      color:'#323232',
    },
    sliderBackGroundColor: {
    },
    cart: {
      title: {
        color: '#F5D13D',
      },
      tableHead: {
        backgroundColor: '#646464',
      },
      totalText: {
        color: '#F5D13D',
      },
      promoForm: {
        backgroundColor: '#646464',
        color: '#fff',
      },
      slider:{
        backgroundColor: '#646464',
      },
      btnPromotional: {
        backgroundColor: '#7DD1F8',
      },
      tableCell: {
      },
      textPromotional: {
      },
      btnContinue: {}
    }
  },
  miplan: {
    title: {
      color: '#FBC828',
    },
    subtitle: {
      color: '#fff',
    },
    cardHeader: {
      color:'#fff'
    },
    featureName: {      
    },
    featureValue: {   
      color: '#16B1F3',   
    },
    buttonCard: {   
      color: '#fff',
      backgroundColor: '#636363',  
    },
    iconCard: {
      color: '#16B1F3',
    },      
  },
  overrides: {
    MuiButton: {
      root: {
        textTransform: 'none',
      },
      label: {
        fontWeight: 'bold',
      },
    },
    MuiCssBaseline: {
      '@global': {
        body: {
          background: '#D8D8D817',
          WebkitBackgroundSize: 'cover',
          MozBackgroundSize: 'cover',
          OBackgroundSize: 'cover',
          backgroundSize: 'cover',
        },
      },
    },
  },
});
