import {
    SERVICE_LOADING, GET_SERVICES,
  } from '../types/service.type';
  
  const INITIAL_STATE = {
    loading: false,
    services: [],
  };
  
  export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
      case GET_SERVICES:
        return {
          ...state,
          services:action.payload 
        };
      case SERVICE_LOADING:
        return {
          ...state,
          loading: action.payload,
        };
      default:
        return state;
    }
  };
  