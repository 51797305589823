/* eslint-disable no-prototype-builtins */
import React from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Popover from '@material-ui/core/Popover';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Button from '@material-ui/core/Button';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import AddShoppingCartIcon from '@material-ui/icons/AddShoppingCart';

import { deleteOfCartAction } from '../../actions/home.action';

const formatter = new Intl.NumberFormat(undefined, {
  style: 'currency',
  currency: 'COP',
  minimumFractionDigits: 0,
  maximumFractionDigits: 0,
});

const useStyles = makeStyles({
  card: {
    width: 350,
    paddingBottom: 10,
    display: 'flex',
    flexDirection: 'column',
    margin: 0,
    justifyContent: 'space-between',
  },
  noItems: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    paddingTop: 24,
  },
  cardContent: {
    padding: '15px 15px 0',
  },
  cardHeader: {
    backgroundRepeat: 'no-repeat',
    height: '140px',
    padding: '11px 20px 0',
    color: 'white',
  },
  cardList: {
    padding: 15,
    fontSize: '16px',
    letterSpacing: '0px',
    lineheight: 1,
  },
  addToCartButton: {
    width: 37,
    minWidth: 0,
  },
  deleteIcon: {
    '&:hover': {
      color: 'red',
    },
  },
  subTotalText: {
    color: 'red',
  },
});

const CleanCart = (cart) => {
  const cartClean = {};
  cart.forEach((element) => {
    if (cartClean.hasOwnProperty(element.packageId)) {
      cartClean[element.packageId] = {
        ...cartClean[element.packageId],
        number: cartClean[element.packageId].number + 1,
      };
    } else {
      cartClean[element.packageId] = {
        id: element.packageId,
        name: element.packageName,
        number: 1,
        price: element.packagePriceWithDiscount,
      };
    }
  });
  return Object.values(cartClean);
};

const getSubtotal = (cart) => formatter.format(
  cart.reduce(
    (previousValue, currentValue) => (
      (currentValue.price * currentValue.number) + previousValue
    ), 0,
  ),
);

const CartMenu = (props) => {
  const {
    element, setElement, cart, openCartModal, deleteOfCart,
  } = props;

  const cartClean = CleanCart(cart);

  const classes = useStyles();

  const handleDelete = (item) => {
    deleteOfCart(item.id, true);
  };

  const handleClick = () => {
    setElement(null);
    openCartModal();
  };

  return (
    <Popover
      id={element ? 'cartMenu-popover' : undefined}
      open={Boolean(element)}
      anchorEl={element}
      onClose={() => setElement(null)}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      PaperProps={{
        style: { borderRadius: 16 },
      }}
    >
      <Card className={classes.card}>
        {
          cart.length
            ? (
              <>
                <CardContent className={classes.cardContent}>
                  <List disablePadding>
                    { cartClean.map((item) => (
                      <ListItem
                        key={item.id}
                        divider
                        dense
                        disableGutters
                      >
                        <ListItemText
                          primary={item.name}
                          secondary={`${formatter.format(item.price)} x ${item.number}`}
                          primaryTypographyProps={{ style: { fontWeight: 500 } }}
                        />
                        <ListItemSecondaryAction>
                          <IconButton
                            edge="end"
                            aria-label="delete"
                            onClick={() => handleDelete(item)}
                          >
                            <DeleteIcon className={classes.deleteIcon} />
                          </IconButton>
                        </ListItemSecondaryAction>
                      </ListItem>
                    ))}
                    <ListItem
                      key="cartMenu-popover-subtotal"
                      disableGutters
                    >
                      <ListItemText
                        primary="Subtotal"
                        secondary={getSubtotal(cartClean)}
                        primaryTypographyProps={{ style: { fontWeight: 500 } }}
                        secondaryTypographyProps={{ style: { fontWeight: 700 } }}
                      />
                    </ListItem>
                  </List>
                </CardContent>
                <CardActions style={{ justifyContent: 'center' }}>
                  <Button variant="contained" color="primary" onClick={() => handleClick()}>
                    Ir al carrito
                    <ShoppingCartIcon style={{ marginLeft: 5 }} />
                  </Button>
                </CardActions>
              </>
            )
            : (
              <CardContent className={classes.noItems}>
                <Typography style={{ marginBottom: 10, fontWeight: 500 }}>
                  ¡Tu carrito está vacío!
                </Typography>
                <AddShoppingCartIcon color="primary" style={{ fontSize: 40 }} />
              </CardContent>
            )
        }
      </Card>
    </Popover>
  );
};

const mapDispatchToProps = {
  deleteOfCart: deleteOfCartAction,
};

export default connect(null, mapDispatchToProps)(CartMenu);
