import React from 'react';
import { Controller } from 'react-hook-form';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';

const CustomInput = (props) => {
  const {
    label, name, type, value, className, disabled, multiline, rows, control, rule, error,
  } = props;
  return (
    <FormControl style={className}>
      <Controller
        as={(
          <TextField
            label={label}
            size="small"
            type={type}
            variant="outlined"
            multiline={multiline}
            rows={rows}
            disabled={disabled}
            FormHelperTextProps={{ style: { margin: '0 0 0 5px', color: 'red' } }}
            helperText={error[name] ? error[name].message : null}
          />
            )}
        name={name}
        control={control}
        rules={rule}
      />
    </FormControl>
  );
};

export default CustomInput;
